import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SharedRoutingModule } from './shared-routing.module';

// ngx bootstrap
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { LayoutComponent } from './layout/layout.component';
import { HttpClientModule } from '@angular/common/http';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { ModalModule } from 'ngx-bootstrap/modal';
import { LocalDatePipe } from './pipes/local-date.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { ProfileDynamicComponent } from './dynamic-profile/dynamic-profile.component';

// ===================================================================================
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ToastrModule } from 'ngx-toastr';
import { MatSelectModule } from "@angular/material/select";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { AlertModule,AlertConfig } from 'ngx-bootstrap/alert';
import { InfiniteScrollModule } from "ngx-infinite-scroll";
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { YoutubeCardsComponent } from './youtube-cards/youtube-cards.component';
import { SpotifyCardsComponent } from './spotify-cards/spotify-cards.component';
import {SuggestionsCardsComponent} from './suggestions/suggestions-card-component'
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import { MatSliderModule } from '@angular/material/slider';
import { PhotoAlbumComponent } from './photo-album/photo-album.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { AngMusicPlayerModule } from  'ang-music-player';
import { YtPlayerAngularModule } from 'yt-player-angular';
// import { YoutubeModalContentComponent } from './dynamic-profile/dynamic-profile.component'
import { TermComponent } from './term/term.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { YoutubeModalContentComponent } from './dynamic-profile/dynamic-profile.component';
import { ProfileFriendsListComponent } from './profile-friends-list/profile-friends-list.component'
import { FriendsFilterPipe } from './pipes/fliter-friends';
import { PhotoAlbumSliderModalContentComponent } from './photo-album/photo-album-slider.component';
import { UpdateGenerComponent } from './update-gener/update-gener.component';
import { UpdateCityComponent } from './update-city/update-city.component';
import { UpdateCoverComponent } from './update-cover/update-cover.component';
import { UpdateProfileComponent } from './update-profile/update-profile.component';

import { MatChipsModule } from '@angular/material/chips';
import { CarouselModule as carousel} from 'ngx-bootstrap/carousel';
import { MentionsModule } from '@flxng/mentions';
import { AngularEditorModule } from '@kolkov/angular-editor';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { PrivacySettingComponent } from './settingsprivacy/privacysetting.component';
import { UpdatedMobNumComponent } from './updated-mob-num/updated-mob-num.component';
import { UpdatedInvitationCodeComponent } from './updated-invitation-code/updated-invitation-code.component';
import { SpotifyAuthRedirectComponent } from './spotify-auth-redirect/spotify-auth-redirect.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { SpotifyViewComponent } from './spotify-view/spotify-view.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { SoundcloudComponent } from './soundcloud/soundcloud.component';
import { BandcampComponent } from './bandcamp/bandcamp.component';
import { YoutubeComponent } from './youtube/youtube.component';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { SpotifyComponent } from './spotify/spotify.component';
import { ClickStopPropagation } from './pipes/click-stop-propagtion'
import { RegistrationPartModule } from '../views/registration-part/registration-part.module';
import { RegisterPlaylistComponent } from './register-playlist/register-playlist.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { HtmlToPlaintextPipe } from './pipes/html-to-text.pipe';
import { LinkyModule } from 'ngx-linky';

// import {  } from "ngx-owl-carousel-o";

const routes: Routes = [];
@NgModule({
  declarations: [
    HeaderComponent,
    SidebarComponent,
    LayoutComponent,
    SafeHtmlPipe,
    LocalDatePipe,
    SafePipe,
    ProfileDynamicComponent,
    YoutubeCardsComponent,
    SpotifyCardsComponent,
    PhotoAlbumComponent,
    SuggestionsCardsComponent,
    YoutubeModalContentComponent,
    TermComponent,
    PrivacyComponent,
    PrivacySettingComponent,
    ProfileFriendsListComponent,
    FriendsFilterPipe,
    PhotoAlbumSliderModalContentComponent,
    UpdateGenerComponent,
    UpdateCityComponent,
    UpdateCoverComponent,
    UpdateProfileComponent,
    UpdatedMobNumComponent,
    UpdatedInvitationCodeComponent,
    SpotifyAuthRedirectComponent,
    SpotifyViewComponent,
    SoundcloudComponent,
    BandcampComponent,
    YoutubeComponent,
    SafeUrlPipe,
    SpotifyComponent,
    ClickStopPropagation,
    RegisterPlaylistComponent,
    HtmlToPlaintextPipe
  ],
  imports: [
    CommonModule,
    BsDropdownModule.forRoot(),
    SharedRoutingModule,
    HttpClientModule,
    ModalModule.forRoot(),
    // ======================================================
    FormsModule,
    ReactiveFormsModule,
    TabsModule.forRoot(),
    ImageCropperModule,
    ToastrModule.forRoot(),
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    TimepickerModule.forRoot(),
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    Ng2SearchPipeModule,
    MatTabsModule,
    NgxYoutubePlayerModule.forRoot(),
    AlertModule,
    InfiniteScrollModule,
    RouterModule.forChild(routes),
    // BrowserAnimationsModule,
    CarouselModule,
    MatToolbarModule,
    MatSliderModule,
    MatIconModule,
    MatCheckboxModule,
    AngMusicPlayerModule,
    YtPlayerAngularModule,
    MatChipsModule,
    MentionsModule,
    carousel,
    AngularEditorModule,
    DragDropModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    LinkyModule
  ],
  exports: [
    SidebarComponent,
    HeaderComponent,
    LayoutComponent,
    SafeHtmlPipe,
    LocalDatePipe,
    SafePipe,
    ProfileDynamicComponent,
    YoutubeCardsComponent,
    SpotifyCardsComponent,
    SuggestionsCardsComponent,
    TermComponent,
    PrivacySettingComponent,
    PrivacyComponent,
    FriendsFilterPipe,
    HtmlToPlaintextPipe
  ]
})
export class SharedModule { }
// platformBrowserDynamic().bootstrapModule(SharedModule);