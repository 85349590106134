import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../services/shared.service';
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { CarouselComponent, OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';
import { CarouselService } from 'ngx-owl-carousel-o/lib/services/carousel.service';
import { style } from '@angular/animations';
declare var $:any;
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'photo-album-slider-modal-content',
    template: `
    <div class="abc">
        <div>
            <div class="d-flex" 
            [ngClass]="{'justify-content-between' : !userAvailable && slidesStore[activeSlidePosition]._id != 'custom_id',
            'userprofilephooalbum' : userAvailable,'profile-or-cover-photo' : !userAvailable && slidesStore[activeSlidePosition]._id == 'custom_id'}">
                <button type="button" class="close crossclose pull-right" aria-label="Close" (click)="modalRef.hide()" style="margin-left: 35px; outline:0">
                <span aria-hidden="true"><img src="../../../../../assets/Indietown_icons/close.svg" alt=""></span>
                </button>
                <div class="text-center" >
                    <h1 class="photoalbumpopuptitle">Photo Album</h1>
                </div>
                <button  *ngIf="!userAvailable && slidesStore[activeSlidePosition]._id != 'custom_id'" type="button" class="close pull-right photoAlbumDelete" aria-label="Close" (click)="onClickDeletePhotoAlbum()" style="margin-right: 35px; outline:0">
                    <span aria-hidden="true">
                            <img src="../../../assets/Indietown_icons/delete.svg" alt="">
                    </span>
                </button>
            </div>
        </div>
        <div>
            
            <owl-carousel-o [options]="customOptions2"  (translated)="getActiveSlideData($event)" #owlCar>
                <ng-container *ngFor="let slide of slidesStore;let index=index">
                    <ng-template carouselSlide [width]="200" [id]="slide._id">
                        <img [src]="slide.fileUrl" [alt]="slide.fileUrl" [title]="slide.fileUrl"> 
                    </ng-template>
                </ng-container>
            </owl-carousel-o>
           <!--
            <div id="demos">
                <div class="loop owl-carousel">
                 <div class="item"  *ngFor="let slide of slidesStore;let index=index">
                        <h4> 1 </h4>
                    </div>
                      
                    <div class="item"><h4>1</h4></div>
                    <div class="item"><h4>2</h4></div>
                    <div class="item"><h4>3</h4></div>
                    <div class="item"><h4>4</h4></div>
                    <div class="item"><h4>5</h4></div>
                    <div class="item"><h4>6</h4></div>
                    <div class="item"><h4>7</h4></div>
                    <div class="item"><h4>8</h4></div>
                    <div class="item"><h4>9</h4></div>
                    <div class="item"><h4>10</h4></div>
                    <div class="item"><h4>11</h4></div>
                    <div class="item"><h4>12</h4></div>
                    
                </div>
            </div>--> 
        </div>
  </div>
    `,
    styleUrls:['./photo-album-slider.component.css']
  })
   
  export class PhotoAlbumSliderModalContentComponent implements OnInit {
  
    slidesStore;
    customOptions2:any;
    selectedImageIndex;
    userAvailable;
    // customOptions2: OwlOptions = {
    //   loop: false,
    //   mouseDrag: false,
    //   touchDrag: false,
    //   pullDrag: false,
    //   dots: false,
    //   navSpeed: 700,
    //   navText: ['', ''],
    //   items: 2,
    //   // responsive: {
    //   //   0: {
    //   //     items: 3
    //   //   },
    //   //   400: {
    //   //     items: 3
    //   //   },
    //   //   740: {
    //   //     items: 3
    //   //   },
    //   //   940: {
    //   //     items: 3
    //   //   }
    //   // },
    //   nav: true
    // }
  
    @ViewChild('owlCar', { static: true }) owlCar: CarouselComponent;
  
    constructor(public modalRef: BsModalRef) {}
  
    @Output() deletePhotoFromAlbum:EventEmitter<any> = new EventEmitter();
   
    ngOnInit() {
        console.log(this.slidesStore);
        console.log(this.selectedImageIndex);
        // let anyService = this.owlCar as any;
        // let carouselService = anyService.carouselService as CarouselService;  
        // carouselService.to(this.selectedImageIndex,500);
        // this.owlCar.to(this.selectedImageIndex.toString());

        // debugger;

        let intr;
        intr = setInterval(()=>{
            console.log(this.owlCar.carouselLoaded);
            if(this.owlCar.carouselLoaded){
                this.owlCar.to(this.selectedImageIndex.toString());
                clearInterval(intr);
            }
        },2000)
        // this.owlCar.carouselLoaded

        // console.log($('.loop'));

        // $('.loop').on('initialized.owl.carousel translate.owl.carousel', function(e){
        //     console.log(e);
        //     let idx = e.item.index;
        //     ($('.owl-item.big') as any).removeClass('big');
        //     $('.owl-item.medium').removeClass('medium');
        //     $('.owl-item').eq(idx).addClass('big');
        //     $('.owl-item').eq(idx-1).addClass('medium');
        //     $('.owl-item').eq(idx+1).addClass('medium');
        // });
    
    
        // $('.loop').owlCarousel({
        //     center: true,
        //     items:3,
        //     // loop:true,
        //     margin:10,
        //     // autoplay: true,
        //     // autoPlay: 3000
        //     loop: false,
        //     rewind: true
        // })
    }
  
    onClickDeletePhotoAlbum(){
      this.deletePhotoFromAlbum.emit(this.activeSlidePosition.toString());
    }
    activeSlidePosition = 0;
    getActiveSlideData(data: SlidesOutputData) {
      console.log("active slide",data);
      this.activeSlidePosition = data.startPosition;
      console.log(this.activeSlidePosition);
    
    /************** Get Index of the Item and add the class **************************/
        // document.querySelector(".owl-item.active123")?.classList.remove('active123');
        // document.querySelectorAll(".owl-item")[this.activeSlidePosition].classList.add('active123');

    /************** **************************/

    }

    addCss(){

    }
  
    ngAfterViewInit()	{
  
    }
   
  }