<!-- <p>soundcloud works!</p> -->
<div>
    <div class="d-flex align-items-center custom mt-2 width100mob" *ngIf="!userId">
        <div class="form-group col-lg-9 pl-0" style="padding-right: 25px;">
            <input type="text" [(ngModel)]="myInputValues" class="form-control" id="soundcloudIframeNew" aria-describedby="emailHelp"
                placeholder="Enter SoundCloud embed (iframe) code" style="border: 1px solid #E9E9EB;
                border-radius: 15px;">
        </div>
        <div>
            <button type="button" class="btn btn-dark btn-lg form-group" [disabled]="!myInputValues.length" (click)="addSoundcloudIframe()" style="height: 50px;
            /* border-radius: 11px;
            font-size: 20px;
            font-weight: 500; */
            width: 120px;border-radius: 8px;
            background: var(--primary-colour, #0F0D0F);
            color: var(--new-style, #F8F9F8);
            /* text-align: center; */
            font-size: 16px;
            /* font-family: Poppins; */
            font-weight: 600;">Submit</button>
        </div>
    </div>
    <div *ngIf="soundcloudList.length" style="height: 600px;" class="soundcloud-list" infinite-scroll [scrollWindow]="false"
    (scrolled)="loadMoreSoundcloudData($event)" [infiniteScrollDistance]="6" infinite-scroll-disabled="!loadMoreSoundcloud">
        <div *ngFor="let soundcloud of soundcloudList;let index=index">
            <div class="spotify-music-card m-2">
                <div class="row" style="align-items: center">
                    <div class="col-1 col-md-1 mob-md-1" (click)="soundcloudModalP(soundcloud.url)" style="padding-left: 25px;">
                      <!-- https://f4.bcbits.com/img/a1095638119_4.jpg -->
                      <!-- assets/Indietown_icons/Music-Image.png -->
                      <img
                         src="assets/Indietown_icons/login-logo-white.svg"
                         alt=""
                         height="60"
                         width="60"
                         style="border-radius: 10px;padding: 4px;background-color: black;"
                         />
                    </div>
                    <div class="col-lg-9 col-md-9 mob-md-9 ml-4" style="padding-left: 32px;" (click)="soundcloudModalP(soundcloud.url)">
                        <p class="spot-title" style="color: var(--primary-colour, #0F0D0F);
                        font-size: 16px;
                        font-family: Poppins;">{{soundcloud.title}}</p>
                    </div>
                    <div style="transform: translate(-16px, 0px);" (click)="removeSoundcloud(soundcloud._id,index)" *ngIf="!userId">
                        <button type="button" class="btn btn-light" style="border-radius: 6px;
                        border: 1px solid #E9E9EB;
                        background: rgba(233, 233, 235, 0.30);">Remove</button>
                    </div>
                    <!-- (click)="removeSingleTrack(spotifyIndex)" -->
                   <!-- <div class="col-lg-2 col-md-2 mob-md-2">
                      <p class="spot-time">03:29</p>
                      </div> -->
                </div>
             </div>
            <!-- <iframe [src]="transFormUrl(soundcloud.url)"></iframe> -->
        </div>
    </div>
    <div *ngIf="!soundcloudList.length && userId" style="text-align: center;
    font-size: 23px;
    font-weight: 600;
    line-height: 33px;
    margin-top: 20px;">
        No Playlists yet !
    </div>
    <div *ngIf="!soundcloudList.length && !userId" style="text-align: center;
   font-size: 23px;
    font-weight: 600;
    margin-top: 20px;">
       Add Playlists
    </div>
</div>
<ng-template #soundcloudIframe>
    <!-- <div class="modal-header ml-2">
      <div class="text-center">
        <h1 class="reporttitle">Report Inappropriate Content</h1>
      </div>
      <div>
        <button type="button" (click)="modalRef.hide()" aria-label="Close">
          <img  src="../../../assets/Indietown_icons/close.svg" alt=""></button>
      </div>
    </div> -->
    <div class="modal-body">
        <div style="text-align: end;
        margin-bottom: 10px;">
            <button type="button" (click)="modalRef.hide()" aria-label="Close" style="border: none;
            background: black;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            padding: 0;">
              <img  src="../../../assets/Indietown_icons/close.svg" alt="" style="width: 20px;">
            </button>
        </div>
        <!-- <iframe [src]="transFormUrl(iframeSource)" style="width: 700px;
        height: 380px;"></iframe> -->
        <iframe [src]="iframeSource | safeUrl" style="width: 700px;
        height: 380px;"></iframe>
    </div>
</ng-template>

<ng-template #removeSoundcloudIframeModal>
    <div class="modal-header">
      <button type="button" class="close pull-right" aria-label="Close" (click)="removeModalref.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body text-center align-items-center">
      <p style="line-height: 26px;font-size: 16px;font-weight: bold;color: black;">Are you sure you want to remove?</p>
      <div class="modal-btn d-flex justify-content-around" style="margin-top: 39px;">
        <button class="btn btn-red nobtn" (click)="removeSoundcloudIframe()" style="color: black;margin-right: 10px;font-weight:bold;">Yes</button>
        <button class="btn btn-blue logoutbtn" (click)="removeModalref.hide()" style="background-color: black;font-weight:bold;">No</button>
      </div>
    </div>
</ng-template>