import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";
// import { User } from '../models/user.model';
import { User } from "src/app/auth/models/user.model";
import { Subject } from "rxjs";
import { coerceStringArray } from "@angular/cdk/coercion";

@Injectable()

export class RegistrationPartService {

    private userSubject: BehaviorSubject<User>;
    // public user: Observable<User>;


    private emailSend = new Subject<any>();
    child1$ = this.emailSend.asObservable();


    SendToChild1(msg:string){
        this.emailSend.next(msg);
    }

    url: any = environment.API_URL;

    constructor(
        private http: HttpClient
    ) { }

    public get currentUserValue() {
        let data = localStorage.getItem('currentUser');
        let obj: Object = {};
        if (data !== 'undefined')
            return JSON.parse(localStorage.getItem('currentUser'));
        return false;
    }

    public get language() {
        let lang = localStorage.getItem('lang');
        console.log('lang', lang);
        if (lang) {
            return lang;
        }
        return false;
    }

    login(user: any) {
        return this.http.post(this.url + 'api/user/login', user);
    }

    reset(data: any) {
        return this.http.post(this.url + 'user/reset-password',data);
    }


    forgetPassword(data: any) {
        return this.http.post(this.url + 'api/user/forgot-password',data);

        
    }


    

    logout(data:any) {
        // localStorage.removeItem('currentUser');
        return this.http.post(this.url + 'user/logout', data);
    }

    updateuser(data: any) {
        return this.http.patch(this.url + 'user/update', data);
    }

    changePassword(data: any) {
        return this.http.post(this.url + 'user/change-password', data);
    }

    emailValidation(data:any){
        return this.http.post(this.url + "user/emailExists", data);
    }

    signUpUser(data:any){
        return this.http.post(this.url + 'api/user/signup', data);
    }

    uploadProfile(data:any){
        return this.http.post(this.url + 'api/file/upload', data);
    }

    validateInvitationCode(data:any){
        return this.http.post(this.url + 'api/user/validate-invitation-code', data);
    }

    sendOTP(data:any){
        return this.http.post(this.url + 'api/user/send-otp', data);
    }


    verifyOTP(data:any){
        return this.http.post(this.url + 'api/user/verify-otp', data);
    }

    updateProfile(data:any){
        return this.http.put(this.url + 'api/user/update-profile', data);
    }

    updatePlaylists(data:any){
        return this.http.put(this.url + 'api/user/update-play-list', data);
    }

    getGenres(){
        return this.http.get(this.url + 'api/community/list');
    }


    getBandSuggestions(data:any){
        return this.http.post(this.url + 'api/user/get-bands-suggession', data);
    }

    AddFriend(data:any){
        return this.http.post(this.url + 'api/friend/add-friend', data);
    }

    cancelRequest(id:any,data:any){
        return this.http.put(this.url + `api/friend/update-friend-request/${id}`, data);
    }
    
    updatePlayList(data:any){
        return this.http.put(this.url + 'api/user/update-play-list', data);
    }

    getArtistGenerList(){
        return this.http.get(this.url + 'api/genere/list');
    }

    getProfile(){
        return this.http.get(this.url + 'api/user/profile');
    }

    requestCode(data:any){
        return this.http.post(this.url + 'api/user/request-invitation-code',data);
    }

    getGenerSuggestions(keyword){
        let url = this.url + `api/genere/suggestions`;
        if(keyword)
            url = url + `?keyword=${keyword}`;
        return this.http.get(url);
    }

}