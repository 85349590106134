<!-- <div id="forgot-password">
  <div class="container-fluid">
    <div class="d-flex logoimg">
      <div class="logo">
        <img
          src="../../../../assets/Indietown_icons/login-logo.svg"
          alt="logo"
        />
      </div>
    </div>
  </div>
</div> -->

      <div class="page centerplacement" style="display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;">
        <div class="profilepictureblock">
          <div class="text-center main-heading" style="margin-top: 23px;
          margin-bottom: 81px;">
            <h1><span class="text-grey">Update</span> Profile Picture</h1>
          </div>
          <!-- seconde page -->
          <form action="#" [formGroup]="updateProfile">
          <div class="profile-box" style="position: relative">
            <div
              class="text-center"
              style="
                position: relative;
                bottom: 34px;
                right: 10px;
                transform: translate(10px, 175px);
              "
            >
              <label class="profile-name" for="">{{
                this.profileData?.bandName
              }}</label>
              <div>
                <label class="profile-location" style="padding-top: 20px;" for=""
                  >{{ this.profileData?.address?.city }}, {{ this.profileData?.address?.state }},
                  {{ this.profileData?.address?.country }}</label
                >
              </div>
            </div>

            <div
              class="text-center inputimagefield"
              style="margin-top: -45%; margin-left: -2%"
            >
              <div class="file-input-wrapper">
                <img
                  [src]="profilePicUrl"
                  alt=""
                  class="profileimag"
                  style="
                    width: 200px;
                    height: 200px;
                    border: 1px solid #f1f1f1;
                    border-radius: 100%;
                  "
                  id="imageView"
                />
                <!-- <span (click)="deleteProfilePic()" *ngIf="showDeleteButton">
                  <img src="../../../../assets/Indietown_icons/cross.svg" height="20" width="20" alt="" style="top: 0px;
                  background: #FFFFFF;
                  z-index: 99999;
                  border: 1px solid rgba(15, 13, 15, 0.2);
                  box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.05);
                  cursor: pointer;
                  padding: 10px;
                  width: 40px;
                  height: 40px;
                  border-radius: 50%;
                  position: absolute;">  
                </span> -->
                <!-- multiple="" -->
                <!-- UPDATE CODE FOR EDIT OPTIONS -->
                <!-- <input *ngIf="!showDeleteProfileButton" #myFile2 type="file" name="myFile2"
                    id="myFile2" value=""
                    (change)="openProfilePictureModal('choose',$event,ProfilePicCropModal)"
                    accept="image/*" />
                <input *ngIf="!showDeleteProfileButton" type="hidden" name="" id=""
                    formControlName="profilePic" />
                  <div class="camera">
                    <img src="../../../../assets/Indietown_icons/camera.png"
                        *ngIf="!showDeleteProfileButton" height="50" width="50"
                        id="imageView" />
                    <img src="../../../../assets/Indietown_icons/edit-icon2.svg"
                        (click)="popupedit=true"  style="border-radius: 50% !important;cursor: pointer;"
                        *ngIf="showDeleteProfileButton" height="50" width="50" id="imageView" /> -->
                        <input
                  #myFile2
                  type="file"
                  name="myFile2"
                  id="myFile2"
                  value=""
                  (change)="openProfilePictureModal('choose',$event,ProfilePicCropModal)"
                  accept="image/*"
                  style="visibility: hidden;"
                />
                <input
                  type="hidden"
                  name=""
                  id=""
                  
                />
                <div class="camera">
                  <img src="../../../../assets/Indietown_icons/camera.png" alt="" height="50" width="50" id="imageView" *ngIf="!showDeleteProfileButton" (click)="myFile2.click()"/>
                  <img src="../../../../assets/Indietown_icons/edit-icon2.svg" alt="" height="50" width="50" id="imageView" *ngIf="showDeleteProfileButton" (click)="popupedit=true"/>
                </div>
            </div>
          </div>
          </div>
        </form>
      <div class="save-button" style="margin-top: 45px;">
        <button class="btn btn-primary" (click)="save()"> Save </button>
      </div>
      <ng-template #ProfilePicCropModal>
        <div class="" >
        <div  class="modal-header " style="padding: 20px;    padding-bottom: 10px;">
            <h4 class="modal-title pull-left" style="font-weight: 600;
    margin-bottom: 0px;">Profile Picture</h4>
            <button type="button" class="btn-close close pull-right"
                style="width:30px; height: 30px; font-size: 30px; opacity: 1;"
                aria-label="Close" (click)="closeProfilePicModal()">
                <span aria-hidden="true" style="color: #fff; opacity: 1; text-shadow: none;"
                    class="visually-hidden">&times;</span>
            </button>
        </div>
        <div class="modal-body "  style="padding: 20px;">
            <div class="profile-crop">
                <image-cropper [imageChangedEvent]="imageChangedEvent"
                    [imageURL]="profilePicCropperUrl" [maintainAspectRatio]="true"
                    [aspectRatio]="1 / 1" format="png" [transform]="imageTransform"
                    roundCropper="true"
                    (imageCropped)="croppedProfilePicture($event)"></image-cropper>
            </div>
            <div style="padding-top: 10px;">
                <div class="d-flex align-items-center" style="margin-top: 16px;">
                    

                  <!-- margin-top:20px !important -->
                        <button class="text-white croppic"
                        style="background: transparent !important;outline:0 !important; color: #555 !important;  border:none !important; padding: 0px !important; margin-right: 5px !important;  font-size: 28px !important;"
                        (click)="zoomOut()">-</button>
                        <!-- margin-top:-10px !important; -->
                    <input type="range" min="1" max="2" step=".1" value="{{scale}}"
                        (mouseup)="ZoomImage($event)" class="slider" id="myRange"
                        style="background: #ccc;">
                        <!-- margin-top:20px !important;  -->
                    <button class="text-white croppic"
                        style="background: transparent !important;outline:0 !important; color: #555 !important;  border:none !important; font-size: 28px !important;padding: 0px !important; margin-left: 5px !important;"
                        (click)="zoomIn()">+</button>
                    <button type="button" class="btn croppic"
                        (click)="profilePictureCancelled()" style="background: #efefef;" style="    background: #efefef;
                        background-color: #efefef !important;
                        border: none !important;
                        padding-left: 0;
                        margin-right: 10px;
                        margin-left: 10px;"><span
                            style="background: #efefef; padding:4px 10px; border-radius: 4px; color: #000;">Cancel</span></button>
                    <button type="button" class="btn croppic" style="background: #000;"
                        (click)="uploadProfile()"><span
                            style="background: #000; padding:4px 10px; border-radius: 4px; color: #fff; margin-left: 10px;">Save</span></button>
                </div>
            </div>
        </div>
        </div>
    </ng-template>
    <div class="overlay" *ngIf="popupedit">
        <div class="popupeditblock">
            <!-- <a class="close" (click)="popupedit = false"><img _ngcontent-nbr-c249="" src="../../../../assets/Indietown_icons/cross.svg" height="20" width="20" alt="" style="top: 10px;right: 13px;background: #fff;border-radius: 32px;box-shadow: 0 0px 1px #000000b5;padding: 5px;position: absolute;"></a> -->
            <div class="content-edit">
                <a style="cursor: pointer;" (click)="deleteProfilePic()">Delete Photo</a>
                <a (click)="openProfilePictureModal('edit','',ProfilePicCropModal)">Edit
                    Photo</a>
                <a (click)="file.click()">Choose Photo
                    <input #file type="file" class="d-none"
                        (change)="openProfilePictureModal('choose',$event,ProfilePicCropModal)"
                        accept="image/*">
                </a>
                <a (click)="popupedit = false">Cancel</a>
            </div>
        </div>
    </div>
        </div>
        </div>
