<div id="forgot-password">
    <div class="container-fluid">
        <div class="row logoimg">
            <div class="logo hidden-xs"><img src="../../../../assets/Indietown_icons/login-logo.svg" alt="logo"></div>

            <div class="col-md-12" id="alignscreencenter" >
                <div class="login-field" style="flex-direction: column;">
                    <div class="logo visible-xs"><img src="../../../../assets/Indietown_icons/login-logo.svg" alt="logo"></div>
                    <div class="login-box">
                        <div class="main-content">
                                <h3><span class="enter">Enter</span> Verification Code</h3>
                                <div class="text-center">
                                    <p>Please type the verification code sent to <br> {{countryCode}} {{phoneNumber}}</p>
                                </div>
                                <form [formGroup]="resetOTPForm">
                                    <div class="form-group">
                                        <!-- <label for="email">Code</label> -->
                                            
                                        <div class="d-flex justify-content-center enter-opt">
                                            <span>
                                                <input type="tel" class="form-control" autocomplete="off" placeholder="-" id="firstDigit" formControlName="firstDigit" style="width: 70px;" maxlength="1" (keyup)="onFillCode1($event.target)" (keypress)="numberOnly($event)" (keydown)="checkbackpress($event,1)" [ngClass]="resetOTPForm.value.firstDigit != ''? 'dynamic': ''">
                                                <!-- <div *ngIf="submitted && f.firstDigit.errors" class="alert alert-danger">
                                                    <span *ngIf="f.firstDigit.errors.required">
                                                        required
                                                    </span>
                                                    <span *ngIf="f.firstDigit.errors.email">
                                                        required
                                                    </span>
                                                </div> -->
                                            </span>


                                            <span>
                                                <input type="tel" class="form-control" autocomplete="off" placeholder="-" id="secondDigit" formControlName="secondDigit" style="width: 70px;" maxlength="1" (keyup)="onFillCode2($event.target)" (keypress)="numberOnly($event)" (keydown)="checkbackpress($event,2)" [ngClass]="resetOTPForm.value.secondDigit != ''? 'dynamic': ''">
                                                <!-- <div *ngIf="submitted && f.secondDigit.errors" class="alert alert-danger">
                                                    <span *ngIf="f.secondDigit.errors.required">
                                                        required
                                                    </span>
                                                    <span *ngIf="f.secondDigit.errors.email">
                                                        required
                                                    </span>
                                                </div> -->
                                            </span>


                                            <span>
                                                <input type="tel" class="form-control" autocomplete="off" placeholder="-" id="thirdDigit" formControlName="thirdDigit" style="width: 70px;" maxlength="1" (keyup)="onFillCode3($event.target)" (keypress)="numberOnly($event)" (keydown)="checkbackpress($event,3)"  [ngClass]="resetOTPForm.value.thirdDigit != ''? 'dynamic': ''">
                                                <!-- <div *ngIf="submitted && f.thirdDigit.errors" class="alert alert-danger">
                                                    <span *ngIf="f.thirdDigit.errors.required">
                                                        required
                                                    </span>
                                                    <span *ngIf="f.thirdDigit.errors.email">
                                                        required
                                                    </span>
                                                </div> -->
                                            </span>


                                            <span>
                                                <input type="tel" class="form-control" autocomplete="off" placeholder="-" id="forthDigit" formControlName="forthDigit" style="width: 70px;" maxlength="1" (keyup)="onFillCode4($event.target)" (keypress)="numberOnly($event)" (keydown)="checkbackpress($event,4)" [ngClass]="resetOTPForm.value.forthDigit != ''? 'dynamic': ''">
                                                <!-- <div *ngIf="submitted && f.forthDigit.errors" class="alert alert-danger">
                                                    <span *ngIf="f.forthDigit.errors.required">
                                                        required
                                                    </span>
                                                    <span *ngIf="f.forthDigit.errors.email">
                                                        required
                                                    </span>
                                                </div> -->
                                            </span>
                                        </div>
                                    </div>

                            

                                        <div class="text-center my-3">
                                            <p class="text-center" id="showTime">Resend Code in :  <span id="time" style="font-weight: 600;"></span></p>
                                            <!-- <div>Have you received the code? <font style="font-weight: bold;cursor: pointer;" (click)="sendCodeAgain()"> <button id="resendButton" class="btn btn-link" style="color:black"> Resend</button></font></div> -->
                                            <div>Have you received the code? <font style="font-weight: bold;cursor: pointer;" (click)="sendCodeAgain()"> <button  id="resendButton" class="btn btn-link" style="color: black;font-weight: 600;padding-left: 0px; text-decoration: none;"> Resend</button></font></div>
                                        </div>
                                    <button type="submit" class="btn btn-primary" id="nextBtn" [disabled]="resetOTPForm.invalid" (click)="resetPassword()">Next</button>
                                    <div class="m-20">
                                        <!-- <a routerLink="/register/phone" class="backbtnn"><img 
                                        style="
                                            width: 14px;
                                            margin-right: 1px;" src="../../../../assets/images/backarrow.svg" alt=""> Back</a> -->
                                        <!-- <a routerLink="/register"><img src="../../../../assets/Indietown_icons/back-arrow-icon.svg" alt="" height="50" width="20"></a> -->
                                    </div>
                                </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>