import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable()
export class SharedService {
  url: any = environment.API_URL;
  constructor(private http: HttpClient) {}

  // uploadImage(file: any) {
  //   return this.http.post(this.url + "upload/image", file);
  // }

  private data = new Subject<any>();
  public data$ = this.data.asObservable();

  private play = new Subject<any>();
  public play$ = this.play.asObservable();

  private playSpotify = new Subject<any>();
  public playSpotify$ = this.playSpotify.asObservable();

  private sendPlayList = new Subject<any>();
  public receivePlayList$ = this.sendPlayList.asObservable();

  private closeSearchModal = new Subject<any>();
  public closeSearchModal$ = this.sendPlayList.asObservable();

  private locationAvailable = new Subject<any>();
  public locationAvailable$ = this.locationAvailable.asObservable();

  closeSearch(id:any){
    this.closeSearchModal.next(id);
  }

  checkLocation(x:any){
    this.locationAvailable.next(x); 
  }

  selectYoutube(x: any){
    this.data.next(x);
  }

  playYoutube(x:any){
    this.play.next(x)
  }

  playSpotifySong(x:any){
    this.playSpotify.next(x)
  }

  sendPlaydata(x:any){
    this.sendPlayList.next(x);
  }

  private editClick = new Subject<any>();
  public editClick$ = this.editClick.asObservable();

  private headerData = new Subject<any>();
  public headerData$ = this.headerData.asObservable();

  private activateModalHeader = new Subject<any>();
  public activateModalHeader$ = this.activateModalHeader.asObservable();

  activate(x:any){
    this.activateModalHeader.next(x);
  }

  editClickOpenModal(x:any){
    this.editClick.next(x);
  }

  sendHeaderData(e:any){
    this.headerData.next(e);
  }




  getGenres(){
    return this.http.get(this.url + 'api/community/list');
}

  uploadVideo(file: any) {
    return this.http.post(this.url + "upload/video", file);
  }

  uploadPdf(file: any) {
    return this.http.post(this.url + "upload/pdf", file);
  }

  deleteInterest(data: any) {
    const httpOptions = {
        headers: new HttpHeaders({}), body: data
    };
    return this.http.delete(this.url + 'category/delete', httpOptions);
}

createPost(data:any){
    return this.http.post(this.url + 'api/post/create-post', data);
}

getPosts(page,pageLimit){
    return this.http.get(this.url + `api/post/list?page=${page}&pageSize=${pageLimit}`);
}

getProfile(){
    return this.http.get(this.url + 'api/user/profile');
}


uploadImage(data:any){
    return this.http.post(this.url + 'api/file/upload', data);
}

uploadImageProgressbar(data:any){

    return this.http.post(this.url + 'api/file/upload', data, {
        reportProgress: true,
        responseType: 'json',
        observe: 'events',
    });
}

createShow(data:any){
    return this.http.post(this.url + 'api/show/create', data);
}

reportPost(data:any,comment:any){
    return this.http.put(this.url + `api/post/report-post/${data}`,comment);
}

createComment(data:any){
    return this.http.post(this.url + 'api/comment/create', data);
}

getCommentsList(data:any,page,pageLimit){
    return this.http.get(this.url + `api/comment/list/${data}?pageNumber=${page}&limit=${pageLimit}`);
    // return this.http.get(this.url + `api/comment/list/${data}`);
}

getBandSuggestions(data:any){
    return this.http.post(this.url + 'api/user/get-bands-suggession', data);
}

AddFriend(data:any){
    return this.http.post(this.url + 'api/friend/add-friend', data);
}


cancelRequest(id:any,data:any){
    return this.http.put(this.url + `api/friend/update-friend-request/${id}`, data);
}

acceptRequest(data:any){
    return this.http.post(this.url + 'api/friend/accept', data);
}

friendRequestList(){
    return this.http.get(this.url + 'api/friend/fr-list');
}

acceptRejectRequest(id:any, data:any){
    return this.http.put(this.url + `api/friend/update-friend-request/${id}`,data);
}

silentPost(id:any, data:any){
    return this.http.put(this.url + `api/post/silent-post/${id}`,data);
}

silentUserPosts(data:any){
    return this.http.post(this.url + `api/user/silent-user`,data);
}

unMuteUserPosts(data:any){
    return this.http.post(this.url + `api/user/un-silent-user`,data);
}

getSilencedUserStatus(id:any){
    return this.http.get(this.url + `api/user/user-status-details/${id}`);
}

reportComment(postId:any, commentId:any,comment:any, data:any){
    return this.http.put(this.url + `api/comment/report`,{postId,commentId,comment});
}

updateProfile(data:any){
    return this.http.put(this.url + 'api/user/update-profile', data);
}

getFriendsList(userId,page,pageLimit){
    let url = 'api/friend/list';
    if(userId)
        url = url + '/' + userId;
    return this.http.get(this.url + url + `?page=${page}&pageSize=${pageLimit}`);
}

getTopFriendsList(){
    return this.http.get(this.url + 'api/friend/top-fr-list');
}

getOtherFriendsList(pageNumber,limit){
    return this.http.get(this.url + 'api/friend/other-friend-list?page='+pageNumber+'&pageSize='+limit);
}


filterFriendsList(keyword,type,userId){
    let url = 'api/friend';
    if(type == 'top'){
        url = url + '/top-fr-list';
    }
    else if(type == 'others'){
        url = url + '/list';
    }
    else if(type == 'friends'){
        url = url + '/list/' + userId;
    }
    return this.http.get(this.url + `${url}?keyword=${keyword}`);
}

getFriendsListById(id:any){
    return this.http.get(this.url + `api/user/user-friend-list/${id}`);
}

updatePlayList(data:any){
    return this.http.put(this.url + 'api/user/update-play-list', data);
}

getPlayList(){
    return this.http.get(this.url + 'api/user/playlist');
}

getPlayListById(id:any){
    return this.http.get(this.url + 'api/user/playlist/'+id);
}

unFriend(id:any,data){
    return this.http.put(this.url + `api/friend/unfriend-user/${id}`,data);
}


getPostById(id:any){
    return this.http.get(this.url + `api/post/${id}`);
}

getPostByIdImpl(id:any,pageSize,pageNumber){
    return this.http.get(this.url + `api/post/list-posts/${id}?page=${pageSize}&pageSize=${pageNumber}&type=POST`);
}

getShowsByIdImpl(id:any,pageSize,pageNumber){
    return this.http.get(this.url + `api/post/list-posts/${id}?page=${pageSize}&pageSize=${pageNumber}&type=SHOW`);
}

getProfileById(id:any){
    return this.http.get(this.url + `api/user/user-profile/${id}`);
}

likePost(id:any,data:any){
    return this.http.put(this.url + `api/post/update/${id}`,data);
}

deletePost(id:any){
    return this.http.delete(this.url + `api/post/delete-user-post/${id}`);
}

getArtistGenerList(){
    return this.http.get(this.url + 'api/genere/list');
}

getLikesList(id:any){
    return this.http.get(this.url + `api/post/post-likes/${id}`);
}

deleteComment(id:any){
    return this.http.delete(this.url + `api/comment/delete/${id}`,{});
}


createAlbum(data:any){
    return this.http.post(this.url + 'api/user/create-album',data);
}


getPhotoAlbum(data:any,pageNumber,pageLimit){
    let url = `api/user/album`;
    if(data)
        url = url + '?userId=' + data.userId + `&page=${pageNumber}&pageSize=${pageLimit}`;
    else
        url = url + `?page=${pageNumber}&pageSize=${pageLimit}`
    return this.http.get(this.url + url);
}


deleteSinglePhoto(data:any){
    return this.http.delete(this.url + `api/user/delete-photo?photoId=${data}`);
}

reportUser(id:any,data:any){
    return this.http.put(this.url + `api/user/report-user/${id}`,data);
}

getFriendsListByUserId(id:any){
    return this.http.get(this.url + `api/friend/list/${id}`)
}
    getSpotifyPlayList(id){
        let url = this.url + 'api/user/spotify-playlist';
        if(id){
           url = url + '?userId='+id;
        }
        return this.http.get(url);
    }
    getYoutubePlayList(data){
        console.log(2);
        console.log(data);
        let url = this.url + 'api/user/youtube-playlist';
        if(data.id){
            console.log(3);
           url = url + '?userId='+data.id;
        }
        if(data.nextPageToken){
            url = url + '?nextPageToken='+data.nextPageToken;
         }
         console.log(url);
        return this.http.get(url);
        // return this.http.get(this.url + 'api/user/youtube-playlist');
    }

deleteMultiplePhotos(ids:any){
    return this.http.put(this.url + 'api/user/delete-photos', ids);
}
    getSpotifyToken(){
        return this.http.get(this.url + 'api/user/get-token');
    }

    makeFavouriteFriend(userId){
        return this.http.post(this.url + 'api/user/add-favrouite',{userId});
    }

    makeUnFavouriteFriend(userId){
        return this.http.put(this.url + 'api/user/remove-favrouite',{userId});
    }
    uploadProfile(data:any){
        return this.http.post(this.url + 'api/file/upload', data);
    }

    getUsersForMention(data){
        let url = this.url + `api/user/filter-bands-list?page=1&pageSize=16`;
        url = data.keyword ? url + `&keyword=${data.keyword}` : url ;
        return this.http.get(url);
    }

    replyToPostComment(data){
        return this.http.post(this.url + 'api/comment/replyToComment',data);
    }

    getRepliesOfComment(commentId,pageNumber,limit){
        return this.http.get(this.url + `api/comment/show-more-replies?commentId=${commentId}&pageNumber=${pageNumber}&limit=${limit}`);
    }
    reportCommentReply(data){
        return this.http.put(this.url + `api/comment/report-child?postId=${data.postId}&comment=${data.comment}&childCommentId=${data.childCommentId}`,data);
    }

    deleteReply(postId,replyId:any){
        return this.http.delete(this.url + `api/comment/delete-child?id=${replyId}&postId=${postId}`,{});
    }

    updatePost(id:any,data:any){
        return this.http.put(this.url + `api/post/update-post/${id}`,data);
    }
    commentOnFriendsTimeline(data){
        return this.http.post(this.url + 'api/post/create-post', data);
    }

    addSpotifyUrl(data){
        return this.http.post(this.url + 'api/playlist/add-spotify-url', data);
    }

    getSpotifyList(userId,page,pageSize){
        let urlPath = `api/playlist/spotify?page=${page}&pageSize=${pageSize}`;
        if(userId){
            urlPath = urlPath + `&userId=${userId}`;
        }
        return this.http.get(this.url + urlPath);
    }

    removeSingleTrack(id:any){
        return this.http.put(this.url + `api/playlist/remove-playlist/${id}`,{});
    }

    removePlaylist(id:any){
        return this.http.put(this.url + `api/playlist/remove-playlist/${id}`,{});
    }

    removePlaylistItem(id:any){
        return this.http.put(this.url + `api/playlist/remove-playlist-item/${id}`,{});
    }

    getPlaylistItems(id:any){
        return this.http.get(this.url + `api/playlist/byId/${id}`);
    }
    getSoundcloudList(userId,page,pageSize){
        let urlPath = `api/playlist/soundcloud-list?page=${page}&pageSize=${pageSize}`;
        if(userId){
            urlPath = urlPath + `&userId=${userId}`;
        }
        return this.http.get(this.url + urlPath);
    }
    addSoundcloudIframe(data){
        return this.http.post(this.url + 'api/playlist/add-soundcloud', data);
    }
    removeSoundcloudIframe(id:any){
        return this.http.delete(this.url + `api/playlist/delete-item/${id}`);
    }
    getBandcampList(userId,page,pageSize){
        let urlPath = `api/playlist/bandcamp-list?page=${page}&pageSize=${pageSize}`;
        if(userId){
            urlPath = urlPath + `&userId=${userId}`;
        }
        return this.http.get(this.url + urlPath);
    }
    addBandcampIframe(data){
        return this.http.post(this.url + 'api/playlist/add-bandcamp', data);
    }
    removeBandcampIframe(id:any){
        return this.http.delete(this.url + `api/playlist/delete-item/${id}`);
    }

    getSpotifyAuthToken(){
        return this.http.get(this.url + `api/user/spotify-token`);
    }

    saveOrUpdateSpotifyToken(data){
        return this.http.post(this.url + 'api/user/update-spotify-token', data);
    }

    getYoutubeList(userId,page,pageSize){
        let urlPath = `api/playlist/youtube?page=${page}&pageSize=${pageSize}`;
        if(userId){
            urlPath = urlPath + `&userId=${userId}`;
        }
        return this.http.get(this.url + urlPath);
    }
    addYoutubeUrl(data){
        return this.http.post(this.url + 'api/playlist/add-youtube-url', data);
    }
    removeYoutubeUrl(id:any){
        // return this.http.delete(this.url + `api/playlist/delete-item/${id}`);
        return this.http.delete(this.url + `api/playlist/delete-item/${id}`);
    }
    getYoutubePlaylistItems(id:any){
        return this.http.get(this.url + `api/playlist/byId/${id}`);
    }

    removeYoutubePlaylist(id:any){
        return this.http.put(this.url + `api/playlist/remove-playlist/${id}`,{});
    }

    removeYoutubePlaylistItem(id:any){
        return this.http.put(this.url + `api/playlist/remove-playlist-item/${id}`,{});
    }

    removeSpotifyUrl(id:any){
        // return this.http.delete(this.url + `api/playlist/delete-item/${id}`);
        // return this.http.delete(this.url + `api/playlist/delete-item/${id}`);
        return this.http.put(this.url + `api/playlist/remove-playlist/${id}`,{});
    }

    removeSpotifyPlaylist(id:any){
        return this.http.put(this.url + `api/playlist/remove-playlist/${id}`,{});
    }

    removeSpotifyPlaylistItem(id:any){
        return this.http.put(this.url + `api/playlist/remove-playlist-item/${id}`,{});
    }

    AcceptFriendRequest(data){
        return this.http.put(this.url + 'api/friend/update-request-by-id', data);
    }
}
