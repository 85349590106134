import { Component, OnInit, ViewChild, TemplateRef, Output, EventEmitter } from '@angular/core';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from 'ngx-toastr';
import { LocationStrategy } from '@angular/common';
import * as $ from 'jquery';
import { RegistrationPartService } from 'src/app/views/registration-part/services/registration.service';
@Component({
  selector: 'shared-updated-invitation-code',
  templateUrl: './updated-invitation-code.component.html',
  styleUrls: ['./updated-invitation-code.component.scss']
})
export class UpdatedInvitationCodeComponent implements OnInit {

  submitted: boolean = false;
  modalRef: BsModalRef;
  phoneNumber:any;
  countryCode:any;
  isTimer:boolean = false;
  generUpdatemodalRef;

  @Output() OTPDetails = new EventEmitter()
  @ViewChild("template") template: TemplateRef<any>;
  constructor(
    private service: RegistrationPartService,
    private router: Router,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private location: LocationStrategy
  ) { 
    history.pushState(null, null, window.location.href);  
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });  
  }

  resetOTPForm = new FormGroup({
    firstDigit: new FormControl('', [Validators.required,Validators.maxLength(1)]),
    secondDigit: new FormControl('', [Validators.required,Validators.maxLength(1)]),
    thirdDigit: new FormControl('', [Validators.required,Validators.maxLength(1)]),
    forthDigit: new FormControl('', [Validators.required,Validators.maxLength(1)]),
  })

  ngOnInit(): void {
    this.phoneNumber = localStorage.getItem('phone');
    this.countryCode = localStorage.getItem('countryCode');
    this.countryCode = this.countryCode.slice(1,this.countryCode.length-1);
    this.phoneNumber =  this.phoneNumber.slice(1,this.phoneNumber.length-1);
    document.getElementById("showTime").style.display='none';
    let Time = localStorage.getItem('time');
    let prevDate = localStorage.getItem('date');
    if(prevDate){
      let currentTime = new Date().getTime();
      let pre = new Date(prevDate.slice(1,prevDate.length-1)).getTime();

      let rem = currentTime-pre;
      if(rem+parseInt(Time)>0){
        this.isTimer = true;
        this.getTimeRemaining(parseInt(Time)-rem);
      }
      
      // console.log("rem",rem,Time);
      // if(rem>parseInt(Time)){
      //   document.getElementById('showTime').style.display = 'block';
      //     this.getTimeRemaining(parseInt(Time)-rem);  
      // }
    }

    // $(document).on("textInput keydown", "#text", function(evt) {
         
    //   console.log("emnter ");
    //   let value = evt.target.value;
    //   var charCode = evt.which ? evt.which : evt.keyCode;
    
    //   if(charCode == 46 || charCode == 37){
    //     return false;
    //   }
    //   if (charCode == 8 || charCode == 37) {
    //     return true;
    //   } else if (charCode == 46 && value.indexOf(".") != -1) {
    //     return false;
    //   } else if (
    //     charCode > 31 &&
    //     charCode != 46 &&
    //     (charCode < 48 || charCode > 57)
    //   ) {
    //     return false;
    //   }
    //   return true;
    // })
  }

  get f() {
    return this.resetOTPForm.controls;
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }


  sendEmailToLogin:any;

  resetPassword() {
    this.submitted = true;
    if (this.resetOTPForm.invalid || this.resetOTPForm.pristine) return;
    this.submitted = false;

    let otp = parseInt(this.resetOTPForm.value.firstDigit + this.resetOTPForm.value.secondDigit + this.resetOTPForm.value.thirdDigit + this.resetOTPForm.value.forthDigit);
    

    let a = (<HTMLInputElement>document.getElementById('firstDigit')).value;
    let b = (<HTMLInputElement>document.getElementById('secondDigit')).value;
    let c = (<HTMLInputElement>document.getElementById('thirdDigit')).value;
    let d = (<HTMLInputElement>document.getElementById('forthDigit')).value;

    let data = parseInt(a+b+c+d);

    // if(otp!=1234){
      this.service.verifyOTP({otp:data})
      .subscribe((res: any) => {
        if (res.type == 'success') {
          this.toastr.success("Code verified");
          // this.router.navigateByUrl('/register/select-role');
          // this.modalService.hide();
          this.saveMobileNumber();
        }
      },(error)=>{
        this.toastr.error(error?.error?.message);
      })
    // }
    // else{
    //   this.toastr.error("Wrong Verification code. Please try again.");
    // }
  }

  saveMobileNumber(){
    //save
    this.OTPDetails.emit({mobileNumber:this.phoneNumber.toString().replace(' ','').replace(' ',''),countryCode:this.countryCode});
  }

  onFillCode1(e:any){
    e.value ? e.value = e.value?.replace(/[^\d]/g,'') : e.value;
    if(e.value){
      e.value = e.value.toString().slice(0,1);
      document.getElementById('secondDigit').focus();
    }
        
  }

  onFillCode2(e:any){
    e.value ? e.value = e.value?.replace(/[^\d]/g,'') : e.value;
    if(e.value){
      e.value = e.value.toString().slice(0,1);
      document.getElementById('thirdDigit').focus();
    }
  }

  onFillCode3(e:any){
    e.value ? e.value = e.value?.replace(/[^\d]/g,'') : e.value;
    if(e.value){
      e.value = e.value.toString().slice(0,1);
      document.getElementById('forthDigit').focus();
    }
}

onFillCode4(e:any){
  e.value ? e.value = e.value?.replace(/[^\d]/g,'') : e.value;
  if(e.value){
    e.value = e.value.toString().slice(0,1);
    document.getElementById('nextBtn').focus();
  }
  
}

checkbackpress(evt:any,index:any){
  console.log("key down");
  let key = evt.key;
  if(key === 'Backspace'){
    if(index==2){
      if(this.resetOTPForm.get('secondDigit').value){
        this.resetOTPForm.get('secondDigit').patchValue('');
      }else{
        document.getElementById('firstDigit').focus();
      }
      
    }
    if(index==3){
      if(this.resetOTPForm.get('thirdDigit').value){
        this.resetOTPForm.get('thirdDigit').patchValue('');
      }else{
        document.getElementById('secondDigit').focus();
      }
      
    }
    if(index==4){
      if(this.resetOTPForm.get('forthDigit').value){
        this.resetOTPForm.get('forthDigit').patchValue('');
      }
      else{
        document.getElementById('thirdDigit').focus();
      }
    }
  }
}

numberOnly(evt: any): boolean {
  console.log("emnter ",evt);
  
  let value = evt.target.value;
  var charCode = evt.which ? evt.which : evt.keyCode;

  if(charCode == 46 || charCode == 37){
    return false;
  }
  if (charCode == 8 || charCode == 37) {
    return true;
  } else if (charCode == 46 && value.indexOf(".") != -1) {
    return false;
  } else if (
    charCode > 31 &&
    charCode != 46 &&
    (charCode < 48 || charCode > 57)
  ) {
    return false;
  }
  return true;
}

numberOnlyForMobile(e){
  e.target.value = e.target.value.replace(/[^\d]/g,'');
}


sendCodeAgain() {
  this.submitted = true;
  let phoneNumber = this.phoneNumber.replace(" ",'').replace(" ",'');
  let data = {
    countryCode: this.countryCode,
    mobileNumber: phoneNumber
  }
    this.service.sendOTP(data)
    .subscribe((res: any) => {
      if (res.type == 'success') {
        this.isTimer = true;
        this.toastr.success(res.message);
        this.getTimeRemaining(45000);
      }else{
        this.getTimeRemaining(45000);
        this.toastr.error('error');
      }
    })
}


// 45000
getTimeRemaining(miliseconds:any){
  let x = setInterval(()=>{
    miliseconds = miliseconds - 1000;
    let currentDate = new Date();
    localStorage.setItem('date',JSON.stringify(currentDate));
    localStorage.setItem('time',JSON.stringify(miliseconds));
    // (<HTMLInputElement>document.getElementById('resendButton')).disabled = false;
    // (<HTMLInputElement>document.getElementById('nextBtn')).disabled = false;
    if(document.getElementById('showTime')){
      document.getElementById('showTime').style.display = 'block';
    }
    if(<HTMLInputElement>document.getElementById('resendButton')){
      (<HTMLInputElement>document.getElementById('resendButton')).disabled =  false;
    }
    
    var days:any    = Math.floor(miliseconds / (1000 * 60 * 60 * 24));
    var hours:any   = Math.floor((miliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes:any = Math.floor((miliseconds % (1000 * 60 * 60)) / (1000 * 60));
    var seconds:any = Math.floor((miliseconds % (1000 * 60)) / 1000);

    if(days<10){
      days = `0${days}`
    }
    if(hours<10){
      hours = `0${hours}`
    }
    if(minutes<10){
      minutes = `0${minutes}`
    }

    if(seconds<10){
      seconds = `0${seconds}`
    }

    if(days<=0 && hours<=0 && minutes<=0 && seconds<=0){
      document.getElementById('showTime').style.display = 'none';
      document.getElementById('time').innerHTML =  '0 d :' +  '0 h :' + '0 m :' + "0 s";
      document.getElementById('showTime').style.display = 'none';
      localStorage.removeItem('time');
      localStorage.removeItem('date');
      this.isTimer = false;
      console.log(this.isTimer);
      clearInterval(x);
      (<HTMLInputElement>document.getElementById('resendButton')).disabled = false;
      // (<HTMLInputElement>document.getElementById("nextBtn")).disabled = false;
      // if(this.resetForm?.value?.firstDigit !=undefined && this.resetForm?.value?.secondDigit !=undefined && this.resetForm?.value?.thirdDigit !=undefined && this.resetForm?.value?.forthDigit !=undefined){
      //   (<HTMLInputElement>document.getElementById("nextBtn")).disabled = false;
      // }
    }else{
      // document.getElementById('resendButton').disabled = true;
      this.isTimer = true;
      (<HTMLInputElement>document.getElementById('resendButton')).disabled = true;
      document.getElementById('showTime').style.display = 'block';
      // (<HTMLInputElement>document.getElementById("nextBtn")).disabled = true;
      if(days>0){
        document.getElementById('time').innerHTML = days + ':' + hours + ':' + minutes + ':' + seconds +' minutes';
      }

      if(hours>0 && minutes>=0 && seconds>=0){
        document.getElementById('time').innerHTML = hours + ':' + minutes + ':' + seconds +' minutes';
      }
      if(minutes>=0 && seconds>=0){
        document.getElementById('time').innerHTML = minutes + ':' + seconds +' Seconds';
      }
      
      // console.log(days + ' days ' + hours + ' hours ' + minutes + ' minutes ' + seconds + ' seconds left');
    }
      
  },1000)
}
}
