<div id="auth-width">
    <div class="termscondtionsec">
        <div class="termsconditionblock">
        <div class="term-condition prelative">
            <img
            *ngIf="isMobile" 
            src="../../../assets/Indietown_icons/rightarrowicon.svg"
            routerLink="/settings"
            class="forward-icon settignsnexticn"
            alt=""
          />
            <!-- <a style="cursor:pointer" routerLink="/auth/signup"><img style="float:left;" src="../../../../assets/images/back-arrow.svg" alt="Arrow"></a> -->
            <h1 class="terms-conditions-title" style="font-size: 22px;"><span></span>Terms and Conditions</h1>
        </div>

        <div id="content-term">
            <!-- <h1 class="heading-margin">What is Lorem Ipsum?</h1>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
            </p>

            <h1 class="heading-margin">Why do we use it?</h1>
            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).

            </p> -->
            <p style="font-weight: 500;opacity: 1;">Last Modified: August 23, 2023</p>
                <ul class="main-heading" style="font-size: 16px;margin-left: 10px;">
                    <li><span class="text-decorate">Acceptance of the Terms of Use</span>
                        <ul class="sub-heading">
                            <li>These website terms of use (these <span class="font-weight-bold">"Terms of Use"</span>)
                                of the Indie App, Inc. website,
                                [indietown.org] (the <span class="font-weight-bold">"Website"</span>) are entered into
                                by Indie App, Inc. (the
                                <span class="font-weight-bold">"Company," "We,"</span> or <span
                                    class="font-weight-bold">"Us"</span>) and by visitors to the Website (<span
                                    class="font-weight-bold">"You"</span>). These Terms
                                of Use, together with any documents they incorporate by reference, govern your
                                access to and use of the Website, including any content, functionality, and services
                                offered on or through the Website.
                            </li>
                            <li>
                                Please read the Terms of Use carefully before You start to use the Website. <span
                                    class="font-weight-bold">By
                                    accessing or using the Website or by clicking to accept or agree to the Terms
                                    of Use when this option is made available to You, You accept and agree to be
                                    bound and abide by these Terms of Use and our Privacy Policy, found at
                                    indietown.org, incorporated herein by reference.</span> If You do not want to agree
                                to
                                these Terms of Use or the Privacy Policy, You must not access or use the Website.
                            </li>
                            <li>
                                This Website is only offered and available to users who are 13 years of age or
                                older. By using this Website, You represent and warrant that You are of legal age
                                to form a binding contract with the Company and meet all of the foregoing
                                eligibility requirements.
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span class="text-decorate">Changes to the Terms of Use</span>
                        <ul class="sub-heading">
                            <li>
                                We may revise and update these Terms of Use from time to time in our sole
                                discretion. Your continued use of the Website following the posting of revised
                                Terms of Use means that You accept and agree to the changes. You are expected
                                to check this page from time to time so You are aware of any changes, as they are
                                binding on You.
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span class="text-decorate">Accessing the Website and Account Security</span>
                        <ul class="sub-heading">
                            <li>
                                We reserve the right to withdraw or amend this Website, and any service or
                                material We provide on the Website, in our sole discretion without notice. We will
                                not be liable if for any reason all or any part of the Website is unavailable at any
                                time or for any period. From time to time, We may restrict access to some parts of
                                the Website, or the entire Website.
                            </li>
                            <li>
                                To access the Website or some of the resources it offers, You may be asked to
                                provide certain registration details or other information. It is a condition of your
                                use of the Website that all the information You provide on the Website is correct,
                                current, and complete. You agree that all information You provide to register with
                                this Website or otherwise, including, but not limited to, through the use of any
                                interactive features on the Website, is governed by our Privacy Policy at
                                indietown.org, and You consent to all actions We take with respect to your
                                information consistent with our Privacy Policy.

                            </li>
                            <li>
                                If You choose, or are provided with, a user name, password, or any other piece of
                                information as part of our security procedures, You must treat such information as
                                confidential, and You must not disclose it to any other person or entity. You also
                                acknowledge that your account is personal to You and agree not to provide any
                                other person with access to this Website or portions of it using your user
                                name,password, or other security information. You agree to notify Us immediately of
                                any unauthorized access to or use of your user name or password or any other
                                breach of security. You also agree to ensure that You exit from your account at the
                                end of each session. You should use particular caution when accessing your
                                account from a public or shared computer so that others are not able to view or
                                record your password or other personal information.
                            </li>
                            <li>
                                We have the right to disable any user name, password, or other identifier, whether
                                chosen by You or provided by Us, at any time in our sole discretion for any or no
                                reason, including if, in our opinion, You have violated any provision of these
                                Terms of Use.
                            </li>
                        </ul>
                    </li>
                    <li><span class="text-decorate">Intellectual Property Rights and Trademarks</span>
                        <ul class="sub-heading">
                            <li>The Website and its entire contents, features, and functionality (including but not
                                limited to all information, software, text, displays, images, video, and audio, and
                                the design, selection, and arrangement thereof) are owned by the Company, its
                                licensors, or other providers of such material and are protected by United States
                                and international copyright, trademark, patent, trade secret, and other intellectual
                                property or proprietary rights laws.</li>
                            <li>
                                These Terms of Use permit You to use the Website for your personal,
                                non-commercial use only. You must not reproduce, distribute, modify, create
                                derivative works of, publicly display, publicly perform, republish, download,
                                store, or transmit any of the material on our Website, except as follows.
                                <ul class="sub-sub-heading">
                                    <li>
                                        Your computer may temporarily store copies of such materials in RAM
                                        incidental to your accessing and viewing those materials.
                                    </li>
                                    <li>
                                        You may store files that are automatically cached by your Web browser for
                                        display enhancement purposes.
                                    </li>
                                    <li>
                                        If We provide desktop, mobile, or other applications for download, You
                                        may download a single copy to your computer or mobile device solely for
                                        your own personal, non-commercial use, provided You agree to be bound
                                        by our end user license agreement for such applications.
                                    </li>
                                    <li>
                                        If we provide social media features You may take such actions as are
                                        enabled and authorized by such features.

                                    </li>

                                </ul>
                            </li>
                            <li>
                                You must not:
                                <ul class="sub-sub-heading">
                                    <li>modify copies of any materials from this site;</li>
                                    <li>use any illustrations, photographs, video or audio sequences, or any
                                        graphics separately from the accompanying text; or</li>
                                    <li>
                                        delete or alter any copyright, trademark, or other proprietary rights notices
                                        from copies of materials from this site.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                You must not access or use for any commercial purposes any part of the Website
                                or any services or materials available through the Website.
                            </li>
                            <li>
                                If You print, copy, modify, download, or otherwise use or provide any other
                                person with access to any part of the Website in breach of the Terms of Use, your
                                right to use the Website will stop immediately and You must, at our option, return
                                or destroy any copies of the materials You have made. No right, title, or interest in
                                or to the Website or any content on the Website is transferred to You, and all
                                rights not expressly granted are reserved by the Company. Any use of the Website
                                not expressly permitted by these Terms of Use is a breach of these Terms of Use
                                and may violate copyright, trademark, and other laws.
                            </li>
                            <li>
                                The Company name, the Company logo, and all related names, logos, product and
                                service names, designs, terms, and slogans are trademarks of the Company or its
                                affiliates or licensors. You must not use such marks without the prior written
                                permission of the Company. All other names, logos, product and service names,
                                designs, and slogans on this Website are the trademarks of their respective
                                owners.
                            </li>
                        </ul>

                    </li>
                    <li><span class="text-decorate">Prohibited Uses</span>
                        <ul class="sub-heading">
                            <li>You may use the Website only for lawful purposes and in accordance with these

                                Terms of Use. You agree not to use the Website:
                                <ul class="sub-sub-heading">
                                    <li>in any way that violates any applicable federal, state, local, or international
                                        law or regulation (including, without limitation, any laws regarding the
                                        export of data or software to and from the US or other countries);
                                    </li>
                                    <li>
                                        for the purpose of exploiting, harming, or attempting to exploit or harm
                                        minors in any way by exposing them to inappropriate content, asking for
                                        personally identifiable information, or otherwise;

                                    </li>
                                    <li>
                                        to transmit, or procure the sending of, any advertising or promotional
                                        material without our prior written consent, including any "junk mail,"
                                        "chain letter," "spam," or any other similar solicitation;
                                    </li>
                                    <li>to impersonate or attempt to impersonate the Company, a Company
                                        employee, another user, or any other person or entity (including, without
                                        limitation, by using email addresses or screen names associated with any
                                        of the foregoing); or</li>
                                    <li>
                                        to engage in any other conduct that restricts or inhibits anyone's use or
                                        enjoyment of the Website, or which, as determined by Us, may harm the
                                        Company or users of the Website, or expose them to liability.
                                    </li>
                                </ul>

                            </li>
                            <li>
                                Additionally, You agree not to:
                                <ul class="sub-sub-heading">
                                    <li>use the Website in any manner that could disable, overburden, damage, or
                                        impair the site or interfere with any other party's use of the Website,
                                        including their ability to engage in real time activities through the Website;
                                    </li>
                                    <li>use any robot, spider, or other automatic device, process, or means to
                                        access the Website for any purpose, including monitoring or copying any
                                        of the material on the Website;</li>
                                    <li>
                                        use any manual process to monitor or copy any of the material on the
                                        Website, or for any other purpose not expressly authorized in these Terms
                                        of Use, without our prior written consent;
                                    </li>
                                    <li>
                                        use any device, software, or routine that interferes with the proper working
                                        of the Website;
                                    </li>
                                    <li>
                                        introduce any viruses, Trojan horses, worms, logic bombs, or other
                                        material that is malicious or technologically harmful;
                                    </li>
                                    <li>
                                        attempt to gain unauthorized access to, interfere with, damage, or disrupt
                                        any parts of the Website, the server on which the Website is stored, or any
                                        server, computer, or database connected to the Website;
                                    </li>
                                    <li>
                                        attack the Website via a denial-of-service attack or a distributed
                                        denial-of-service attack; or
                                    </li>
                                    <li>otherwise attempt to interfere with the proper working of the Website.</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li><span class="text-decorate">User Contributions</span>
                        <ul class="sub-heading">
                            <li>The Website may contain message boards, chat rooms, personal web pages or
                                profiles, forums, bulletin boards, and other interactive features (collectively,
                                <span class="font-weight-bold">"Interactive Services"</span>) that allow users to post,
                                submit, publish, display, or
                                transmit to other users or other persons (hereinafter, <span
                                    class="font-weight-bold">"post"</span>) content or materials
                                (collectively, <span class="font-weight-bold">"User Contributions"</span>) on or through
                                the Website.
                            </li>
                            <li>Any User Contribution You post to the site will be considered non-confidential
                                and non-proprietary. By providing any User Contribution on the Website, You
                                grant Us and our affiliates and service providers, and each of their and our
                                respective licensees, successors, and assigns the right to use, reproduce, modify,
                                perform, display, distribute, and otherwise disclose to third parties any such
                                material for any purpose/according to your account settings.</li>
                            <li>
                                You represent and warrant that:
                                <ul class="sub-sub-heading">
                                    <li>You own or control all rights in and to the User Contributions and have the
                                        right to grant the license granted above to Us and our affiliates and service
                                        providers, and each of their and our respective licensees, successors, and
                                        assigns.</li>
                                    <li>
                                        All of your User Contributions do and will comply with these Terms of
                                        Use.
                                    </li>
                                </ul>
                            </li>
                            <li>You understand and acknowledge that You are responsible for any User
                                Contributions You submit or contribute, and You, not the Company, have full
                                responsibility for such content, including its legality, reliability, accuracy, and
                                appropriateness.</li>
                            <li>We are not responsible or liable to any third party for the content or accuracy of
                                any User Contributions posted by You or any other user of the Website.</li>
                            <li>If You believe that any User Contributions violate your copyright, please contact
                                Us at <a href="https://support@indietown.org">support@indietown.org</a> with notice of
                                copyright infringement. It is the
                                policy of the Company to terminate the user accounts of repeat infringers. We
                                respond to notices of alleged copyright infringement under the United States
                                Digital Millennium Copyright Act. If You believe your intellectual property has
                                been infringed, please notify Us at support@indietown.org.</li>
                        </ul>
                    </li>
                    <li><span class="text-decorate">Monitoring and Enforcement; Termination</span>
                        <ul class="sub-heading">
                            <li>We have the right to:
                                <ul class="sub-sub-heading">
                                    <li>remove or refuse to post any User Contributions for any or no reason at
                                        our sole discretion;</li>

                                    <li>take any action with respect to any User Contribution that We deem
                                        necessary or appropriate in our sole discretion, including if We believe
                                        that such User Contribution violates the Terms of Use, infringes any
                                        intellectual property right or other right of any person or entity, threatens
                                        the personal safety of users of the Website or the public, or could create
                                        liability for the Company;</li>

                                    <li>disclose your identity or other information about You to any third party
                                        who claims that material posted by You violates their rights, including
                                        their intellectual property rights or their right to privacy;</li>

                                    <li>take appropriate legal action, including without limitation, referral to law
                                        enforcement, for any illegal or unauthorized use of the Website; or</li>

                                    <li>terminate or suspend your account and your access to all or part of the
                                        Website for any or no reason, including without limitation, any violation
                                        of these Terms of Use.</li>

                                </ul>
                            </li>
                            <li>
                                Without limiting the foregoing, We have the right to cooperate fully with any law
                                enforcement authorities or court order requesting or directing Us to disclose the
                                identity or other information of anyone posting any materials on or through the
                                Website. YOU WAIVE AND HOLD HARMLESS THE COMPANY AND ITS
                                AFFILIATES, LICENSEES, AND SERVICE PROVIDERS FROM ANY
                                CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY OF THE
                                FOREGOING PARTIES DURING, OR TAKEN AS A CONSEQUENCE OF,
                                INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW ENFORCEMENT
                                AUTHORITIES.

                            </li>
                            <li>
                                However, We do not undertake to review all material before it is posted on the
                                Website, and cannot ensure prompt removal of objectionable material after it has
                                been posted. Accordingly, We assume no liability for any action or inaction
                                regarding transmissions, communications, or content provided by any user or
                                third party. We have no liability or responsibility to anyone for performance or
                                nonperformance of the activities described in this section.
                            </li>
                        </ul>
                    </li>
                    <li><span class="text-decorate">Content Standards</span>
                        <ul class="sub-heading">
                            <li>These content standards apply to any and all User Contributions and use of
                                Interactive Services. User Contributions must in their entirety comply with all
                                applicable federal, state, local, and international laws and regulations. Without
                                limiting the foregoing, User Contributions must not:
                                <ul class="sub-sub-heading">
                                    <li>contain any material that is defamatory, obscene, indecent, abusive,
                                        offensive, harassing, violent, hateful, inflammatory, or otherwise
                                        objectionable;</li>
                                    <li>
                                        promote sexually explicit or pornographic material, violence, or
                                        discrimination based on race, sex, religion, nationality, disability, sexual
                                        orientation, or age;

                                    </li>
                                    <li>
                                        infringe any patent, trademark, trade secret, copyright, or other intellectual
                                        property or other rights of any other person;</li>

                                    <li>violate the legal rights (including the rights of publicity and privacy) of
                                        others or contain any material that could give rise to any civil or criminal
                                        liability under applicable laws or regulations or that otherwise may be in
                                        conflict with these Terms of Use and our Privacy Policy at indietown.org
                                    </li>
                                    <li>be likely to deceive any person;</li>

                                    <li>promote any illegal activity, or advocate, promote, or assist any unlawful
                                        act;</li>

                                    <li>cause annoyance, inconvenience, or needless anxiety or be likely to upset,
                                        embarrass, alarm, or annoy any other person;</li>

                                    <li>impersonate any person, or misrepresent your identity or affiliation with
                                        any person or organization;</li>

                                    <li>involve commercial activities or sales, such as contests, sweepstakes, and
                                        other sales promotions, barter, or advertising; or</li>
                                    <li>give the impression that they emanate from or are endorsed by Us or any
                                        other person or entity, if this is not the case.</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li><span class="text-decorate">Reliance on Information Posted</span>
                        <ul class="sub-heading">
                            <li>The information presented on or through the Website is made available solely for
                                general information purposes. We do not warrant the accuracy, completeness, or
                                usefulness of this information. Any reliance You place on such information is
                                strictly at your own risk. We disclaim all liability and responsibility arising from
                                any reliance placed on such materials by You or any other visitor to the Website,
                                or by anyone who may be informed of any of its contents.</li>
                            <li>
                                This Website includes content provided by third parties, including materials
                                provided by other users, bloggers, and third-party licensors, syndicators,
                                aggregators, and/or reporting services. All statements and/or opinions expressed
                                in these materials, and all articles and responses to questions and other content,
                                other than the content provided by the Company, are solely the opinions and the
                                responsibility of the person or entity providing those materials. These materials do
                                not necessarily reflect the opinion of the Company. We are not responsible, or
                                liable to You or any third party, for the content or accuracy of any materials
                                provided by any third parties.
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span class="text-decorate">Changes to the Website</span>
                        <ul class="no-list-type">
                            <li>We may update the content on the Website from time to time, but its content is not
                                necessarily complete or up-to-date. Any of the material on the Website may be out of
                                date
                                at any given time, and We are under no obligation to update such material.</li>
                        </ul>
                    </li>
                    <li>
                        <span class="text-decorate">Information About You and Your Visits to the Website</span>
                        <ul class="no-list-type">
                            <li>All information We collect on this Website is subject to our Privacy Policy at
                                indietown.org. By using the Website, You consent to all actions taken by Us with respect
                                to your information in compliance with the Privacy Policy.</li>
                        </ul>
                    </li>
                    <li>
                        <span class="text-decorate">Other Terms and Conditions</span>
                        <ul class="no-list-type">
                            <li>Additional terms and conditions may also apply to specific portions, services, or
                                features
                                of the Website. All such additional terms and conditions are hereby incorporated by this
                                reference into these Terms of Use.</li>
                        </ul>
                    </li>
                    <li>
                        <span class="text-decorate">Linking to the Website and Social Media Features</span>
                        <ul class="sub-heading">
                            <li>You may link to our homepage, provided You do so in a way that is fair and legal
                                and does not damage our reputation or take advantage of it, but You must not
                                establish a link in such a way as to suggest any form of association, approval, or
                                endorsement on our part without our express consent.</li>
                            <li>The Website may provide certain social media features that enable You to:
                                <ul class="sub-sub-heading">
                                    <li>link from your own or certain third-party websites to certain content on the
                                        Website;</li>
                                    <li>send emails or other communications with certain content, or links to
                                        certain content, on the Website; or</li>
                                    <li>cause limited portions of content on the Website to be displayed or appear
                                        to be displayed on your own or certain third-party websites.</li>
                                </ul>
                            </li>
                            <li>You may use these features solely as they are provided by Us, solely with respect
                                to the content they are displayed with, and otherwise in accordance with any
                                additional terms and conditions We provide with respect to such features. Subject
                                to the foregoing, You must not:
                                <ul class="sub-sub-heading">
                                    <li>establish a link from any website that is not owned by You,</li>
                                    <li>cause the Website or portions of it to be displayed on, or appear to be
                                        displayed by, any other site, for example, framing, deep linking, or in-line
                                        linking;
                                    </li>
                                    <li>link to any part of the Website other than the homepage; or</li>
                                    <li>otherwise take any action with respect to the materials on this Website that
                                        is inconsistent with any other provision of these Terms of Use.</li>
                                </ul>
                            </li>
                            <li>The website from which You are linking, or on which you make certain content
                                accessible, must comply in all respects with the Content Standards set out in these
                                Terms of Use.</li>
                            <li>You agree to cooperate with Us in causing any unauthorized framing or linking
                                immediately to stop. We reserve the right to withdraw linking permission without
                                notice.</li>
                            <li>We may disable all or any social media features and any links at any time without
                                notice at our discretion.</li>
                        </ul>
                    </li>
                    <li>
                        <span class="text-decorate">Links from the Website</span>
                        <ul class="no-list-type">
                            <li>If the Website contains links to other sites and resources provided by third parties,
                                these
                                links are provided for your convenience only. This includes links contained in
                                advertisements, including banner advertisements and sponsored links. We have no control
                                over the contents of those sites or resources, and accept no responsibility for them or
                                for
                                any loss or damage that may arise from your use of them. If You decide to access any of
                                the third-party websites linked to this Website, You do so entirely at your own risk and
                                subject to the terms and conditions of use for such websites.</li>
                        </ul>
                    </li>
                    <li>
                        <span class="text-decorate">Geographic Restrictions</span>
                        <ul class="no-list-type">
                            <li>The owner of the Website is based in the State of Washington in the United States. We
                                provide this Website for use only by persons located in the United States. We make no
                                claims that the Website or any of its content is accessible or appropriate outside of
                                the
                                United States. Access to the Website may not be legal by certain persons or in certain
                                countries. If You access the Website from outside the United States, You do so on your
                                own initiative and are responsible for compliance with local laws.</li>
                        </ul>
                    </li>
                    <li><span class="text-decorate">Disclaimer of Warranties</span>
                        <ul class="sub-heading">
                            <li>You understand that We cannot and do not guarantee or warrant that files

                                available for downloading from the internet or the Website will be free of viruses
                                or other destructive code. You are responsible for implementing sufficient
                                procedures and checkpoints to satisfy your particular requirements for anti-virus
                                protection and accuracy of data input and output, and for maintaining a means
                                external to our site for any reconstruction of any lost data. TO THE FULLEST
                                EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS
                                OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE
                                ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL
                                MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT,
                                COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL
                                DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS
                                OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF
                                ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.</li>
                            <li>YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR
                                ITEMS OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK.
                                THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS
                                OBTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN "AS IS"
                                AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY
                                KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR
                                ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY
                                WARRANTY OR REPRESENTATION WITH RESPECT TO THE
                                COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR
                                AVAILABILITY OF THE WEBSITE. WITHOUT LIMITING THE
                                FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED
                                WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE
                                WEBSITE, ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED
                                THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE,
                                ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE
                                CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT
                                AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL
                                COMPONENTS, OR THAT THE WEBSITE OR ANY SERVICES OR ITEMS
                                OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR
                                NEEDS OR EXPECTATIONS.</li>
                            <li>TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY
                                HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
                                EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT
                                NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY,
                                NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.</li>
                            <li>
                                THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT
                                CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
                            </li>
                        </ul>
                    </li>
                    <li><span class="text-decorate">Limitation on Liability</span>
                        <ul class="sub-heading">
                            <li>TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL
                                THE COMPANY, ITS AFFILIATES, OR THEIR LICENSORS, SERVICE
                                PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE
                                LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY,
                                ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY
                                TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT
                                ON THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY
                                DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR
                                PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL
                                INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF
                                REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED
                                SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND
                                WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF
                                CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.</li>
                            <li>
                                The limitation of liability set out above does not apply to liability resulting from
                                our gross negligence or willful misconduct or death or bodily injury caused by
                                products You purchase through the site.
                            </li>
                            <li>THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT
                                BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</li>
                        </ul>
                    </li>
                    <li><span class="text-decorate">Indemnification</span>
                        <ul class="sub-heading">
                            <li>You agree to defend, indemnify, and hold harmless the Company, its affiliates,
                                licensors,
                                and service providers, and its and their respective officers, directors, employees,
                                contractors, agents, licensors, suppliers, successors, and assigns from and against any
                                claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees
                                (including
                                reasonable attorneys’ fees) arising out of or relating to your violation of these Terms
                                of
                                Use or your use of the Website, including, but not limited to, your User Contributions,
                                any use of the Website's content, services, and products other than as expressly
                                authorized in these Terms of Use, or your use of any information obtained from the
                                Website.</li>
                        </ul>
                    </li>
                    <li><span class="text-decorate">Governing Law and Jurisdiction</span>
                        <ul class="sub-heading">
                            <li>All matters relating to the Website and these Terms of Use, and any dispute or
                                claim arising therefrom or related thereto (in each case, including non-contractual
                                disputes or claims), shall be governed by and construed in accordance with the
                                internal laws of the State of Washington without giving effect to any choice or
                                conflict of law provision or rule (whether of the State of Washington or any other
                                jurisdiction).</li>
                            <li>
                                Any legal suit, action, or proceeding arising out of, or related to, these Terms of
                                Use or the Website shall be instituted exclusively in the federal courts of the
                                United States or the courts of the State of Washington, although We retain the
                                right to bring any suit, action, or proceeding against You for breach of these
                                Terms of Use in your country of residence or any other relevant country. You
                                waive any and all objections to the exercise of jurisdiction over You by such
                                courts and to venue in such courts.
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span class="text-decorate">Arbitration</span>
                        <ul class="no-list-type">
                            <li>At Company's sole discretion, it may require You to submit any disputes arising from
                                these Terms of Use or use of the Website, including disputes arising from or concerning
                                their interpretation, violation, invalidity, non-performance, or termination, to final
                                and
                                binding arbitration under the Rules of Arbitration of the American Arbitration
                                Association applying Washington law.</li>
                        </ul>
                    </li>
                    <li><span class="text-decorate">Limitation on Time to File Claims</span>
                        <ul class="no-list-type">
                            <li>ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR
                                RELATING TO THESE TERMS OF USE OR THE WEBSITE MUST BE
                                COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION
                                ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS
                                PERMANENTLY BARRED.</li>
                        </ul>
                    </li>
                    <li><span class="text-decorate">Waiver and Severability</span>
                        <ul class="sub-heading">
                            <li>No waiver by the Company of any term or condition set out in these Terms of Use
                                shall be deemed a further or continuing waiver of such term or condition or a
                                waiver of any other term or condition, and any failure of the Company to assert a
                                right or provision under these Terms of Use shall not constitute a waiver of such
                                right or provision.</li>
                            <li>
                                If any provision of these Terms of Use is held by a court or other tribunal of
                                competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such
                                provision shall be eliminated or limited to the minimum extent such that the
                                remaining provisions of the Terms of Use will continue in full force and effect.
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span class="text-decorate">Entire Agreement</span>
                        <ul class="no-list-type">
                            <li>The Terms of Use, our Privacy Policy, and User License Agreemnt constitute the sole and
                                entire agreement between You and Us regarding the Website and supersede all prior and
                                contemporaneous understandings, agreements, representations, and warranties, both
                                written and oral, regarding the Website.</li>
                        </ul>
                    </li>
                    <li>
                        <span class="text-decorate">Your Comments and Concerns</span>
                        <ul class="sub-heading">
                            <li>All feedback, comments, requests for technical support, and other
                                communications relating to the Website should be directed to:
                                <a href="https://support@indietown.org">support@indietown.org.</a>
                            </li>
                        </ul>
                    </li>
                </ul>
        </div>
    </div>
    </div>
  </div>