<div id="auth-width">
    <div class="termscondtionsec">
        <div class="termsconditionblock">
        <div class="term-condition prelative">
            <img
            *ngIf="isMobile" 
            src="../../../assets/Indietown_icons/rightarrowicon.svg"
            routerLink="/settings"
            class="forward-icon settignsnexticn"
            alt=""
          />
            <!-- <a style="cursor:pointer" routerLink="/auth/signup"><img style="float:left;" src="../../../../assets/images/back-arrow.svg" alt="Arrow"></a> -->
            <h1 class="terms-conditions-title" style="font-size: 22px;"><span></span>Privacy Policy</h1>
        </div>

        <div id="content-term">
            <!-- <h1 class="heading-margin">What is Lorem Ipsum?</h1>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
            </p>

            <h1 class="heading-margin">Why do we use it?</h1>
            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).

            </p> -->
            <p style="font-weight: 500;opacity: 1;">Last Modified: August 23, 2023</p>
                <ul class="main-heading" style="font-size: 16px;">
                    <li>
                        <span class="text-decorate">Introduction</span>
                        <ul class="sub-heading">
                            <li>Indie App, Inc. (the <span class="font-weight-bold">“Company”, “We”,</span> or <span class="font-weight-bold">“Us”</span>) respects the privacy of visitors
                                to our website (<span class="font-weight-bold">“You”</span>) and is committed to protecting your privacy through our
                                compliance with this privacy policy (this <span class="font-weight-bold">“Privacy Policy”</span>).
                            </li>
                            <li>
                                This Privacy Policy describes:
                                <ul class="sub-sub-heading">
                                    <li>the types of information We may collect from You or that You may
                                        provide when You visit the Indie App website ([indietown.org], the
                                        <span class="font-weight-bold">“Website”</span>); and</li>
                                    <li>
                                        our practices for collecting, using, maintaining, protecting, and disclosing
                                        that information.
                                    </li>

                                </ul>
                            </li>
                            <li>
                                This Privacy Policy applies to information We collect:
                                <ul class="sub-sub-heading">
                                    <li>onthe Website; and</li>
                                    <li>in email, text, phone calls, and other electronic messages between You and
                                        Us.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                It does not apply to information collected by:
                                <ul class="sub-sub-heading">
                                    <li>Us through any other means, including on any other website operated by
                                        Us or any third party; or</li>
                                    <li>
                                        any third party, including through any application, content or advertising
                                        that may link to or be accessible through the Website.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Please read this Privacy Policy carefully to understand our policies and practices
                                regarding your information and how We will treat it. If You do not agree with our
                                policies and practices, your choice is to not use the Website. By accessing or
                                using the Website, You agree to this Privacy Policy.
                            </li>
                        </ul>
                    </li>
                    <li><span class="text-decorate">Information We Collect About You and How We Collect It</span>
                        <ul class="sub-heading">
                            <li> We may collect several types of information directly from You when You provide
                                it to Us, including information by which You may be personally identified
                                including, but not limited to, your name, postal address, email address, age,
                                gender and telephone number (<span class="font-weight-bold">"Personal Information"</span>).
                            </li>
                            <li>
                                We also may collect information from You automatically. The information We
                                collect automatically may include Personal Information as well as information
                                that is about You but does not individually identify You. We may maintain or
                                associate the information We collect automatically with information We receive in
                                other ways or receive from third parties. Our goal in collecting this information is
                                to help improve the Website and to deliver better and more personalized service,
                                including by enabling Us to:
                                <ul class="sub-sub-heading">
                                    <li>estimate our audience size and usage patterns;</li>
                                    <li>store information about your preferences, allowing Us to customize the
                                        Website according to your individual interests;</li>
                                    <li>
                                        speed up your searches; and
                                    </li>
                                    <li>
                                        recognize You when You return to the Website.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                The technologies We use for automatic data collection may include the use of
                                cookies, also called browser cookies. A cookie is a small file placed on the hard drive
                                of your computer. You may refuse to accept browser cookies by activating
                                the appropriate setting on your browser. However, if You select this setting You
                                may be unable to access certain parts of the Website. Unless You have adjusted
                                your browser setting so that it will refuse cookies, our system may issue cookies
                                when You direct your browser to the Website.
                            </li>

                        </ul>
                    </li>
                    <li><span class="text-decorate">Children Under the Age of 13</span>
                        <ul class="no-list-type">
                            <li>The Website is not intended for children under 13 years of age. We do not knowingly
                                collect Personal Information from children under 13. If We learn We have collected or
                                received Personal Information from a child under 13 We will delete that information.
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span class="text-decorate">Third-Party Use of Cookies and Other Tracking Technologies</span>
                        <ul class="sub-heading">
                            <li>Some content or applications, including advertisements, on the Website are served
                                by third-parties, including Google Analytics, advertisers, ad networks and servers,
                                content providers, and application providers. These third parties may use cookies
                                alone or in conjunction with web beacons or other tracking technologies to collect
                                information about You when You use the Website.
                            </li>
                            <li>
                                ‘We do not control these third parties' tracking technologies or privacy policies. If
                                You have any questions about an advertisement or other targeted content, You
                                should contact the responsible provider directly.
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span class="text-decorate">How We Use Your Information</span>
                        <ul class="sub-heading">
                            <li>We use information that We collect about You or that You provide to Us,
                                including any Personal Information:
                                <ul class="sub-sub-heading">
                                    <li>to present the Website and its contents to You;</li>
                                    <li>to provide You with information, products, or services that You request
                                        from Us;</li>
                                    <li>to fulfill any other purpose for which You provide it;
                                    </li>
                                    <li>
                                        to provide You with notices about your account, including expiration and
                                        renewal notices;
                                    </li>
                                    <li>to carry out our obligations and enforce our rights arising from any
                                        contracts entered into between You and Us, including for billing and
                                        collection;</li>
                                    <li>
                                        to notify You about changes to the Website or any products or services We
                                        offer or provide through it;
                                    </li>
                                    <li>
                                        in any other way We may describe when You provide the information; and
                                    </li>
                                    <li>
                                        for any other purpose with your consent.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span class="text-decorate">Disclosure of Your Information</span>
                        <ul class="sub-heading">
                            <li>We may disclose aggregated information about our users, and information that
                                does not identify any individual, without restriction.</li>
                            <li>
                                We may disclose Personal Information:
                                <ul class="sub-sub-heading">
                                    <li>to contractors, service providers, and other third parties We use to support
                                        our business and who are bound by contractual obligations to keep
                                        Personal Information confidential and use it only for the purposes for
                                        which We disclose it to them;</li>
                                    <li>
                                        to fulfill the purpose for which You provide it;
                                    </li>
                                    <li>
                                        for any other purpose disclosed by Us when You provide the information;
                                        or
                                    </li>
                                    <li>
                                        with your consent.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                We may also disclose your Personal Information:
                                <ul class="sub-sub-heading">
                                    <li>to comply with any court order, law, or legal process, including to respond
                                        to any government or regulatory request;</li>
                                    <li>
                                        to enforce our Terms of Use and other agreements, including for billing
                                        and collection purposes; or
                                    </li>
                                    <li>
                                        if We believe disclosure is necessary to protect the rights, property, or
                                        safety of the Company, our customers, or others. This includes exchanging
                                        information with other companies and organizations for the purposes of
                                        fraud protection and credit risk reduction.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li><span class="text-decorate">Deletion of Personal Information</span>
                        <ul class="sub-heading">
                            <li>To delete your Personal Information, You must delete your account in settings.
                                Your activity on Indie Town may still be visible on other users’ pages.
                            </li>
                        </ul>
                    </li>
                    <li><span class="text-decorate">Data Security</span>
                        <ul class="sub-heading">
                            <li>We have implemented measures designed to secure your Personal Information
                                from accidental loss and from unauthorized access, use, alteration, and disclosure.</li>
                            <li>
                                The safety and security of your information also depends on You. Where We have
                                given You (or where You have chosen) a password for access to certain parts of
                                the Website, You are responsible for keeping this password confidential. We ask
                                You not to share your password with anyone.
                            </li>
                            <li>
                                Unfortunately, the transmission of information via the internet is not completely
                                secure. Although We do our best to protect your Personal Information, We cannot
                                guarantee the security of your Personal Information transmitted to the Website.
                                Any transmission of Personal Information is at your own risk. We are not
                                responsible for circumvention of any privacy settings or security measures
                                contained on the Website.
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span class="text-decorate">Your State Privacy Rights</span>
                        <ul class="sub-heading">
                            <li>State consumer privacy laws may provide their residents with additional rights
                                regarding our use of their Personal Information, including, but not limited to, the
                                following.
                            </li>
                            <ul class="sub-sub-heading">
                                <li>1. California provides its residents with additional privacy protections. To
                                    learn more about California residents’ privacy rights, visit
                                    <a href="https://oag.ca.gov/privacy/ccpa">https://oag.ca.gov/privacy/ccpa</a></li>
                                <li>
                                    Colorado, Connecticut, Virginia, and Utah each provide their state
                                    residents with rights to:
                                    <ul class="sub-sub-sub-heading">
                                        <li>Confirm whether We process their Personal Information,.</li>
                                        <li>Access and delete certain Personal Information,.</li>
                                        <li>Data portability, and.</li>
                                        <li>Opt-out of personal data processing for targeted advertising and
                                            sales.</li>
                                    </ul>
                                </li>
                                <li>
                                    Colorado, Connecticut, and Virginia also provide their state residents with
                                    rights to:
                                    <ul class="sub-sub-sub-heading">
                                        <li>Correct inaccuracies in their Personal Information, taking into
                                            account the information's nature processing purpose, and.
                                        </li>
                                        <li>
                                            Opt-out of profiling in furtherance of decisions that produce legal
                                            or similarly significant effects.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Nevada provides its residents with a limited right to opt-out of certain
                                    Personal Information sales. However, please know We do not currently
                                    sell data triggering that statute's opt-out requirements.
                                </li>
                            </ul>
                        </ul>
                    </li>
                    <li>
                        <span class="text-decorate">Changes to Our Privacy Policy</span>
                        <ul class="no-list-type">
                            <li>It is our policy to post any changes We make to our Privacy Policy on this page with a
                                notice that the Privacy Policy has been updated on the Website home page. If We make
                                material changes to how We treat our users’ Personal Information, We will notify You by
                                email to the email address specified in your account, or through a notice on the Website
                                home page. The date the Privacy Policy was last revised is identified at the top of the
                                page. You are responsible for ensuring We have an up-to-date active and deliverable
                                email address for You, and for periodically visiting the Website and this Privacy Policy to
                                check for any changes.
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span class="text-decorate">Contact Information</span>
                        <ul class="no-list-type">
                            <li>To ask questions or comment about this Privacy Policy and our privacy practices, contact
                                Us at: support@indietown.org.</li>
                        </ul>
                    </li>
                </ul>
        </div>
    </div>
    </div>
  </div>