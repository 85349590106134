import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { HeaderService } from "../services/header.service";
import { AuthService } from "src/app/auth/services/auth.service";
import { ToastrService } from "ngx-toastr";
// import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SharedService } from "../services/shared.service";
import * as $ from 'jquery';
import { HttpClient } from "@angular/common/http";
import { SpinnerVisibilityService } from "ng-http-loader";
import { RegistrationPartService } from "src/app/views/registration-part/services/registration.service";

declare var Pushwoosh:any;

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {

  manageCaregiversHeading: boolean = false;
  manageClientHeading:boolean = false;
  manageServicesHeading: boolean = false;
  manageTutorialHeading:boolean = false;
  manageConvenienceHeading:boolean = false;
  manageDiseasesHeading:boolean = false;



  cancelPostId:any;
  cancelRequestIdText:any;
  welcomeUserMsg: boolean = false;
  hospitalHeading: boolean = false;
  loginHeading: boolean = false;
  usageHeading: boolean = false;
  supportHeading: boolean = false;
  feedbackHeading: boolean = false;
  manageHeading: boolean = false;
  aboutHeading:boolean = false;
  dashboardHeading:boolean = false;
  changePasswordHeading:boolean = false;
  pushNotificationHeading: boolean = false;
  addUserHeading: boolean = false;
  privacyPolicyHeading: boolean = false;
  surveyAnswersHeading: boolean = false;





  importedEventsHeading: boolean = false;
  subEventsHeading: boolean = false;
  zoneHeading: boolean = false;
  subZoneHeading: boolean = false;
  venueHeading: boolean = false;
  interestHeading: boolean = false;
  pointHeading: boolean = false;
  placeHeading: boolean = false;
  merchandiseHeading: boolean = false;
  sectionHeading: boolean = false;
  categoryHeading: boolean = false;
  pointcategoryHeading: boolean = false;
  homeScreenPartnersHeading: boolean = false;
  eventsponsorsHeading: boolean = false;
  notificationHeading: boolean = false;
  protocolHeading: boolean = false;
  seasonsHeading: boolean = false;
  chatListHeading: boolean = false;
  chatDetailsHeading: boolean = false;
  requestHeading: boolean = false;
  settingHeading: boolean = false;
  restaurantsHeading: boolean = false;
  requestsHeading: boolean = false;
  customerHeading: boolean = false;
  zoneRequestHeading: boolean = false;
  zoneChatHeading: boolean = false;
  zoneCallHeading: boolean = false;
  zoneReportingHeading: boolean = false;
  EventRequestHeading: boolean = false;
  BannerHeading: boolean = false;
  CuisineHeading: boolean = false;
  PopupHeading: boolean = false;
  PdfHeading: boolean = false;
  TreasureHeading: boolean = false;
  CouponHeading: boolean = false;
  ZoneCoordinatesHeading: boolean = false;
  SubZoneCoordinatesHeading: boolean = false;
  emailSupportHeading:boolean = false;

  default: boolean;
  lang: string;

  private returnUrl: any;

  profilePic: String = "";
  profileDetails:any;
  modalRef: any;
  bandSuggestionList:any=[];
  searchList:any=[];
  latitube:boolean = false;

  constructor(
    private _header: HeaderService,
    public router: Router,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private sharedService: SharedService,
    private http: HttpClient,
    private spinner: SpinnerVisibilityService,
    private services: RegistrationPartService,
    private cdr:ChangeDetectorRef
  ) { 
    this._header.clearLocalStorageOnLogout.subscribe(message =>{
      console.log("from clear logout.");
    console.log(message);
    if(message == 'logout'){
      try{
        Pushwoosh.push( (api) => {
          console.log('Pushwoosh ready!',api);
          Pushwoosh.unsubscribe();
          Pushwoosh.push(['onUnsubscribe', (api) => {
          console.log('User successfully unsubscribed',api);       
        }]); 
      });
      this.sharedService.updateProfile({deviceToken:""}).subscribe((res:any)=>{
        console.log(res);
        localStorage.removeItem("currentUser");
        localStorage.removeItem("selectedRole");
        localStorage.removeItem("email");
        localStorage.removeItem("phone");
        localStorage.removeItem("seeAll");
        localStorage.removeItem("setSearchResult");
        localStorage.removeItem("screensCompleted");
        localStorage.removeItem("address");
        localStorage.removeItem("countryCode");
        this._header.logout("");
        this.router.navigateByUrl("/auth/login");
      })
      Pushwoosh.push(['onLoad', (api) => {
        console.log("loaded");
      }]);
    }
    catch(err){
        localStorage.removeItem("currentUser");
        localStorage.removeItem("selectedRole");
        localStorage.removeItem("email");
        localStorage.removeItem("phone");
        localStorage.removeItem("seeAll");
        localStorage.removeItem("setSearchResult");
        localStorage.removeItem("screensCompleted");
        localStorage.removeItem("address");
        localStorage.removeItem("countryCode");
        this._header.logout("");
        this.router.navigateByUrl("/auth/login");
      } 
    }
    });
  }

  userName: String;
  firstName:String;
  lastName: String;
  allDate:any;
  search:boolean;
  disabledSlider:boolean = true;

  @ViewChild('searchDataModal') searchDataModal: TemplateRef<any>;
  @ViewChild("cancelRequest") cancelRequest: TemplateRef<any>;

  currentUser:any;
  ngOnInit(): void {
    // let user = JSON.parse(localStorage.getItem("currentUser"));
    this.currentUser = this.authService.currentUserValue;
    // this.initMapHeader();
    this.default = true;
    this.currentUser = this.authService.currentUserValue;
    this.profilePic = "../../../assets/icons/user-img.svg";
    this.allDate = this.authService.currentUserValue;
    this.userName = this.allDate?.firstName?.toUpperCase();
    let seeAll = localStorage.getItem("seeAll");
    // this.getDefault();
    this.getProfile();
    document.getElementById('searchLocation').style.display = 'none';

    // alert(seeAll);
    // if(!seeAll){
    //   document.getElementById('openModalField').style.display = 'block';
    //   document.getElementById('searchLocation').style.display = 'none';
    // }else{
    //   (<HTMLInputElement>document.getElementById('pac-input-header')).value = JSON.parse(seeAll);
    //   document.getElementById('openModalField').style.display = 'none';
    //   document.getElementById('text').style.overflow="";
    //   document.getElementById('searchLocation').style.display = 'flex';
    // }
  }

  searchForm = this.fb.group({
    slider: new FormControl('200',),
    gener: new FormControl('',),
    name: new FormControl('',),
    lat: new FormControl(''),
    lng :new FormControl(''),
  })


  searchData(){
    this.search = true;
    this.getBandSuggestions();
  }

  getDefault(){
    this.searchClick=false;
    this.search=false;
    let data = {
      "radius": this.searchForm.value.slider,
      "genre": '',
      "phoneBook":[],
      "pageNumber":1,
      "limit":20,
      "bandName":'',
      "location":{
        "lat": '',
        "long":'',    
      }
    }
    console.log(data);
    this.sharedService.getBandSuggestions(data).subscribe((res:any) => {
      // this.initMapHeader();
      // debugger;
      if(res.type == 'success'){
        this.moreData = res.data;
        data.limit = 10
        localStorage.setItem('payload',JSON.stringify(data));
        if(res.data.length>10){
          this.searchList = res.data.slice(0,10);
        }else{
          this.searchList = res.data;
        }
        this.cdr.detectChanges();
      }
    })
  }


  moreData:any=[];
  searchClick:any;
  getBandSuggestions(){
    let data = {
      "radius": this.searchForm.value.slider,
      "genre": this.searchForm.value.gener,
      "phoneBook":[],
      "pageNumber":1,
      "bandName":this.searchForm.value.name,
      "location":{
        "lat": this.searchForm.value.lat,
        "long":this.searchForm.value.lng,    
      },
      "limit":20
    }

    console.log(data);
    this.searchClick = true;
    this.sharedService.getBandSuggestions(data).subscribe((res:any) => {
      if(res.type == 'success'){
        data.limit = 10;
        localStorage.setItem('payload',JSON.stringify(data));
        this.moreData = res.data;
        if(res.data.length>10){
          this.searchList = res.data.slice(0,10);
        }else{
          this.searchList = res.data;
        }
        this.cdr.detectChanges();
      }
    })
  }

  clickCancelPostYes() {
    this.spinner.show();
    console.log(this.cancelPostId, "this.cancelPostId")
    document.getElementById(  this.cancelRequestIdText + this.cancelPostId).innerText = 'Add Friend'
    document.getElementById(  this.cancelRequestIdText+ this.cancelPostId).classList.remove("requestSent");
    document.getElementById(  this.cancelRequestIdText + this.cancelPostId).classList.add("requestNotSent")
    this.sharedService.cancelRequest(this.cancelPostId,{"action":"CANCEL"}).subscribe((res:any)=>{
        if(res.type == 'success'){
          this.modalRef.hide()
          this.toastr.success("Cancelled friend request");
          this.spinner.hide();
          let index = this.moreData.findIndex((obj)=>{
            return obj._id == this.cancelPostId;
          });
          if(index != -1){
            this.moreData[index].friendStatus = "NOT_FRIEND";
          }
        }
      })
  }
   

  addFriend(id:any){

    let data = document.getElementById('bandSuggestionList'+id).innerText;
    if(data =='Pending'){ 
      // document.getElementById('bandSuggestionList'+id).innerText = 'Add Friend'; 
      // document.getElementById('bandSuggestionList'+id).classList.remove("requestSent");
      // document.getElementById('bandSuggestionList'+id).classList.add("requestNotSent");
      // document.getElementById('bandSuggestionList'+id).classList.remove('btn:enabled');

      this.cancelPostId = id;
      this.cancelRequestIdText = 'bandSuggestionList';
       this.modalRef = this.modalService.show(this.cancelRequest, { class: "deletepopuphomee" });
 
      // this.spinner.show();
      // this.sharedService.cancelRequest(id,{"action":"CANCEL"}).subscribe((res:any)=>{
      //   if(res.type == 'success'){
      //     this.toastr.success("Cancelled friend request");
      //   }
      // })
      // this.spinner.hide();
    }else if(data=='Add Friend'){ 
      this.spinner.show();
      document.getElementById('bandSuggestionList'+id).innerText = 'Pending'; 
      document.getElementById('bandSuggestionList'+id).classList.add("requestSent");
      document.getElementById('bandSuggestionList'+id).classList.remove("requestNotSent");

      // document.getElementById('bandSuggestionList'+id).classList.add('myclass');
      // document.getElementById('bandSuggestionList'+id).classList.remove('myclass2');
      document.getElementById('bandSuggestionList'+id).classList.remove('btn:enabled');
      // document.getElementById('bandSuggestionList'+id).style.color='black';
      this.sharedService.AddFriend({"recipientId":id}).subscribe((res:any) => {
        if(res.type == 'success'){
          // this.toastr.success(res.message);   
          this.toastr.success("Friend request sent");
          // debugger;
          let index = this.moreData.findIndex((obj)=>{
            return obj._id == id
          });
          if(index != -1){
            this.moreData[index].friendStatus = "REQUEST_SENT";
          }
        }else{
          this.toastr.error(res.message);   
        }
      },(err) => {
        this.toastr.error(err.error.message);
      })
      this.spinner.hide();
    }else if(data=='Accept'){ 
      this.spinner.show();
      document.getElementById('bandSuggestionList'+id).innerText = 'Friend'; 
      document.getElementById('bandSuggestionList'+id).classList.add("friend");
      document.getElementById('bandSuggestionList'+id).classList.remove("requestSent");

      document.getElementById('bandSuggestionList'+id).classList.remove('btn:enabled');
      // document.getElementById('bandSuggestionList'+id).style.color='black';
      this.sharedService.AcceptFriendRequest({"recipientId": id,"action": "ACCEPT"}).subscribe((res:any) => {
        if(res.type == 'success'){
          // this.toastr.success(res.message);   
          this.toastr.success("Friend request accepted");
          // debugger;
          let index = this.moreData.findIndex((obj)=>{
            return obj._id == id
          });
          if(index != -1){
            this.moreData[index].friendStatus = "FRIEND";
          }
        }else{
          this.toastr.error(res.message);   
        }
      },(err) => {
        this.toastr.error(err.error.message);
      })
      this.spinner.hide();
    }
  }
  notFount: boolean;
  onChangeGenerSearch(e) {
    this.searchData();
    // if(e.target.value?.trim() != e.target?.previousValue?.trim()){
    // let data = {
    //   // "radius": this.updateProfile.value.slider,
    //   "genre": this.searchForm.value.gener.trim(),
    //   // "phoneBook":["7780984169"],
    //   "pageNumber": 1,
    //   "bandName": this.searchForm.value.name,
    //   "location": {
    //     "lat": this.searchForm.value.locationLat,
    //     "long": this.searchForm.value.locationLong,
    //   },
    //   "limit":10000
    // }
    // if (this.searchForm.value.locationLat) {
    //   data["radius"] = this.searchForm.value.slider;
    // }
    // // e.target.previousValue = e.target.value;
    // this.services.getBandSuggestions(data).subscribe((res: any) => {
    //   if (res.type == 'success') {
    //     console.log(res,"lll")
    //     this.bandSuggestionList = res.data;

    //     if (res.data?.length == 0) {
    //       this.notFount = true;
    //     } else {
    //       this.notFount = false;
    //     }
    //   }
    // })
    // }
    // else if(e.target.value == ''){
    //   this.getBandSuggestions();
    // }
  }

  // logout() {
  //   this.modalRef.hide();
    
  //   this.authService.logout({email:this.currentUser.email, password: this.currentUser.password}).subscribe((res:any) =>{
  //     console.log(res.status);
  //     if(res.status == 200){
  //       localStorage.removeItem("currentUser");
  //     }   
  //   });
  //   this.router.navigateByUrl("/auth/login");
  // }

  // modal lougout confirmation
  confirm() {
    // this.logout();
    this.modalRef.hide();
    try{
      Pushwoosh.push( (api) => {
        console.log('Pushwoosh ready!',api);
        Pushwoosh.unsubscribe();
        Pushwoosh.push(['onUnsubscribe', (api) => {
          console.log('User successfully unsubscribed',api);
          this.sharedService.updateProfile({deviceToken:""}).subscribe((res:any)=>{
            console.log(res);
            localStorage.removeItem("currentUser");
            localStorage.removeItem("selectedRole");
            localStorage.removeItem("email");
            localStorage.removeItem("phone");
            localStorage.removeItem("seeAll");
            localStorage.removeItem("setSearchResult");
            localStorage.removeItem("screensCompleted");
            localStorage.removeItem("address");
            localStorage.removeItem("countryCode");
            this.router.navigateByUrl("/auth/login");
          })
        }]);
      });
      Pushwoosh.push(['onLoad', (api) => {
        console.log("loaded");
      }]);
    }
     catch(err){
      localStorage.removeItem("currentUser");
      localStorage.removeItem("selectedRole");
      localStorage.removeItem("email");
      localStorage.removeItem("phone");
      localStorage.removeItem("seeAll");
      localStorage.removeItem("setSearchResult");
      localStorage.removeItem("screensCompleted");
      localStorage.removeItem("address");
      localStorage.removeItem("countryCode");
      this.router.navigateByUrl("/auth/login");
     } 
     
      
      
      
    // document.getElementById("text").classList.remove("screenzooom");

  }

  clearLogout(message){

    console.log("from clear logout");
    console.log(message);
    if(message == 'logout'){
      try{
        Pushwoosh.push( (api) => {
          console.log('Pushwoosh ready!',api);
          Pushwoosh.unsubscribe();
          Pushwoosh.push(['onUnsubscribe', (api) => {
          console.log('User successfully unsubscribed',api);
          this.sharedService.updateProfile({deviceToken:""}).subscribe((res:any)=>{
            console.log(res);
            localStorage.removeItem("currentUser");
            localStorage.removeItem("selectedRole");
            localStorage.removeItem("email");
            localStorage.removeItem("phone");
            localStorage.removeItem("seeAll");
            localStorage.removeItem("setSearchResult");
            localStorage.removeItem("screensCompleted");
            localStorage.removeItem("address");
            localStorage.removeItem("countryCode");
            this.router.navigateByUrl("/auth/login");
          })
        }]);
      });
      Pushwoosh.push(['onLoad', (api) => {
        console.log("loaded");
      }]);
    }
    catch(err){
        localStorage.removeItem("currentUser");
        localStorage.removeItem("selectedRole");
        localStorage.removeItem("email");
        localStorage.removeItem("phone");
        localStorage.removeItem("seeAll");
        localStorage.removeItem("setSearchResult");
        localStorage.removeItem("screensCompleted");
        localStorage.removeItem("address");
        localStorage.removeItem("countryCode");
        this.router.navigateByUrl("/auth/login");
      } 
    }
  }
  cancel() {
    this.modalRef.hide();
  }

  openModal(template: TemplateRef<any>){
    this.modalRef = this.modalService.show(template, {class:'logoutpopupmodal'});
  }

  CheckEnterPress(e:any){

  }

  goToProfile(){

  }

  formatLabel(value: number) {
    if (value >= 1000) {
      return Math.round(value / 1000) + ' Miles';
    }
    else{
      return `${value}`+' Miles';
    }
  }

  opened:any;
  openSearchModal(){
    document.getElementById('openModalField').style.display = 'none';
    this.getDefault();
    this.opened = true;
    document.getElementById('headertopbar').style.width='100%';
    document.getElementById('headertopbar').style.marginLeft='0';
    document.getElementById('text').style.overflow="hidden";
    document.getElementById('searchLocation').style.display = 'flex';
    document.getElementById('openModalField').style.display = 'none';
    // this.openModal(this.searchDataModal);
    $("#searchModalDialog").show();
  }

  latitude:any;
  longitude:any;

  initMapHeader() {
    const map = new google.maps.Map(
      document.getElementById("map-header") as HTMLElement,
      {
        center: { lat: this.latitude ? parseFloat(this.latitude): 28.7041, lng: this.longitude ? parseFloat(this.longitude): 77.1025 },
        zoom: 13,
        mapTypeId: "roadmap",
      }
    );
  
  
    // Create the search box and link it to the UI element.
    const input = document.getElementById("pac-input-header") as HTMLInputElement;
    // const searchBox = new google.maps.places.SearchBox(input);

    const searchBox = new google.maps.places.Autocomplete(input,{
      types:['(cities)']
    });
  
    map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
  
    // Bias the SearchBox results towards current map's viewport.
    map.addListener("bounds_changed", () => {
      searchBox.setBounds(map.getBounds() as google.maps.LatLngBounds);
    });
  
    
    // Listen for the event fired when the user selects a prediction and retrieve
    // more details for that place.
    searchBox.addListener("places_changed", () => {
      // const places = searchBox.getPlaces();
      const place = searchBox.getPlace();
  
      // if (places.length == 0) {
      //   return;
      // }

      if(!Object.keys(place)?.length){
        return;
      }

      // For each place, get the icon, name and location.
      const bounds = new google.maps.LatLngBounds();
      // places.forEach((place) => {
        this.latitube = true;
        console.log(place.geometry.location.lat())
        if(place.geometry.location.lat()){
          this.searchForm.get('lat').patchValue(1);
          this.searchForm.get('lat').patchValue(place.geometry.location.lat());
          this.disabledSlider = false;
        }
        if (!place.geometry || !place.geometry.location) {
          console.log("Returned place contains no geometry");
          return;
        }
  
        const icon = {
          url: place.icon as string,
          size: new google.maps.Size(71, 71),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(17, 34),
          scaledSize: new google.maps.Size(25, 25)
        };

        // this.codeLatLng(place.geometry.location.lat(),place.geometry.location.lng());
        this.searchForm.get('lat').patchValue(place.geometry.location.lat());
        this.searchForm.get('lng').patchValue(place.geometry.location.lng());
        this.searchData();
        // this.disabledSlider = false;
        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      // });
      map.fitBounds(bounds);      
    });
  
    let infoWindow = new google.maps.InfoWindow({
      content: "Click the map to get Lat/Lng!",
      // position: myLatlng,
    });
  
    infoWindow.open(map);
  }

  seeAll(){
    // this.initMapHeader();
    let loc = (<HTMLInputElement>document.getElementById('pac-input-header')).value;
    localStorage.setItem('seeAll',JSON.stringify(loc));
    // this.modalRef.hide();
    // debugger;
    this.moreData = this.moreData.slice(0,10)
    localStorage.setItem('setSearchResult',JSON.stringify(this.moreData));
    // for(var i of this.searchList){
    //   console.log(i.bandName);
    // }
    this.sharedService.sendHeaderData(this.searchList);
    this.router.navigateByUrl('/search-result');
  }

  closeSearchModal(){
    this.hideLocSuggestions();
    this.disabledSlider = true;
    this.latitube = false;
    this.searchForm.get('lat').patchValue("");
    this.searchForm.get('gener').patchValue("");
    this.searchForm.get('name').patchValue("");
    this.searchForm.get('slider').patchValue("200");
    (<HTMLInputElement>document.getElementById('pac-input-header')).value = '';
    document.getElementById('searchLocation').style.display = 'none';
    document.getElementById('headertopbar').style.width='';
    document.getElementById('headertopbar').style.marginLeft='';
    document.getElementById('openModalField').style.display = 'block';
    document.getElementById('text').style.overflow="";
    $("#searchModalDialog").hide();
  }

  checkEmpty(e:any){
    if(e.target.value==''){
      this.hideLocSuggestions();
      this.disabledSlider = true;
      this.searchForm.get('lat').patchValue('');
      this.searchForm.get('lng').patchValue('');
      this.getDefault();
      if((this.searchForm.value.gener=='' || this.searchForm.value.gener==undefined) && (this.searchForm.value.name=='' || this.searchForm.value.name==undefined)){
        this.getDefault();
      }
      this.latitube=false;
    }
    else{
      console.log(e?.target?.value);
      this.hideLocSuggestions();
      this.getLocationSuggestions(e?.target?.value);
    }
  }

  getLocationSuggestions(key){
    // delete (document.getElementById("programmingLanguages") as HTMLUListElement).children
    (document.getElementById("programmingLanguages") as HTMLUListElement).innerHTML = '';
    this.cdr.detectChanges();
    const service = new google.maps.places.AutocompleteService();
    // getQueryPredictions
    // '(regions)','(cities)'
    service.getPlacePredictions({ input: key, types: ['(cities)'] }, (predictions)=>{
      console.log(predictions);
      // (document.getElementById("programmingLanguages") as HTMLUListElement).innerHTML = '';
      this.hideLocSuggestions();
      predictions.forEach((prediction) => {
        if(prediction?.description?.split(',')?.length >= 2){
          const option = document.createElement("li");
          option.appendChild(document.createTextNode(prediction.description));
          option.setAttribute("class","suggestionItemClass");
          option.setAttribute("description",prediction.description);
          option.setAttribute("style","padding:10px;");
          option.setAttribute("placeId",prediction.place_id);
          // option.setAttribute("country",prediction.description.split(",")[2]);
          (document.getElementById("programmingLanguages") as HTMLUListElement).appendChild(option);
        }
      });
      this.setHandler();
    });
  }

  setHandler(){
    (document.getElementById("programmingLanguages") as HTMLUListElement).childNodes.forEach((node)=>{
      node.addEventListener('click', (e:any) => {  
        console.log("yeas i am here");
        console.log(e.target.getAttribute('description'));
        let desc = e.target.getAttribute('description');
        let placeId = e.target.getAttribute('placeId');
        (<HTMLInputElement>document.getElementById('pac-input-header')).value = desc;
        this.hideLocSuggestions();
        if(desc.split(",")?.length == 2){
          this.getLatLngFromAddress(desc.split(",")[0],desc.split(",")[1]?.trim(),placeId)
        }
        else if(desc.split(",")?.length > 2){
          this.getLatLngFromAddress(desc.split(",")[0],desc.split(",")[2]?.trim(),placeId)
        }
        // this.getLatLngFromAddress(desc.split(",")[0],desc.split(",")[2]?.trim())
        // debugger;
      });
    })
  }

  hideLocSuggestions(){
    (document.getElementById("programmingLanguages") as HTMLUListElement).innerHTML = '';
    // (<HTMLElement>document.getElementById('programmingLanguages')).click();
    this.cdr.detectChanges();
  }
  
  getLatLngFromAddress(city, country,placeId){

    var address = city +", "+ country;
    var geocoder = new google.maps.Geocoder();

    let obj;

    if(placeId)
      obj = {placeId};
    else
      obj = {address};
  
    geocoder.geocode( obj, (results:any, status) => {
  
      if (status == google.maps.GeocoderStatus.OK) {
        // $('#latitude').val(results[0].geometry.location.Pa);
        // $('#longitude').val(results[0].geometry.location.Qa);
        console.log(results[0].geometry.location?.lat());
        console.log(results[0].geometry.location?.lng());
        this.searchForm.get('lat').patchValue(results[0].geometry.location?.lat());
        this.searchForm.get('lng').patchValue(results[0].geometry.location?.lng());
        this.disabledSlider = false;
        this.searchData();
  
      } else {
        console.log("Geocode was not successful for the following reason: " + status);
      }
    });
  }

  checkEmptyForName(e:any){
    if(e.target?.value?.length>=2){
      this.searchData();
    }
    else if(!e.target?.value.length && !this.searchForm.value.gener && !this.latitube){
      this.getDefault();
    }
    else if(!e.target?.value.length){
      this.searchData();
    }
  }

  getSuggestionsForRadiusChange(){
    this.searchData();
  }

  generSuggestions;
  getGenerSuggestions(e) {
   console.log(e?.target?.value, e?.target?.value.length, "ppp")
   if(e.target?.value?.length >1){
      this.services.getGenerSuggestions(e?.target?.value).subscribe((res: any) => {
        if (res.type == 'success') {
          console.log(res)
          this.generSuggestions = res.data;
        }
      })

      if (!e.target.value) {
        this.getBandSuggestions();
      }
    }
    else if(!e.target?.value && !this.latitube && !this.searchForm.value.name.length){
      this.generSuggestions = [];
      this.getDefault();
    }
    else if(!e.target?.value){
      this.generSuggestions = [];
      this.searchData();
    }

  }
  getProfile(){
    this.sharedService.getProfile().subscribe((res:any) => {
      if(res.type == 'success'){
        this.profileDetails = res.data;
        // localStorage.removeItem('seeAll');

      }
    })
  }
  navigateToUserProfile(id,bandName){
    this.router.navigateByUrl(`user/profile?id=${id}&user=${bandName}`).then((res)=>{
      window.document?.location?.reload();
    })
  }
  // ngOnDestroy(){
  //   console.log("on destroy");
  //   this._header.clearLocalStorageOnLogout.unsubscribe();
  // }
}
