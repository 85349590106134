<div class="sidebar" (keydown)="disabledTab($event)">
  <!-- <div class="logo">
    <img src="../../../assets/Indietown_icons/login-logo-white.svg" alt="logo">
  </div> -->

    <div  class="sidebar1">
  <!-- <div class="logo"><img routerLink= "/analytics-dashboard" src="../../../assets/mf_homecare/left_mf_logo.svg" alt="logo"></div> -->

        <div class="header-links">

          <div class="text-center" style="margin-bottom: 137px;">
              <img src="../../../assets/Indietown_icons/login-logo-white.svg" routerLink="/home" alt="" style="cursor: pointer;">
          </div>

              <a routerLink="/home" routerLinkActive="active" (click)="activateSearchModal('home')">
                <span class="sidebar-icon">
                  <img class="black-icon" src="../../../assets/Indietown_icons/Home-grey.svg" alt="" height="24" width="24">
                  <img class="white-icon" src="../../../assets/Indietown_icons/Home.svg" alt="">
              </span>Home</a>

              <a routerLink="/messages" routerLinkActive="active"  (click)="activateSearchModal('messages')">
                <span class="sidebar-icon">
                  <div style="display: inline-block;
                  /* color: red; */
                  width: 6px;
                  height: 6px;
                  border-radius: 50%;
                  background-color: #FF4545;
                  position: absolute;
                  left: 45px;" *ngIf="showNewMesageRedDot"></div>
                  <img class="black-icon" src="../../../assets/Indietown_icons/Chat-grey.svg" alt="" height="24" width="24">
                  <img class="white-icon" src="../../../assets/Indietown_icons/Chat.svg" alt="">
              </span>Messages</a>

              <a routerLink="/notifications" routerLinkActive="active"  (click)="activateSearchModal('notifications')">
                <span class="sidebar-icon">
                  <div style="display: inline-block;
                  /* color: red; */
                  width: 6px;
                  height: 6px;
                  border-radius: 50%;
                  background-color: #FF4545;
                  position: absolute;
                  left: 46px;" *ngIf="showNotificationRedDot"></div>
                  <img class="black-icon" src="../../../assets/Indietown_icons/Notification-grey.svg" alt="" height="24" width="24">
                  <img class="white-icon" src="../../../assets/Indietown_icons/Notification.svg" alt="">
              </span>Notifications</a>

              <a routerLink="/profile" routerLinkActive="active"  (click)="activateSearchModal('profile')">
                <span class="sidebar-icon">
                  <!-- <img class="black-icon profile-icon" src="../../../assets/Indietown_icons/profile.png" alt="">
                  <img class="white-icon profile-icon" src="../../../assets/Indietown_icons/profile.png" alt=""> -->
                  <img 
                  style="width: 27px;
                  height: 27px;
                  border-radius: 100%;
                  border: 1px solid #fff;"
                  [src]="profileDetails?.profileImage?.fileUrl?profileDetails?.profileImage?.fileUrl:'../../../../assets/Indietown_icons/upload-profile-pic.png'"
                  alt="">
              </span>Profile</a>
              

        </div>
    </div>
</div>

<!-- Logout fonfirm -->
<ng-template #template>
  <div class="modal-header">
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body text-center delete-modal align-items-center">
      <img src="../../../../../assets/images/create-successfull-img.svg" alt="">
      <p>Are you sure you <br>want to logout</p>
      <div class="modal-btn d-flex justify-content-around">
          <button class="btn btn-red" (click)="cancel()" style="color: #000;">No</button>
          <button class="btn btn-blue" (click)="confirm()">Yes</button>
      </div>
  </div>
</ng-template>