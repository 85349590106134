import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SpinnerVisibilityService } from "ng-http-loader";
import { ToastrService } from "ngx-toastr";
import { SharedService } from "../services/shared.service";

@Component({
  selector: "app-spotify-card",
  templateUrl: "./spotify-cards.component.html",
  styleUrls: ["./spotify-cards.component.scss"],
})
export class SpotifyCardsComponent implements OnInit {
  youtubePlayData:any;
  selectedIndex:any;
  // @Input() playList: any=[];

  @Output() gotoSpotify = new EventEmitter();

  constructor(
    public router: Router,
    private service: SharedService,
    private toastrService: ToastrService,
    private spinner: SpinnerVisibilityService
  ) {}

  // @Input() goYoutube: any;
  // @Input() playlistModelOpen: any;

  @Input() userId: any;
  spotifyList = [];

  ngOnInit(): void {
    this.getSpotifyList();
  }

  getSpotifyList(){
    this.service.getSpotifyList(this.userId,1,20).subscribe((res:any)=>{
      console.log(res);
      this.spinner.hide();
      if(res.data.length){
        this.spotifyList = res.data;
      }
    },(err) => {
      // this.spinner.hide();
      console.log("some error occured");
      // this.toastrService.info("some error occured, please try again");
    }
    )
  }

  navigateToSpotify(){
    console.log("navigate to spotify");
    this.gotoSpotify.emit();
  }


  openSpotifyModal(data:any){
    // this.service.playSpotifySong(data);
  }

  // gotoSpotify(){
  //   // this.service.selectYoutube(3);
  // }


  getTimeRemaining(miliseconds:any){
      miliseconds = miliseconds - 1000;
      const days    = Math.floor(miliseconds / (1000 * 60 * 60 * 24));
      const hours   = Math.floor((miliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((miliseconds % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((miliseconds % (1000 * 60)) / 1000);
      let data = minutes + ":" + seconds;
      return data;
  }

  
}
