import { Component, OnInit } from '@angular/core';
import { SharedService } from '../services/shared.service';

@Component({
  selector: 'app-spotify-view',
  templateUrl: './spotify-view.component.html',
  styleUrls: ['./spotify-view.component.scss']
})
export class SpotifyViewComponent implements OnInit {

  constructor(
    private service: SharedService,
  ) { }

  ngOnInit(): void {
    this.getSpotifyList();
  }

  spotifyList = [];
  getSpotifyList(){
    this.service.getSpotifyList("",1,20).subscribe((res:any)=>{
      console.log(res);
      this.spotifyList = res.data;
    })
  }

}
