import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";

import { ImageCropperModule } from "ngx-image-cropper";
import { CommonModule } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ToastrModule } from "ngx-toastr";
import { NgHttpLoaderModule } from "ng-http-loader";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { ModalModule } from "ngx-bootstrap/modal";
import { CustomFormsModule } from "ng2-validation";

import { NgxSliderModule } from "@angular-slider/ngx-slider";

import { AppRoutingModule } from "./app-routing.module";
import { AuthModule } from "./auth/auth.module";
import { SharedModule } from "./shared/shared.module";

import { AppComponent } from "./app.component";
import { AuthInterceptor } from "./_helpers/auth.interceptor";
import { AuthService } from "./auth/services/auth.service";
import { SharedService } from "./shared/services/shared.service";
import { ViewsComponent } from "./views/views.component";
import { AuthGuard } from "./_helpers/auth.guard.service";
import { EditProfileComponent } from "./edit-profile/edit-profile.component";
import { SessionService } from "./shared/services/session.service";
import { SocketIoModule, SocketIoConfig } from "ngx-socket-io";
import { environment } from "src/environments/environment";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { MatSelectModule } from "@angular/material/select";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";

const config: SocketIoConfig = {
	url: environment.BaseChatUrl, // socket server url;
	options: {
		transports: ['websocket']
	}
}

import { DashboardService } from "./dashboard/services/dashboard.service";
import { SafeHtmlPipe } from "./shared/pipes/safe-html.pipe";
import { HomeService } from "./views/home/home.service";
import { NotificationsService } from "./views/notifications/notifications.service";
import { RegistrationPartService } from "./views/registration-part/services/registration.service";
import { ProfileService } from "./views/profile/profile.service";
import { UrlService } from "./views/registration-part/services/url.service";
import { MessagesService } from "./views/messages/messages.service";
import { SearchResultService } from "./views/search-result/search-result.service";
import { ViewProfileService } from "./views/view-profile/view-profile.service";
import { SettingsService } from "./views/settings/settings.service";
import { CarouselModule } from 'ngx-owl-carousel-o';
// import { AngularFireModule } from '@angular/fire/compat';
// import { FirestoreModule } from '@angular/fire/firestore';
// import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
// import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
// import { fi } from '@angular/fire';
// import { AnalyticsModule } from '@angular/fire/analytics';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { MatProgressBarModule } from '@angular/material/progress-bar';


@NgModule({
  declarations: [
    AppComponent,
    ViewsComponent,
    EditProfileComponent,
    // SubZoneCoordinatesComponent,
    // ZoneCoordinatesComponent
  ],
  imports: [
    CommonModule,
    CarouselModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AuthModule,
    SharedModule,
    ImageCropperModule,
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    ModalModule.forRoot(),
    CustomFormsModule,
    NgxSliderModule,
    // SocketIoModule.forRoot(config),
    NgxIntlTelInputModule,
    MatSelectModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    // FirestoreModule
    // AnalyticsModule
    MatProgressBarModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    AuthGuard,
    AuthService,
    SharedService,
    DashboardService,
    SessionService,
    HomeService,
    NotificationsService,
    RegistrationPartService,
    ProfileService,
    UrlService,
    MessagesService,
    SearchResultService,
    ViewProfileService,
    SettingsService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA ],
})
export class AppModule {}
