<div id="auth-width" class="loginnscreen">
  <div class="container-fluid">
    <div class="row" >
      <div class="col-md-6">
        <div class="login-banner"  *ngIf="!isMobile">
          <!-- <img src="../../../../assets/Indietown_icons/login-banner.svg" alt="login-banner" class="img-fluid"> -->
          <div style="width: 100%;">
            <carousel [noWrap]="noWrapSlides" [showIndicators]="showIndicator" [interval]="2200" [isAnimated]="true">
               <slide *ngFor="let slide of slides; let index=index">
                  <img [src]="slide.image" [id]="slide.id" alt="image slide" style="display: block; width: 100%;">
                  <div class="carousel-caption">
               <h4 class="carouseltitle">Your Indie Music<br> Community</h4>
                     <!-- <p class="carouselcap">Connect, Share, and Build Community with <br>Independent Artists Everywhere. </p> -->
                     <p class="carouselcap">Connect and Share with <br>Independent Artists Everywhere. </p>
                  </div>
               </slide>
            </carousel>
            <!-- Connect and Share with Independent Artists Everywhere. -->
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="d-flex justify-content-between" *ngIf="isMobile" style="padding: 7px 22px;">
          <img class="logo" src="../../../../assets/Indietown_icons/login-logo.svg" alt="">
          <h3 class="logintext">Log In</h3>
        </div>
        <div *ngIf="isMobile" class="signin-title">
          <h4 class="carouseltitlemob">Welcome Back!</h4>
          <p class="carouselcapmob">Log in in to your account</p>
        </div>
        <div class="login-field">
          <div class="login-box">
          <div style="text-align: center; margin-bottom: 71px;margin-top: 25px;" class="loginlogodiv"  *ngIf="!isMobile">
            <img src="../../../../assets/Indietown_icons/login-logo.svg" class="loginlogo" alt="">
          </div>
          <h3 style="text-align: center;" class="mt-1 logintxt">Log In</h3>
          <div class="">
            <!-- <p class="mb/ss-3">Promoting skilled trades and technologies in Ontario since 1989.</p> -->
            <form [formGroup]="loginForm">
              <div class="form-group">
                <!-- <label for="email">Email Address *</label> -->
                <!-- <span class="input-icon"><img src="../../../../assets/icons/email.svg" alt="" style="margin-top: -10px;"></span> -->
                <input type="email" class="form-control" placeholder="Email Address" id="email" formControlName="email" style="width: 100%; margin-bottom: 0.3em" 
                [ngClass]="{'dynamic':loginForm.value.email != '','redbordercolor': (submitted && f.email.errors) || (f.email?.errors?.required && (f.email?.dirty || f.email?.touched))}"
                (keyup)="removeError($event)">
                <div *ngIf="submitted && f.email.errors" class="alert alert-danger">
                  <!-- <span *ngIf="f.email.errors.required" style="position:absolute;">
                    *Email Required
                  </span> -->
                  <span *ngIf="f.email.errors.email || f.email.errors.pattern"  style="position:absolute;">
                    *Please enter valid Email Address
                  </span>

                  <!-- <span *ngIf="f.email.errors.pattern">
                    *Please enter a valid Email Address
                  </span> -->
                </div>
                <div *ngIf="f.email?.errors?.required && (f.email?.dirty || f.email?.touched)" class="alert alert-danger">
                  <span style="position:absolute;">
                    *Email Required
                  </span>
                </div>
              </div>
              <div class="form-group passwordfield">
                  <mat-form-field appearance="fill" class="w-100">
                    <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" class="form-control w-100" placeholder="Password" 
                    [ngClass]="{'dynamic':loginForm.value.password != '','redbordercolor': submitted && f.password.errors}"
                    (keyup)="removeError($event)">
                    <button  class="eyebutton" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                      <mat-icon style="top:-5px">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <div class="alert alert-danger" *ngIf="submitted && f.password.errors">
                        <span *ngIf="f.password.errors.required">
                            *Password is required.
                        </span>
                        <span *ngIf="f.password.errors.minlength">
                            *Please enter correct password
                        </span>
                    </div>
                </mat-form-field>

                <span style="margin: 3% 0;     margin-top: 12%; float: right;" class="forgot-pass text-right" routerLink="/auth/forgot-password">Forgot Password?</span>
              </div>

              <div class="btn-login">
                <button (click)="login()" class="btn btn-primary" style="background-color: #000000;border: solid 1px #000000;" [disabled]="loginForm.invalid">Log In</button>
              </div>
            </form>

            <div style="text-align: center; margin-top: 10px;">
              <p>Don't have an account? <a href="javascript:void()" routerLink="/auth/signup" style="color: #000000;font-weight: bold;">Sign up</a></p>
            </div>
            <div style="display: flex;
            justify-content: space-evenly;">
              <a target="_blank" href="https://apps.apple.com/us/app/indie-town/id6445808133"><img class="mobile-view" src="assets/Indietown_icons/surface1.png" alt=""></a>
              <a target="_blank" href="https://play.google.com/store/apps/details?id=com.incubator.indietown&pli=1"><img class="mobile-view" src="assets/Indietown_icons/Group 1171276041.png" alt=""></a>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Logout fonfirm -->
<ng-template #template>
  <div class="modal-header">
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body text-center delete-modal align-items-center">
      <!-- <p style="font-size: 18px;line-height: normal">Please connect to admin as you are blocked <br><a href="mailto:invite@indietown.com">invite@indietown.com</a></p> -->
      <!-- <p style="font-size: 18px;line-height: normal">Please connect to admin as you are blocked <br><a href="mailto:support@indietown.org">support@indietown.org</a></p> -->
      <p style="font-size: 18px;line-height: normal">You are blocked. Contact admin at <br><a href="mailto:info@indietown.org">info@indietown.org.</a></p>
  </div>
</ng-template>