<div id="forgot-password">
    <div class="container-fluid">
        <div class="row logoimg">
            <div class="logo"><img src="../../../../assets/Indietown_icons/login-logo.svg" alt="logo"></div>

            <div class="col-md-12" id="alignscreencenter">
                <div class="login-field">
                    <div class="login-box">
                            <div class="main-content">
                                <h3><span class="enter-phone">Update Your</span> Phone Number</h3>
                                <form [formGroup]="resetForm">
                                    <div class="form-group phone-number">
                                        <div style="position: absolute;">
                                            <mat-form-field appearance="fill" style="position: relative;
                                            top: 19px;
                                            width: 39px;
                                            left: 12px;
                                            background: transparent;
                                            margin-top: -10px
                                            ">
                                                <mat-select (selectionChange)="onSelectCode($event.value)" formControlName="code"  [(value)]="selectedPhoneCode" style="width: 198%;
                                                margin-top: -7px">
                                                    <input type="text" (keyup)="onKey($event.target.value)" style="width: 95%;
                                                    padding: 7px;
                                                    background: #fff;
                                                    margin-top: 20px;
                                                    font-size: 16px;
                                                    border: 1px solid #0F0D0F !important;
                                                    border-radius: 7px;position:sticky;top:7px;z-index:1; outline: none;" class="ml-2" placeholder="Search here..">
                                                    <mat-select-trigger>
                                                        {{ selectedPhoneCode }}
                                                    </mat-select-trigger>
                                                    <mat-option *ngFor="let code of selectedPhoneCodes" [value]="code.dial_code">{{code.name}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Enter Phone Number" id="text" formControlName="phone" (keyup)="phoneFormat($event.target.value)" (keypress)="numberOnly($event)" maxlength="15" [ngClass]="resetForm.value.phone != ''? 'dynamic': ''">
                                        <div *ngIf="submitted && f.phone.errors" class="alert alert-danger">
                                            
                                            <span *ngIf="f.phone.errors.required">
                                                Phone number is required.
                                            </span>
        
                                            <span *ngIf="f.phone.errors.minlength">
                                                Please put 9 digit mobile number.
                                            </span>
                                        </div>
                                    </div>

                            <!--
                                <ngx-intl-tel-input
                                [cssClass]="'custom'"
                                [preferredCountries]="preferredCountries"
                                [enableAutoCountrySelect]="true"
                                [enablePlaceholder]="true"
                                [searchCountryFlag]="true"
                                [searchCountryField]="[
                                  SearchCountryField.Iso2,
                                  SearchCountryField.Name
                                ]"
                                [selectFirstCountry]="false"
                                [selectedCountryISO]="CountryISO.India"
                                [maxLength]="15"
                                [phoneValidation]="true"
                                [separateDialCode]="separateDialCode"
                                [numberFormat]="PhoneNumberFormat.National"
                                name="phone"
                                formControlName="phone"
                              >
                              </ngx-intl-tel-input> -->

                                    <button class="btn btn-primary" (click)="verifyPhone()" [disabled]="NextDisabledForPhoneNum">Next</button>
                                    <!-- <div class="m-20">
                                        <a routerLink="/register" class="backbtnn"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</a>
                                        <a routerLink="/register"><img src="../../../../assets/Indietown_icons/back-arrow-icon.svg" alt="" height="50" width="20"></a>
                                    </div> -->
                                </form>
                            </div>
                    </div>
                </div>
            </div>
        </div>

        
    </div>
</div>

<!-- Edit Mobile Number -->
<!-- <div class="modal-body text-center delete-modal align-items-center" id="editprofilepopup">
    <div class="createShowFields" style="height: 610px; overflow: auto">

      <form action="" [formGroup]="updateProfileForm"> -->
        <!-- <div class="my-3" style="position: relative;width: 200px; margin: auto;"> -->
<ng-template #showupdateGenerModal>
  <shared-updated-invitation-code *ngIf="updateScreen == 'updateOTP'" (OTPDetails)="collectUpdatedupdateMobNum($event)"></shared-updated-invitation-code>
  </ng-template>
  <!-- </div> -->
  <!-- </form>
  </div>
</div> -->