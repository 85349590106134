import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './components/login/login.component';
import { SignupComponent } from './components/signup/signup.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { EmailSendComponent } from './components/email-send/email-send.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { TermConditionComponent } from './components/term-condition/term-condition.component';
import { SharedModule } from '../shared/shared.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { MatChipsModule } from '@angular/material/chips';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { AlertModule,AlertConfig } from 'ngx-bootstrap/alert';
import { SplashScreenComponent } from './components/splash-screen/splash-screen.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'email-send', component: EmailSendComponent },
  { path: '', redirectTo: 'welcome', pathMatch: 'full' },
  { path: 'reset-password', component: ResetPasswordComponent},
  { path: 'terms-and-conditions', component: TermConditionComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'welcome', component: SplashScreenComponent}
];



@NgModule({
  declarations: [
    LoginComponent,
    SignupComponent,
    ForgotPasswordComponent,
    EmailSendComponent,
    ResetPasswordComponent,
    TermConditionComponent,
    PrivacyPolicyComponent,
    SplashScreenComponent,
  ],
  imports: [
    MatIconModule,
    MatFormFieldModule,
    CommonModule,
    SharedModule,
    FormsModule,
    CarouselModule,
    AlertModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    RouterModule.forChild(routes),
    MatInputModule,
    MatChipsModule
  ],
  exports:[
    TermConditionComponent,
    PrivacyPolicyComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AuthModule { }
