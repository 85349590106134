<!-- <div id="forgot-password">
  <div class="container-fluid">
    <div class="d-flex logoimg">
      <div class="logo">
        <img
          src="../../../../assets/Indietown_icons/login-logo.svg"
          alt="logo"
        />
      </div>
    </div>
  </div>
</div> -->

<div class="page centerplacement" style="display: flex;
height: 100%;
align-items: center;
justify-content: center;">
  <!-- 3rd page -->
  <div class="coverpictureblock">
    <div class="text-center main-heading">
      <h1 style=" margin-top: 30px;   margin-bottom: 90px;"><span class="text-grey">Update </span> Cover Picture</h1>
    </div>
    <form [formGroup]="updateProfile">
    <div class="text-center">
      <div class="file-input-wrapper coverPic">

        <div style="width: 725px;height: 285px;display: flex;justify-content: center;align-items: center;background-color: black;">
          <img class="coverpicture" [src]="coverPicUrl" alt="" style="border: 1px solid #f1f1f1; border-radius: 11px 11px 0 0;width: 100%; "
            id="coverpicurll" />
            <!-- height="285" width="725" -->
        </div>
       

        <div class="cameraCover">
          <!-- multiple="" -->
          <!-- UPDATED CODE FOR EDIT OPTIONS -->
          <!-- <input
          *ngIf="!showDeleteCoverButton"
          #myFile3
          type="file"
          name="myFile3"
          id="myFile3"
          value=""
          (change)="openCoverPictureModal('choose',$event,CoverPicCropModal)"
          accept="image/*"
            style="
             opacity: 0;
            position: absolute;
            width: 50px;
            height: 50px;
            z-index: 99;
            right: 46.5%;
            top: 7.5%;
            "
            [ngClass]="{'transformm' : showEditIcon}"
          /> -->

          <!-- (change)="uploadProfile($event)" -->
          <input
          #myFile3
            type="file"
            name="myFile3"
            id="myFile3"
            value=""
            (change)="openCoverPictureModal('choose',$event,CoverPicCropModal)"
            accept="image/*"
            style="
             opacity: 0;
            position: absolute;
            width: 50px;
            height: 50px;
            z-index: 99;
            right: 46.5%;
            top: 7.5%;
            visibility: hidden;
            "
            [ngClass]="{'transformm' : showEditIcon}"
          />
          <!-- <input type="hidden" name="" id="" formControlName="myFile"> -->
          <!-- UPDATED CODE FOR EDIT OPTIONS -->
          <!-- <img src="../../../../assets/Indietown_icons/camera.png" alt="" height="50" width="50" id="imageView" *ngIf="!showDeleteCoverButton" style="transform: translate(10px, 20px);"/>
          <img src="../../../../assets/Indietown_icons/edit-icon2.svg" *ngIf="showDeleteCoverButton" (click)="popupedits=true" alt="" height="50" width="50" id="imageView" style="transform: translate(15px, 6px);
        " /> -->
        <!-- *ngIf="!showEditIcon" -->
        <img src="../../../../assets/Indietown_icons/camera.png" alt="" height="50" width="50" id="imageView" *ngIf="!showDeleteCoverButton" (click)="myFile3.click()" style="transform: translate(-1px, 5px);cursor: pointer;"/>
        <!-- *ngIf="showEditIcon" -->
        <img src="../../../../assets/Indietown_icons/edit-icon2.svg" alt="" height="50" width="50" id="imageView" *ngIf="showDeleteCoverButton" (click)="popupedits=true" style="transform: translate(15px, 6px);cursor: pointer;"/>

          <!-- <span (click)="deleteCoverPic()" *ngIf="showDeleteCoverButton">
            <img src="../../../../assets/Indietown_icons/cross.svg" height="20" width="20" alt="" style="    top: -264px;
            left: 33px;
            position: relative;background: #FFFFFF;
border: 1px solid rgba(15, 13, 15, 0.2);
box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.05);  padding: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%; cursor: pointer;">  
          </span> -->
        </div>

        <div class="profile-pic">
          <img
            [src]="profilePicUrl"
            alt=""
            height="140"
            width="140"
            id="imageView"
            style="border-radius: 100%; object-fit: cover;"
          />
        </div>
      </div>
    </div>
  </form>
<div class="save-button">
  <button class="btn btn-primary" (click)="save()"> Save </button>
</div>

<ng-template #CoverPicCropModal>
  <div class="modal-header" style="padding: 20px;padding-bottom: 0;">
    <h4 class="modal-title pull-left">Cover Picture</h4>
    <button type="button" class="btn-close close pull-right" style="width:30px; height: 30px; font-size: 30px; opacity: 1;" aria-label="Close" (click)="closeProfilePicModal()">
      <span aria-hidden="true" style="color: #fff; opacity: 1; text-shadow: none;" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="padding: 20px;">
    <div class="profile-crop">
      <!-- [maintainAspectRatio]="true"
      [aspectRatio]="16/9" -->
       <!-- cropperMinWidth="1284"
        cropperMinHeight="290" -->

        <!-- <img [src]="coverPicCropperUrl"> -->
        <!-- maintainAspectRatio="false"
          containWithinAspectRatio="true"
        cropperStaticWidth="1284"
        cropperStaticHeight="290"
        cropperMinWidth="1284"
        cropperMinHeight="290"
        cropperMaxWidth="1284"
        cropperMaxHeight="290"
       -->
      <image-cropper
        [imageChangedEvent]="coverPictureImageChangedEvent"
        [imageURL]="coverPicCropperUrl"
        [maintainAspectRatio]="true"
        [containWithinAspectRatio]="false"
        [aspectRatio]="4 / 1"
        [resizeToWidth]="1286"
        [cropperMinWidth]="128"
        [onlyScaleDown]="true"
        [roundCropper]="false"
        [alignImage]="'left'"
        format="png"
        allowMoveImage="true"
        [transform]="coverPictureImageTransform"
        (imageCropped)="croppedCoverPicture($event)"
      ></image-cropper>
    </div>
    <div style="padding-top: 10px;">
      <!-- <button class="text-white croppic" (click)="coverPictureZoomOut()">Zoom -</button>
      <button class="text-white croppic" (click)="coverPictureZoomIn()">Zoom +</button> -->
      <div class="d-flex align-items-center" style="margin-top: 16px;">
        <button class="text-white  croppic"
        style="border:none !important;background: #fff !important;outline:0 !important;padding: 0px !important;color:  #555 !important;  margin-right: 7px !important;font-size: 20px !important;cursor: pointer;margin-top:0 !important;" (click)="coverPictureZoomOut()"
        >-</button>
  
        <input type="range" min="1" max="2" step=".1"  value="{{coverPictureScale}}"
        (mouseup)="coverPictureZoomImage($event)" class="slider" id="myRangeCoverPic"
        style="background: #ccc !important;">
  
        <button class="text-white croppic"
        style="border:none !important;outline:0 !important;background: #fff !important; color: #555 !important;padding: 0px !important;  margin-left: 5px !important;font-size: 20px !important;margin-top:0 !important;cursor: pointer;margin-right: 10px;"
        (click)="coverPictureZoomIn()">+</button>

        <button type="button" class="btn croppic" style="background: #efefef; background-color: none !important;border: none !important;" (click)="coverPictureCancelled()"><span  style="background: #efefef; padding:4px 10px; border-radius: 4px; color: #000;">Cancel</span></button>
        <button type="button" class="btn croppic" style="background: #000; background-color: none ;border: none;margin-left: 10px;" (click)="uploadCoverProfile()"><span  style="background: #000; padding:4px 10px; border-radius: 4px; color: #fff;">Save</span></button>
  
      </div>
      
    </div>
  </div>
</ng-template>
<div class="overlay" *ngIf="popupedits">
  <div class="popupeditblock1">
      <!-- <a class="close" (click)="popupedit = false"><img _ngcontent-nbr-c249="" src="../../../../assets/Indietown_icons/cross.svg" height="20" width="20" alt="" style="top: 10px;right: 13px;background: #fff;border-radius: 32px;box-shadow: 0 0px 1px #000000b5;padding: 5px;position: absolute;"></a> -->
      <div class="content-edit">
       <a style="cursor: pointer;" (click)="deleteCoverPic()">Delete Photo</a>
       <a (click)="openCoverPictureModal('edit','',CoverPicCropModal)">Edit Photo</a>
       <a (click)="coverFile.click()" *ngIf="showDeleteCoverButton">
        <input #coverFile type="file" class="d-none" (change)="openCoverPictureModal('choose',$event,CoverPicCropModal)"
     accept="image/*">Choose Photo</a>
       <a (click)="popupedits = false">Cancel</a>
      </div>
  </div>
</div>
</div>
</div>
