import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class SettingsService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    silentUsersList(){
        return this.http.get(this.url + 'api/user/silent-user-list');
    }

    changePassword(data:any){
        return this.http.put(this.url +'api/user/change-password', data);
    }


    unmuteUser(id:any){
            return this.http.post(this.url + 'api/user/un-silent-user',id)
    }

    invitationCode(){
        return this.http.get(this.url + 'api/user/invitation-code-list')
    }

    searchSilentUser(keyword){
        return this.http.get(this.url + `api/user/silent-user-list?keyword=${keyword}`);
    }


    updateProfile(data:any){
        return this.http.put(this.url + 'api/user/update-profile', data);
    }
}  