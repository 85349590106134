import { Component, Input, OnInit, SecurityContext, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/auth/services/auth.service';
import { SharedService } from '../services/shared.service';
import { DomSanitizer} from '@angular/platform-browser';


@Component({
  selector: 'app-soundcloud',
  templateUrl: './soundcloud.component.html',
  styleUrls: ['./soundcloud.component.scss']
})
export class SoundcloudComponent implements OnInit {
  modalRef: any;
  myInputValues:any='';
  loadMoreSoundcloud: Boolean = true; 
  @ViewChild("soundcloudIframe") soundcloudIframe: TemplateRef<any>;
  removeModalref: any;
  @ViewChild("removeSoundcloudIframeModal") removeSoundcloudIframeModal: TemplateRef<any>;
  
  @Input() userId;

  constructor(
    private modalService: BsModalService,
    private service: SharedService,
    private toastrService: ToastrService,
    private authService: AuthService,
    private fb: FormBuilder,
    private router: ActivatedRoute,
    private spinner: SpinnerVisibilityService,
    private route: Router,
    private domSanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    console.log("from soundcloud",this.userId);
    this.getSoundcloudList();
  }

  page = 1;
  pageSize = 20;
  soundcloudList = [];
  getSoundcloudList(){
    this.service.getSoundcloudList(this.userId,this.page,this.pageSize).subscribe((res:any)=>{
      console.log(res);
      this.soundcloudList = res.data;
    })
  }
  addSoundcloudIframe(){
    console.log((<HTMLInputElement>document.getElementById("soundcloudIframeNew")).value);
    let soundcloudIframe = (<HTMLInputElement>document.getElementById("soundcloudIframeNew")).value;
    this.service.addSoundcloudIframe({iframeCode:soundcloudIframe}).subscribe((res:any)=>{
      console.log(res);
      this.soundcloudList.unshift(res.data);
      (<HTMLInputElement>document.getElementById("soundcloudIframeNew")).value = '';
      this.myInputValues = '';
    },
    (err)=>{
      console.log(err);
      console.log(err?.error?.message);
      if(err?.error?.message?.includes('iFrame code already added')){
        this.toastrService.info("iFrame code already added");
      }
      else {
        this.toastrService.info(err?.error?.message);
      }
      (<HTMLInputElement>document.getElementById("soundcloudIframeNew")).value = '';
      this.myInputValues = '';
    })
  }
  transFormUrl(url){
    // return this.domSanitizer.sanitize(SecurityContext.URL, url);
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
  iframeSource = '';
  soundcloudModalP(iframesrc:any){
    let initialState: ModalOptions = {
      initialState: {
        source:iframesrc
      } as Partial<Object>,
      class: 'soundcloudpopup'
    };
    this.iframeSource = iframesrc;
    this.modalRef = this.modalService.show(this.soundcloudIframe, initialState);
  }

  removeSoundcloudId;
  removeSoundcloudIndex;
  removeSoundcloud(id,index){

    this.removeSoundcloudId = id;
    this.removeSoundcloudIndex = index;

    // this.service.removeSoundcloudIframe(id).subscribe((res:any)=>{
    //   console.log(res);
    //   if(res.type == 'success'){
    //     this.soundcloudList.splice(index,1);
    //   }
    // })

    this.removeModalref = this.modalService.show(this.removeSoundcloudIframeModal,{class:"remove-soundcloud-modal"});
  }

  removeSoundcloudIframe(){
    this.service.removeSoundcloudIframe(this.removeSoundcloudId).subscribe((res:any)=>{
      console.log(res);
      if(res.type == 'success'){
        this.soundcloudList.splice(this.removeSoundcloudIndex,1);
      }
      this.removeModalref.hide();
    })
  }

  loadMoreSoundcloudData(e){
    console.log("scrolled fired");
    if(this.loadMoreSoundcloud){
      console.log("loading more")
      this.page++;
      this.getMoreSoundcloudList();
    }
  }

  // loadMoreSoundcloud:Boolean = true;
  getMoreSoundcloudList(){
    this.service.getSoundcloudList(this.userId,this.page,this.pageSize).subscribe((res:any)=>{
      console.log(res);
      if(res.data.length){
        this.soundcloudList.push(...res.data);
      }
      else{
        this.loadMoreSoundcloud = false;
      }
    })
  }
  @Input('addSoundcloud')
  set addSoundcloud(data: any) {
      //do whatever you want with your data here, this data will be passed from parent component
      console.log("soundcloud to add",data);
      if(data && Object.keys(data).length){
        this.soundcloudList.unshift(data);
      }
  }
}
