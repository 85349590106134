import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/auth/services/auth.service';
import { BandcampModalContentComponent } from '../dynamic-profile/dynamic-profile.component';
import { SharedService } from '../services/shared.service';

@Component({
  selector: 'app-bandcamp',
  templateUrl: './bandcamp.component.html',
  styleUrls: ['./bandcamp.component.scss']
})
export class BandcampComponent implements OnInit {
  modalRef: any;
  
  @Input() userId;
  myInputValue:any='';
  @ViewChild("bandcampIframe") bandcampIframe: TemplateRef<any>;

  removeModalref: any;
  @ViewChild("removeBandcampIframeModal") removeBandcampIframeModal: TemplateRef<any>;

  constructor(
    private modalService: BsModalService,
    private service: SharedService,
    private toastrService: ToastrService,
    private authService: AuthService,
    private fb: FormBuilder,
    private router: ActivatedRoute,
    private spinner: SpinnerVisibilityService,
    private route: Router,
    private domSanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    console.log(this.userId);
    this.getBandcampList();
  }

  page = 1;
  pageSize = 20;
  bandcampList = [];
  getBandcampList(){
    this.service.getBandcampList(this.userId,this.page,this.pageSize).subscribe((res:any)=>{
      console.log(res);
      this.bandcampList = res.data;
    })
  }
  addBandcampIframe(){
    console.log((<HTMLInputElement>document.getElementById("bandcampIframeNew")).value);
    let bandcampIframe = (<HTMLInputElement>document.getElementById("bandcampIframeNew")).value;
    this.service.addBandcampIframe({iframeCode:bandcampIframe}).subscribe((res:any)=>{
      console.log(res);
      // Add Validations
      this.bandcampList.unshift(res.data);
      (<HTMLInputElement>document.getElementById("bandcampIframeNew")).value = '';
      this.myInputValue = '';
    },
    (err)=>{
      console.log(err);
      console.log(err?.error?.message);
      if(err?.error?.message?.includes('iFrame code already added')){
        this.toastrService.info("iFrame code already added");
      }
      else {
        this.toastrService.info(err?.error?.message);
      }
      (<HTMLInputElement>document.getElementById("bandcampIframeNew")).value = '';
      this.myInputValue = '';
    })
  }
  transFormUrl(url){
    // return this.domSanitizer.sanitize(SecurityContext.URL, url);
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
  iframeSource = '';
  bandcampModalP(iframesrc:any){
    let initialState: ModalOptions = {
      initialState: {
        source:iframesrc
      } as Partial<Object>,
      class: 'bandcamppopup'
    };
    this.iframeSource = iframesrc;
    this.modalRef = this.modalService.show(this.bandcampIframe, initialState);
  }

  removeBandcampId;
  removeBandcampIndex;

  removeBandcamp(id,index){
    // this.service.removeBandcampIframe(id).subscribe((res:any)=>{
    //   console.log(res);
    //   if(res.type == 'success'){
    //     this.bandcampList.splice(index,1);
    //   }
    // })

    this.removeBandcampId = id;
    this.removeBandcampIndex = index;
    this.removeModalref = this.modalService.show(this.removeBandcampIframeModal);

  }

  removeBandcampIframe(){
    this.service.removeBandcampIframe(this.removeBandcampId).subscribe((res:any)=>{
      console.log(res);
      if(res.type == 'success'){
        this.bandcampList.splice(this.removeBandcampIndex,1);
      }
      this.removeModalref.hide();
    })
  }

  loadMoreBandcamps(e){
    console.log("scrolled fired");
    if(this.loadMoreBandcamp){
      console.log("loading more")
      this.page++;
      this.getMoreBandcampList();
    }
  }

  loadMoreBandcamp:Boolean = true;
  getMoreBandcampList(){
    this.service.getBandcampList(this.userId,this.page,this.pageSize).subscribe((res:any)=>{
      console.log(res);
      if(res.data.length){
        this.bandcampList.push(...res.data);
      }
      else{
        this.loadMoreBandcamp = false;
      }
    })
  }

  @Input('addBandcamp')
  set addBandcamp(data: any) {
      //do whatever you want with your data here, this data will be passed from parent component
      console.log("sdasdsdsd",data);
      if(data && Object.keys(data).length){
        this.bandcampList.unshift(data);
      }
  }

}
