export class AnalyticsEvents {
    public static SCREEN_VISITED = 'Screen_Visited';
    public static LOGIN = 'log_in';
    public static LOGOUT = 'log_out';
    public static DELETE_ACCOUNT = 'delete_account'; // NOT USING CURRENTLY
}

// Screen Visited Constants
// SV -> Screen Visited
export class SVConstants {

    public static home = "Home Screen";

    public static messages = "Messages Screen";

    public static notifications = "Notifications Screen";

    public static profile = "Profile Screen";

    public static settings = "Settings Screen";

    // public static comments = "Comments Screen";

    // public static likes = "Likes Screen";

    // public static suggestions = "Suggestions Screen";

    // public static search = "Search Screen";

    // public static createPost = "CreatePost Screen";

    // public static createShow = "CreateShow Screen";

    // public static chat = "Chat Screen";

    // public static newMessages = "NewMessages Screen";
   
    // public static othersProfile = "OthersProfile Screen";

    // public static editProfile = "EditProfile Screen";

    // public static spotify = "SpotifyList Screen";

    // public static youtube = "YoutubeList Screen";

    // public static bandcamp = "BandcampList Screen";

    // public static soundcloud = "SoundCloudList Screen";

    // public static spotifyPlayer = "SpotifyPlayer Screen";

    // public static bandcampPlayer = "BandcampPlayer Screen";

    // public static soundcloudPlayer = "SoundCloudPlayer Screen";  

}