import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
// import { Socket } from 'ngx-socket-io';
import { io } from "socket.io-client";
// import { io } from 'socket.io-client';
import { BehaviorSubject, Observable } from "rxjs";
import { Manager } from "socket.io-client";


@Injectable({
    providedIn: 'root',
  })
export class MessagesService {

    url: any = environment.API_URL;
    manager;
    // socket:any;
    public message$: BehaviorSubject<string> = new BehaviorSubject('');

    socket = io(environment.BaseChatUrl,{
        query:{accessToken:JSON.parse(localStorage.getItem('currentUser')).token}
    });

    constructor(
        private http: HttpClient
    ) {
        // this.manager = new Manager("https://indietown-api.approd.ca");

        // console.log("gggggggggggggggg");
        // this.socket = this.manager.socket("/");
        // this.socket.io.on("connect",(socket)=>{
        //     console.log("connection made successfully");
        //     this.socket.io.emit('login',{userId:'63fee68942cce284d9a69b51'});
        //     socket.on('chat-message',(data)=>{
        //         console.log("got a notification");
        //         console.log(data);
        //     })
        //     socket.on("error", (error) => {
        //         console.log("I am here at the error");
        //         console.log(error);
        //         // ...
        //     });
        // });
        // this.socket.io.on('chat-message',(data)=>{
        //     console.log("got a notification");
        //     console.log(data);
        // })
        // this.socket.io.on("error", (error) => {
        //     console.log("I am here at the error");
        //     console.log(error);
        //     // ...
        // });
        
        // this.listenMsg();
        this.socket.on('receive-message', (message) =>{
            this.message$.next(message);
            console.log("got a message from service", message);
        });
        
    }

    ngOnInIt(){
        
    }

    public sendMessage(message: any) {
        console.log('sendMessage: ', message)
        this.socket.emit('send-message', message);
    }
    
    // public getNewMessage = () => {
    //     this.socket.on('receive-message', (message) =>{
    //         this.message$.next(message);
    //         console.log("got a message", message);
    //     });

    //     return this.message$.asObservable();
    // };
   
    

    getChatUsersList(data){
        return this.http.get(this.url + `api/chat/chat-users?page=${data.page}&pageSize=${data.pageSize}`); 
    }
    getUserChat(params){
        return this.http.get(this.url + 'api/chat/messages',{
            params:params
        }); 
    }


    getDraftMessages(params){
        return this.http.get(this.url + 'api/chat/draft-messages',{
            params:params
        }); 
    }

    saveMessage(message){
        return this.http.post(this.url + 'api/chat/send-message',message); 
    }

    updateDraftMessage(data){
        return this.http.post(this.url + 'api/chat/update-draft-message',data); 
    }

    deleteUserChat(userId){
        return this.http.delete(this.url + `api/chat/delete-messages/${userId}`); 
    }

    deleteInterest(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'category/delete', httpOptions);
    }

    initiatChat(param:any) {
        this.socket.emit('initialFromFrontEnd', param);
    }

    socketoff(listner:any) {
        this.socket.removeAllListeners(listner);
    }

    socketon() {
        console.log('this.socket', this.socket);
        this.socket.connect();
    }

    socketend() {
        this.socket.disconnect();
    }

    
    sendMsg(param:any) {
        this.socket.emit('new-message', param);
    }

    login(data:any) {
        this.socket.emit('login', data);
    }
    listenMsg(){
        // this.socket.listeners('');
        this.socket.on('chat-message',(data)=>{
            console.log("got a notification");
            console.log(data);
        })
    }

    getAnswer(param:any) {
        console.log('chat service params', this.socket);
        this.socket.emit('answerOfChat', param);
    }

    getChatHistory() {
        return Observable.create((observer:any) => {
          this.socket.on('output', (message:any) => {
            console.log('output message', message);
            observer.next(message);
          });
        });
    }

    getSupportRequest() {
        return Observable.create((observer:any) => {
          this.socket.on('newRequestComing', (count:any) => {
            console.log('output message', count);
            observer.next(count);
          });
        });
    }

    friendsList(data){
        return this.http.get(this.url + `api/friend/list?page=${data.page}&pageSize=${data.pageSize}`)
    }

    readMessageUpdate(userId){
        return this.http.put(this.url + `api/chat/read-messages/${userId}`,{})
    }

    connectToSocket(currentUserId){
        this.socket.on("connect",()=>{
            console.log("Connection made");
            this.socket.emit('login',{userId:currentUserId});
        });
    }

    sendMsgEvent(data){
        this.socket.emit('new-message',data);
    }

    deleteMultipleConversations(userIds){
        return this.http.put(this.url + 'api/chat/delete-conversations',{userIds})
    }

    removeDraft(userId){
        return this.http.put(this.url + `api/chat/remove-draft-message/${userId}`,{})
    }
    
}