import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
// import { RegistrationPartService } from '../../registration-part/services/registration.service';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from 'ngx-toastr';
import { LocationStrategy } from '@angular/common';
// import { UrlService } from '../../registration-part/services/url.service';
import { SharedService } from 'src/app/shared/services/shared.service';
// import { CdkDragDrop } from '@angular/cdk/drag-drop';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop'; 
    // <= DragDropModule removed


@Component({
  selector: 'shared-update-gener',
  templateUrl: './update-gener.component.html',
  styleUrls: ['./update-gener.component.scss']
})
export class UpdateGenerComponent implements OnInit {

  submitted: boolean = false;
  imgURLCover:any;
  currentUser:any;
  selectedFile:any;
  profilePicUrl:any;
  coverPicUrl:any;
  profileData:any;
  profileImageFileUrl:any;
  profileImageKey:any;
  change:boolean = false;
  genersData:any=[];
  generIdsList:any=[];
  genreList:any=[];
  sample:any=[];
  role:any;

  @Output() ids = new EventEmitter();
  
  @ViewChild("template") template: TemplateRef<any>;
  constructor(
    // private service: RegistrationPartService,
    private router: Router,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private location: LocationStrategy,
    // private urlService: UrlService,
    private sharedService: SharedService,
    private fb: FormBuilder
  ) {   }


  ngOnInit(): void {
    this.profilePicUrl = "../../../../assets/Indietown_icons/upload-profile-pic.png";
    this.coverPicUrl = '../../../../assets/Indietown_icons/upload-cover-pic.png';

    this.sharedService.getProfile().subscribe((res:any)=> {
        if(res.type == 'success'){
          this.profileData = res.data;
          if(res.data?.profileImage.fileUrl){
            this.profilePicUrl = res.data?.profileImage.fileUrl;
          }

          this.role = this.profileData.role;

          if(this.profileData.role!='Artist'){
            this.sharedService.getGenres().subscribe((res:any) => {
              if(res.type == 'success'){
                this.genreList = res.data;
                for(var id of this.profileData?.communityIds){
                  for(var gener of this.genreList){
                      if(gener._id ==id?._id){
                          // this.sample.push(gener);
                          const addChip = () => { this.chips.add(gener); };
                          // const removeChip = () => { this.chips.delete(gener); };
                          this.chips.has(gener) ? addChip() : addChip();
                      }
                  }
                }
                // this.generForm.get('gener').patchValue(this.sample);
                // this.chips.add(this.sample);
              }
            })
          }else{
            this.sharedService.getArtistGenerList().subscribe((res:any) => {
              if(res.type == 'success'){
                this.genreList = res.data;
                for(var id of this.profileData?.genereIds){
                  for(var gener of this.genreList){
                      if(gener._id ==id?._id){
                        this.sample.push(gener);
                        const addChip = () => { this.chips.add(gener); };
                        // const removeChip = () => { this.chips.delete(gener); };
                        this.chips.has(gener) ? addChip() : addChip();
                        
                      }
                  }
                }
                // this.generForm.get('gener').patchValue(this.sample);
                // this.chips.add(this.sample);
              }
            })
          }
        }
    })  

    // this.service.getGenres().subscribe((res:any) => {
    //   if(res.type == 'success'){
    //     this.genreList = res.data;
        
    //   }
    // })
    // this.generForm.get('gener').patchValue(this.sample);
  }


  
  generForm = new FormGroup({
    gener : new FormControl(),
  })

  // onSelectGener(event: any) {
  //   console.log(event.value);
  //   this.genersData = event.value;

    // this.change = true;
    // this.generIdsList = [];
    // for(var i of event.value){
    //     let y = i.split(' ');
    //     this.generIdsList.push(y[y.length-1]);
    // }
    // console.log(this.generIdsList);
// }

 


save(){
  let ids:any=[];
  let generNames = '';
  console.log(this.chipControl.value);
  if(this.profileData.role=='Artist'){
    for(var i of this.chipControl.value){
      ids.push(i?._id);
      generNames = generNames + ', ' + i.genereName;
    }
  }
  else{
    for(var i of this.chipControl.value){
      ids.push(i?._id);
      generNames = generNames + ', ' + i.communityName;
    }
  }

  console.log("ids"+typeof ids);
  console.log(ids, generNames);

    let data = {
        "role": this.profileData?.role,
        "address":{
            "city": this.profileData?.address?.city,
            "state": this.profileData?.address?.state,
            "country": this.profileData?.address?.country,
             
        },
        "bandName": this.profileData?.bandName,
        // "location":{
        //     "lat": this.profileData?.location?.coordinates[0],
        //     "long": this.profileData?.location?.coordinates[1]    
        // },
        "profileImage":{
            "fileUrl": this.profileData?.profileImage?.fileUrl,
            "key": this.profileData?.profileImage?.key,
        },
        "coverImage":{
            "fileUrl": this.profileData?.coverImage?.fileUrl,
            "key": this.profileData?.coverImage?.key
        },
        "email":this.profileData?.email,
        "bio": this.profileData?.bio,
        "communityIds": this.profileData.role!='Artist' ? ids : [],
        "genereIds": this.profileData.role=='Artist' ? ids : []
      } 

      this.sharedService.updateProfile(data).subscribe((res:any)=> {
        if(res.type == 'success'){
          // this.modalService.hide();
          // this.router.navigateByUrl("/profile/main");
      //     this.router.navigateByUrl('/',{skipLocationChange:true}).then(()=>{
      //       this.router.navigate([`/profile`]).then(()=>{
      // console.log("hello")
      //       })
      //     })
          this.ids.emit({ids,generNames:generNames.substring(1)});
        }
      })


    

}

chipControl = new FormControl(new Set());

  toggleChip = (chip: any) => {

    if(this.role =='Artist'){
      if(this.chips.has(chip)){
        this.chips.delete(chip);
      }else{
        if(this.chipControl.value.size>=3){
          this.toastr.error("Maximum 3 Allowed");
        }
        else{
          this.chips.add(chip);
        }
      }
    }else{
      if(this.chips.has(chip)){
        this.chips.delete(chip);
      }else{
        if(this.chipControl.value.size>=2){
          this.toastr.error("Maximum 2 Allowed");
        }
        else{
          this.chips.add(chip);
        }
      }
    }
    
  }

  addChip(chip){
    const addChip = () => { this.chips.add(chip); };
    const removeChip = () => { this.chips.delete(chip); };
    this.chips.has(chip) ? removeChip() : addChip();
  }

  deleteChip(chip){
    const removeChip = () => { this.chips.delete(chip); };
    this.chips.has(chip) ? removeChip() : removeChip();
  }



  get chips() { 
    // console.log(this.chipControl);
    // console.log(this.chipControl.value.size);
    return this.chipControl.value; 
  }

  drop(event) {
    console.log(event);
    // moveItemInArray(this.timePeriods, event.previousIndex, event.currentIndex);
  }
  horizontal = "horizontal";

}

