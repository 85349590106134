import { Component, OnInit,AfterViewInit,DoCheck, TemplateRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/auth/services/auth.service';
import { BsModalService } from "ngx-bootstrap/modal";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { SharedService } from '../services/shared.service';
import { MessagesService } from 'src/app/views/messages/messages.service';
// import { io } from "socket.io-client";
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { NotificationsService } from 'src/app/views/notifications/notifications.service';
declare var Pushwoosh:any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  constructor(
    private toAsterService: ToastrService,
    private authService: AuthService,
    public router: Router,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private shared: SharedService,
    private messageService: MessagesService,
    private notificationsService: NotificationsService
  ) {}

  modalRef: any;
  currentUser:any;
  profileDetails:any;
  showNewMesageRedDot:Boolean = false;

  // socket = io(environment.BaseChatUrl,{
  //   query:{accessToken:JSON.parse(localStorage.getItem('currentUser')).token}
  // });
  msgSubscriptionSideBar: Subscription;
  currentUrl;
  ngOnInit(): void {
    this.currentUser = this.authService.currentUserValue;
    this.getProfile();

    // if(!this.currentActiveScreen){
      // this.router.events.subscribe(event => {
      //   console.log(event);
      //   if (event instanceof NavigationEnd) {        
          
      //     console.log(event.url);
      //     this.currentUrl = event.url;
      //   };
      // });
    // }
    // this.messageService.getNewMessage().subscribe((message: string) => {
    //   // this.messageList.push(message);
    //   console.log("got a message", message);
    //   // this.showNewMesageRedDot = true;
    // })

    
    // this.socket.on('receive-message', (message) =>{
    //   console.log("got a message in sidebar", message);
    //   this.showNewMesageRedDot = true;
    // });

    this.msgSubscriptionSideBar = this.messageService.message$.subscribe((message: string) => {
      // this.messageList.push(message);
      // if(this.currentActiveScreen == 'messages'){
      //   this.showNewMesageRedDot = false;
      //   this.messageService.message$.next('');
      // }
      // else{
        // console.log("you are not in messages screen", message);
        if(message && Object.keys(message)?.length){
          this.showNewMesageRedDot = true;
        } 
      // }
      
    })

    this.notificationsService.getNotifications(1,10).subscribe((res: any) => {
      console.log("from sidebar");
      console.log(res);
      if(res.data?.length){
        if(!res.data[0].read){
          this.showNotificationRedDot = true;
        }
      }
    });

    this.checkNotifications();

    if ('serviceWorker' in navigator) {

      console.log("check 1");
       navigator.serviceWorker
        .register('assets/pushwoosh-service-worker.js', { })
          .then( (reg) => {
            console.log('Service Worker is ready123', reg);
            reg.active.postMessage("ping");
            console.log("Posted message");
            console.log("check 2");
            navigator.serviceWorker.addEventListener('message', (event) => {
              console.log("from step final 9999");
              var messageFromSW = event.data;
              console.log(messageFromSW);
              this.showNotificationRedDot = true;
              this.notificationsService.newNotification$.next(messageFromSW);
              // you can also send a stringified JSON and then do a JSON.parse() here.
            });
        }).catch(function(error) {
            console.log('Error : ', error);
        });
      // ensure service worker is ready
      
    }
  }
  showNotificationRedDot: Boolean =  false;

  checkNotifications(){

    Pushwoosh.push(['onLoad', (api) => {
        console.log('Pushwoosh load!',api);

        Pushwoosh.addEventHandler('subscribe', (payload) => {
          console.log('Triggered event: subscribe',payload);
          Pushwoosh.getPushToken().then((pushToken) => {
            console.log('by default pushToken:', pushToken);
            if(pushToken){
              this.shared.updateProfile({deviceToken:pushToken,isNotificationEnabled:true}).subscribe((res:any)=>{
                console.log(res);
              })
            }
          });
        });

        Pushwoosh.isSubscribed().then((isSubscribed) => {
          console.log('isSubscribed', isSubscribed);
        });

        Pushwoosh.getPushToken().then((pushToken) => {
          console.log('by default pushToken:', pushToken);
          if(pushToken){
            this.shared.updateProfile({deviceToken:pushToken,isNotificationEnabled:true}).subscribe((res:any)=>{
              console.log(res);
            })
          }
        });

        Pushwoosh.addEventHandler('receive-push', function (payload) {
          console.log('Triggered event: receive-push', payload);
        });

        Pushwoosh.addEventHandler('receive-inbox-message', function (payload) {
          console.log('Triggered event: receive-inbox-message', payload);
        });
      
        // Executed after the Inbox is updated automatically while the page is loading.
        Pushwoosh.addEventHandler('update-inbox-messages', function (payload) {
          console.log('Triggered event: receive-inbox-messages', payload);
        });
        
        Pushwoosh.addEventHandler('unsubscribe', function (payload) {
          console.log('Triggered event: unsubscribe',payload);
        });

        Pushwoosh.push(['onUpdateInboxMessages', function(api, pwinbox) {
          pwinbox.loadMessages().then(function(messages) {
            console.log('EVENT: onUpdateInboxMessages', messages);
          });
        }]);

        // Pushwoosh.push(['onMessageReceived', function(api, pwinbox) {
        //   console.log("messsssgggggg");
        //   // pwinbox.loadMessages().then(function(messages) {
        //   //   console.log('EVENT: onUpdateInboxMessages', messages);
        //   // });
        // }]);

        // Executed by ServiceWorker after the Inbox Message is received and saved to indexedDB. 
        Pushwoosh.push(['onPutNewMessageToInboxStore', function(api, message) {
          console.log('EVENT: onPutNewMessageToInboxStore', message);
        }]);

        Pushwoosh.addEventHandler('message', function (payload) {
          console.log('message event: receive-push', payload);
        });

        navigator.serviceWorker.addEventListener('message', event => {
          // use `event.data`
          console.log("messageeeeeeeeeeeeeeeeeeeeeee");
        });

        Pushwoosh.addEventHandler('onPushDelivery', function (payload) {
          console.log('message event: onPushDelivery', payload);
        });

        Pushwoosh.addEventHandler('onPutNewMessageToInboxStore', function (payload) {
          console.log('message event: onPutNewMessageToInboxStore', payload);
        });

        Pushwoosh.addEventHandler('onUpdateInboxMessages', function (payload) {
          console.log('message event: onUpdateInboxMessages', payload);
        });

        Pushwoosh.addEventHandler('onNotificationClose', function (payload) {
          console.log('message event: onNotificationClose', payload);
        });

        Pushwoosh.addEventHandler('onPushDelivery', function (payload) {
          console.log('message event: onPushDelivery', payload);
        });

        Pushwoosh.push(['onPushDelivery', function(payload) {
          console.log('User successfully receive-push',payload);
        }]);

        navigator.serviceWorker.addEventListener('onPushDelivery', event => {
          // use `event.data`
          console.log("messageeeeeeeeeeeeeeeeeeeeeee");
        });


    }]);

    navigator.serviceWorker.addEventListener('push', event => {
      // use `event.data`
      console.log("messageeeeeeeeeeeeeeeeeeeeeee",event);
    });

    Pushwoosh.push(['onPushDelivery', function(payload) {
      console.log('User successfully receive-push',payload);
    }]);

    Pushwoosh.push(['onLoad', function (api) {
      // Executed when a push notification is displayed.
      Pushwoosh.addEventHandler('receive-push', function (payload) {
        console.log('Triggered event: receive-push', payload.notification);
      });
    }]);

    Pushwoosh.push(function (api) {
      console.log('Pushwoosh ready!',api);
      Pushwoosh.subscribe();

      Pushwoosh.addEventHandler('message', function (payload) {
        console.log('message event: receive-push 2', payload);
      });

      // Pushwoosh.push(['message', function(api) {
      //   console.log('User successfully message',api);
      // }]);

      Pushwoosh.push(['onPushDelivery', function(payload) {
        console.log('User successfully receive-push',payload);
      }]);

      // Pushwoosh.push(['receive-inbox-message', function(api) {
      //   console.log('User successfully receive-inbox-message',api);
      // }]);

      // Pushwoosh.push(['onPutNewMessageToInboxStore', function(api) {
      //   console.log('User successfully onPutNewMessageToInboxStore-push',api);
      // }]);


      navigator.serviceWorker.addEventListener('message', event => {
        // use `event.data`
        console.log("messageeeeeeeeeeeeeeeeeeeeeee");
      });
      navigator.serviceWorker.addEventListener('onPushDelivery', event => {
        // use `event.data`
        console.log("messageeeeeeeeeeeeeeeeeeeeeee");
      });
      

      Pushwoosh.addEventHandler('receive-push', function (payload) {
        console.log('Triggered event: receive-push', payload);
      });

      Pushwoosh.addEventHandler('receive-inbox-message', function (payload) {
        console.log('Triggered event: receive-inbox-message', payload);
      });
    
      // Executed after the Inbox is updated automatically while the page is loading.
      Pushwoosh.addEventHandler('update-inbox-messages', function (payload) {
        console.log('Triggered event: receive-inbox-messages', payload);
      });
      Pushwoosh.push(['onPutNewMessageToInboxStore', function(api, message) {
        console.log('EVENT: onPutNewMessageToInboxStore', message);
      }]);

      navigator.serviceWorker.addEventListener('message', event => {
        // use `event.data`
        console.log("messageeeeeeeeeeeeeeeeeeeeeee");
      });

      Pushwoosh.addEventHandler('onPushDelivery', function (payload) {
        console.log('message event: onPushDelivery', payload);
      });

      Pushwoosh.addEventHandler('onPutNewMessageToInboxStore', function (payload) {
        console.log('message event: onPutNewMessageToInboxStore', payload);
      });

      Pushwoosh.addEventHandler('onUpdateInboxMessages', function (payload) {
        console.log('message event: onUpdateInboxMessages', payload);
      });

      Pushwoosh.addEventHandler('onNotificationClose', function (payload) {
        console.log('message event: onNotificationClose', payload);
      });

      Pushwoosh.addEventHandler('onPushDelivery', function (payload) {
        console.log('message event: onPushDelivery', payload);
      });

      // const messaging = new AngularFireMessaging();

    });
    
    // this.pwInstance = new Pushwoosh();
    // this.pwInstance.push(['init', {
    //   logLevel: 'info', // or debug
    //   applicationCode: '82D1F-EDDDC',
    //   defaultNotificationTitle: 'Pushwoosh',
    //   defaultNotificationImage: 'https://indietown-web.approd.ca/assets/Indietown_icons/login-logo-white.svg',
    //   autoSubscribe: false,
    //   subscribeWidget: {
    //     enable: true
    //   },
    //   userId: 'user_id',
    //   serviceWorkerUrl: "assets/service-worker.js",
    //   tags: {
    //       'Name': 'John Smith'
    //   },
      
    // }]);

    // this.pwInstance.push(['onLoad', (api) => {
    //   console.log('Pushwoosh load!',api);

    //   // this.pwInstance.subscribe();
      

    //   this.pwInstance.addEventHandler('subscribe', (payload) => {
    //     console.log('Triggered event: subscribe',payload);
    //     this.pwInstance.getPushToken().then((pushToken) => {
    //       console.log('by default pushToken:', pushToken);
    //       if(pushToken){
    //         this.sharedService.updateProfile({deviceToken:pushToken}).subscribe((res:any)=>{
    //           console.log(res);
    //         })
    //       }
    //     });
    //   });

    //   this.pwInstance.isSubscribed().then((isSubscribed) => {
    //     console.log('isSubscribed', isSubscribed);
    //   });

    //   this.pwInstance.getPushToken().then((pushToken) => {
    //     console.log('by default pushToken:', pushToken);
    //     if(pushToken){
    //       this.sharedService.updateProfile({deviceToken:pushToken}).subscribe((res:any)=>{
    //         console.log(res);
    //       })
    //     }
    //   });

    //   this.pwInstance.addEventHandler('permission-granted', function (payload) {
    //     console.log('Triggered event: permission-granted',payload);
    //   });

    
    //   // this.pwInstance.push(['onSubscribe', function(api) {
    //   //   console.log('User successfully subscribed',api);
    //   // }]);

    //   // this.pwInstance.push(['onUnsubscribe', function(api) {
    //   //   console.log('User successfully unsubscribed',api);
    //   // }]);

      

    //   // this.pwInstance.addEventHandler('show-notification-permission-dialog', function (payload) {
    //   //   console.log('Triggered event: show-notification-permission-dialog',payload);
    //   // });

    //   // Executed on hiding the permission dialog with one of three possible statuses: 
    //   // 1. default - the dialog is closed 
    //   // 2. granted - permission is granted 
    //   // 3. denied - permission is denied
    //   // this.pwInstance.addEventHandler('hide-notification-permission-dialog', function (payload) {
    //   //   console.log('Triggered event: hide-notification-permission-dialog', payload);
    //   // });

    //   // this.pwInstance.addEventHandler('permission-default', function (payload) {
    //   //   console.log('Triggered event: permission-default',payload);
    //   // });

    //   // Executed during the SDK initialization if notifications are blocked or once a user blocks push notifications.
  

    //   // Executed during the SDK initialization if notifications are allowed or once a user allows push notifications.
     

    //   // this.pwInstance.addEventHandler('subscribe-popup-show', function() {
    //   //   console.log("Triggered event: subscribe-popup-show");
    //   // })
      
    //   // // Executed when a user clicks on the 'Subscribe' button in the pop-up window
    //   // this.pwInstance.addEventHandler('subscribe-popup-accept', function() {
    //   //   console.log("Triggered event: subscribe-popup-accept");
    //   // })

    //   // // Executed when a user clicks on the 'Ask later' button in the pop-up window
    //   // this.pwInstance.addEventHandler('subscribe-popup-decline', function() {
    //   //   console.log("Triggered event: subscribe-popup-decline");
    //   // })
      
    //   // Executed when the popup hides
    //   // this.pwInstance.addEventHandler('subscribe-popup-hide', function() {
    //   //   console.log("Triggered event: subscribe-popup-hide");
    //   // })

    //   // this.pwInstance.subscribe();

    //   // this.pwInstance.isSubscribed().then((isSubscribed) => {
    //   //   console.log('isSubscribed', isSubscribed);
    //     // this.pwInstance.unsubscribe();
    //     // if(isSubscribed){
    //     //   this.pwInstance.getPushToken().then(function(pushToken) {
    //     //     console.log('after subcribed pushToken:', pushToken);
    //     //     if(pushToken){
    //     //       this.sharedService.updateProfile({deviceToken:pushToken}).subscribe((res:any)=>{
    //     //         console.log(res);
    //     //       })
    //     //     }
    //     //   });
    //     // }
    //     // else{
    //     //   // this.pwInstance.subscribe();

    //     //   console.log("todo");

    //     //   // Notification.requestPermission().then((permission) => {
    //     //   //   // If the user accepts, let's create a notification
    //     //   //   if (permission === "granted") {
    //     //   //     console.log("yes");
    //     //   //     this.pwInstance.getPushToken().then((pushToken) => {
    //     //   //       console.log('after subcribed pushToken:', pushToken);
    //     //   //       if(pushToken){
    //     //   //         this.sharedService.updateProfile({deviceToken:pushToken}).subscribe((res:any)=>{
    //     //   //           console.log(res);
    //     //   //         })
    //     //   //       }
    //     //   //     });
    //     //   //   }
    //     //   // });
    //     // }
        
    //     // this.pwInstance.getHWID().then(function(hwid) {
    //     //   console.log('after hwid:', hwid);
    //     // });
    //     // this.pwInstance.getParams().then(function(params) {
    //     //   params = params || {};
    //     //   var hwid = params.hwid;
    //     //   var pushToken = params.pushToken;
    //     //   var userId = params.userId;
    //     //   console.log(hwid);
    //     //   console.log(pushToken);
    //     //   console.log(userId);
    //     // });
    //   // });

    //   // this.pwInstance.getPushToken().then((pushToken) => {
    //   //   console.log('by default pushToken:', pushToken);
    //   //   if(pushToken){
    //   //     this.sharedService.updateProfile({deviceToken:pushToken}).subscribe((res:any)=>{
    //   //       console.log(res);
    //   //     })
    //   //   }
    //   // });

    //   // this.pwInstance.getHWID().then(function(hwid) {
    //   //   console.log('by default :', hwid);
    //   // });

    // }]);

    // this.pwInstance.push(function (api) {
    //   console.log('Pushwoosh ready!',api);
    // });

  }

  ngOnDestroy(){
    // if(this.currentActiveScreen == 'messages'){
    //   this.messageService.message$.next('');
    //   this.showNewMesageRedDot = false;
    // }
    console.log(1);
    // console.log(this.currentActiveScreen);
    // if(this.currentUrl.includes('messages')){
    //   console.log(2);
    //   this.showNewMesageRedDot = false;
    //   this.messageService.message$.next('');
    // }

    this.msgSubscriptionSideBar.unsubscribe();
    
    
  }

  ngDoCheck(){
  
    // if(window.location.pathname.indexOf('coupon') != -1){
    //   document.getElementById('couponmenu').scrollIntoView();
    // }else if(window.location.pathname.indexOf('coordinates') != -1){
    //   document.getElementById('Coordinates').scrollIntoView();
    // }else if(window.location.pathname.indexOf('notifications') != -1){
    //   document.getElementById('notifications').scrollIntoView();
    // }else if(window.location.pathname.indexOf('event-sponsors') != -1){
    //   document.getElementById('Sponsors').scrollIntoView();
    // }else if(window.location.pathname.indexOf('treasure') != -1){
    //   document.getElementById('Treasure').scrollIntoView();
    // }else if(window.location.pathname.indexOf('more-sections') != -1){
    //   document.getElementById('More-Section').scrollIntoView();
    // }else if(window.location.pathname.indexOf('home-partners') != -1){
    //   document.getElementById('home-partners').scrollIntoView();
    // }
  

  }


  logout() {
    this.modalRef.hide();
    
    this.authService.logout({id:this.currentUser._id}).subscribe((res:any) =>{
      console.log(res.status);
      if(res.status == 200){
        localStorage.removeItem("currentUser");
      }else{
        this.toAsterService.error(res.error.message);
      }
      
    });
    this.router.navigateByUrl("/auth/login");
  }

  // modal lougout confirmation
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  confirm() {
    this.logout();
  }
  cancel() {
    this.modalRef.hide();
  }

  currentActiveScreen = '';
  activateSearchModal(screen){
    console.log("I am mmmm");
    this.currentActiveScreen = screen;
    console.log(this.currentActiveScreen);
    this.shared.sendHeaderData('true');
    localStorage.removeItem('seeAll');
    localStorage.removeItem("setSearchResult");
    if(screen == 'messages'){
      this.showNewMesageRedDot = false;
      // this.messageService.message$.next('');
    }
    if(screen == 'notifications'){
      this.showNotificationRedDot = false;
      // this.messageService.message$.next('');
    }
    if(screen == 'profile'){
      console.log("clicked on profile");
      localStorage.removeItem("selectedProfileTabIndex");
    }
  }

  disabledTab(event:any){
    if (event.keyCode == 9) {   //tab pressed
      event.preventDefault(); // stops its action
   }
  }
  getProfile(){
    this.shared.getProfile().subscribe((res:any) => {
      if(res.type == 'success'){
        this.profileDetails = res.data;

      }
    })
  }
}
