<div id="forgot-password" class="forgetpossward">
    <div class="container-fluid">
        <!-- <div class="logo" routerLink="/">
            <img src="../../../../assets/Indietown_icons/login-logo.svg" alt="logo" >
        </div> -->
        <div class="row">
            <!-- <div class="logo"><img src="../../../../assets/images/bungie_logo_main.svg" alt="logo"></div> -->

            <div class="col-md-12">
                <div class="login-field">
                    <div class="login-box align-items-center d-flex flex-column justify-content-center">
                        <div class="" routerLink="/" style="">
                            <img src="../../../../assets/Indietown_icons/login-logo.svg" alt="logo" >
                        </div>
                        <h3 class="resetpass"><span>Reset Your</span> Password</h3>
                        <p class="mb-3">Enter the email address associated <br>with your account</p>
                        <form [formGroup]="resetForm" (submit)="resetPassword()">
                            <div class="form-group">
                                <!-- <label for="email">Email Address</label> -->
                                <!-- <span class="input-icon"><img src="../../../../assets/icons/email.svg" alt="" style="margin-top: -11px;"></span> -->
                                <input type="email" class="form-control" placeholder="Email Address" id="email" formControlName="email">
                                <div *ngIf="submitted && f.email.errors" class="alert alert-danger">
                                    <span *ngIf="f.email.errors.required">
                                        Email is required.
                                    </span>
                                    <span *ngIf="f.email.errors.email">
                                        Invalid email.
                                    </span>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary" style="background-color: #000000;border: solid 1px #000000;" [disabled]="resetForm.invalid">Send</button>
                                <div class="text-center mt-3">
                                    <div class="backtloginsc">Back to <font style="font-weight: bold;cursor: pointer;"  routerLink="/auth/login">Log in</font></div>
                                </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Forget Password -->
<ng-template #template>
    <div class="modal-header">
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body text-center delete-modal">
        <img src="../../../../../assets/images/create-successfull-img.svg" alt="">
      <!-- <img src="../../../../../assets/images/delete-img.svg" alt="" /> -->
      <!-- <img src="../../../../../assets/icons/reset-password.svg" alt="" style="width: 20%; height:50%"> -->
      <p>password has been sent to <br />your email</p>
      <div class="modal-btn">
        
        <button class="btn btn-cancel btn-primary" (click)="OkPress()" style="background-color: #346ef6;">OK</button>
        <!-- <button class="btn btn-confirm" (click)="updatePassword()">Confirm</button> -->
      </div>
    </div>
  </ng-template>
