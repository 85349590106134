<div id="forgot-password" class="resetpassward">
    <div class="container-fluid">
        <!-- <div class="logo" routerLink="/">
            <img src="../../../../assets/Indietown_icons/Indie Town.svg" alt="logo" >
        </div> -->
        <div class="row">
            <!-- <div class="logo"><img src="../../../../assets/images/bungie_logo_main.svg" alt="logo"></div> -->

            <div class="col-md-12">
                <div class="login-field">
                    <div class="login-box">
                        <img src="../../../../assets/Indietown_icons/check-mail.png" style="width:50%" alt="">
                        <h3>Check Your Mail</h3>
                        <p>A link to reset your password has been sent to your email.</p>
                            <button type="submit" class="btn btn-primary" style="background-color: #000000;border: solid 1px #000000;margin-top: -2%;" routerLink="/auth/login">Login</button>
                                <div class="text-center mt-4">
                                    <div class="fs-16">Didn't receive the email? <font style="font-weight: bold;cursor: pointer;"  (click)="resendPassword()">Resend</font></div>
                                </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Forget Password -->
<ng-template #template>
    <div class="modal-header">
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body text-center delete-modal">
        <img src="../../../../../assets/images/create-successfull-img.svg" alt="">
      <!-- <img src="../../../../../assets/images/delete-img.svg" alt="" /> -->
      <!-- <img src="../../../../../assets/icons/reset-password.svg" alt="" style="width: 20%; height:50%"> -->
      <p>Password sent to your<br />email address</p>
      <div class="modal-btn">
        
        <button class="btn btn-cancel btn-primary" (click)="OkPress()" style="background-color: #346ef6;">OK</button>
        <!-- <button class="btn btn-confirm" (click)="updatePassword()">Confirm</button> -->
      </div>
    </div>
  </ng-template>
