import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class SearchResultService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    deleteInterest(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'category/delete', httpOptions);
    }

    createPost(data:any){
        
    return this.http.post(this.url + 'api/post/create-post', data, {
        reportProgress: true,
        responseType: 'json',
        observe: 'events',
    });
        // return this.http.post(this.url + 'api/post/create-post', data);
    }

    getPosts(page,pageLimit){
        return this.http.get(this.url + `api/post/list?page=${page}&pageSize=${pageLimit}`);
    }

    getProfile(){
        return this.http.get(this.url + 'api/user/profile');
    }


    uploadImage(data:any){
        return this.http.post(this.url + 'api/file/upload', data);
    }

    createShow(data:any){
        return this.http.post(this.url + 'api/show/create', data);
    }

    reportPost(data:any,comment:any){
        return this.http.put(this.url + `api/post/report-post/${data}`,comment);
    }

    createComment(data:any){
        return this.http.post(this.url + 'api/comment/create', data);
    }

    getCommentsList(data:any,page:any,pageLimit:any){
        return this.http.get(this.url + `api/comment/list/${data}?pageNumber=${page}&limit=${pageLimit}`);

        // https://indietown-api.approd.ca/api/comment/list/63d8c4a55bddbc41fd5a5cc4?pageNumber=3&limit=10 like this 
    }

    getBandSuggestions(data:any){
        return this.http.post(this.url + 'api/user/get-bands-suggession', data);
    }

    AddFriend(data:any){
        return this.http.post(this.url + 'api/friend/add-friend', data);
    }

    acceptRequest(data:any){
        return this.http.post(this.url + 'api/friend/accept', data);
    }

    friendRequestList(){
        return this.http.get(this.url + 'api/friend/fr-list');
    }

    acceptRejectRequest(id:any, data:any){
        return this.http.put(this.url + `api/friend/update-friend-request/${id}`,data);
    }

    silentPost(id:any, data:any){
        return this.http.put(this.url + `api/post/silent-post/${id}`,data);
    }

    reportComment(postId:any, commentId:any,comment:any, data:any){
        return this.http.put(this.url + `api/comment/report`,{postId,commentId,comment});
    }

    likePost(id:any,data:any){
        return this.http.put(this.url + `api/post/update/${id}`,data);
    }
    
    updatePost(id:any,data:any){
        return this.http.put(this.url + `api/post/update-post/${id}`,data);
    }

    deletePost(id:any){
        return this.http.delete(this.url + `api/post/delete-user-post/${id}`);
    }

    getLikesList(id:any){
        return this.http.get(this.url + `api/post/post-likes/${id}`);
    }

    deleteComment(id:any){
        return this.http.put(this.url + `api/comment/delete/${id}`,{});
    }
}