import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../services/shared.service';
import { SpinnerVisibilityService } from 'ng-http-loader';

@Component({
  selector: 'app-profile-friends-list',
  templateUrl: './profile-friends-list.component.html',
  styleUrls: ['./profile-friends-list.component.scss']
})
export class ProfileFriendsListComponent implements OnInit {

  friendsList: any = [];
  topFriendsList: any = [];
  modalRef: BsModalRef;
  selectedFriendData: any;
  filterFriendKey: any;
  @Input() userId = '';
  otherFriendsList: any = [];
  friendsCount;
  otherFriendsCount;
  @Input() myProfileId;


  @ViewChild("friendActionsModal") friendActionsModal: TemplateRef<any>;
  @ViewChild("cancelRequests") cancelRequests: TemplateRef<any>;
  @ViewChild("unFriendModal") unFriendModal: TemplateRef<any>;


  constructor(private service: SharedService,
    private toastrService: ToastrService,
    private modalService: BsModalService,
    private router: Router,
    private spinner: SpinnerVisibilityService
  ) { }

  ngOnInit(): void {
    console.log("I am here");;

    if (this.userId) {
      this.getFriendsList(this.userId);
    }
    else {
      this.getTopFriendsList();
      this.getOtherFriendsList();
    }

  }

  getFriendsList(userId) {
    this.service.getFriendsList(userId,this.loadMoreFriendPage,20).subscribe((res: any) => {
      if (res.type == 'success') {
        this.friendsList.push(...res.data);
        if(!res?.data?.length){
          this.loadMoreFriend = false;
        }
        // this.friendsCount = res.data.totalCount;
      } else {
        this.toastrService.error(res.message);
      }
    })
  }

  getOtherFriendsList() {
    this.service.getOtherFriendsList(this.loadMoreOtherFriendPage,20).subscribe((res: any) => {
      if (res.type == 'success') {
        this.otherFriendsList.push(...res.data);
        this.otherFriendsCount = res.totalCount;
        if(!res?.data?.length){
          this.loadMoreOtherFriend = false;
        }
      } else {
        this.toastrService.error(res.message);
      }
    })
  }

  topFriendsCount;
  getTopFriendsList() {
    this.service.getTopFriendsList().subscribe((res: any) => {
      if (res.type == 'success') {
        this.topFriendsList = res.data;
        this.topFriendsCount = res.totalCount;
        //   this.topFriendsList = [
        //     {
        //       "profileImage":{"fileUrl":"https://indietown-dev.s3.amazonaws.com/file_1677648063538.jpg","key":"file_1677648063538.jpg"},
        //       "coverImage":{"fileUrl":"https://indietown-dev.s3.amazonaws.com/file_1677648085268.jpg","key":"file_1677648085268.jpg"},
        //       "_id":"63fee04942cce284d9a694f7","bandName":"Miller"
        //     },
        //     {
        //       "profileImage":{"fileUrl":"https://d1hbsedo7kzk7w.cloudfront.net/file_1681208824115.jfif","key":"file_1681208824115.jfif"},
        //       "coverImage":{"fileUrl":"https://d1hbsedo7kzk7w.cloudfront.net/file_1681208849534.jpg","key":"file_1681208849534.jpg"},
        //       "_id":"64353563be4c9e29bd6f8c14","bandName":"denhy"
        //     },
        //     {
        //       "profileImage":{"fileUrl":"https://indietown-dev.s3.amazonaws.com/file_1677648063538.jpg","key":"file_1677648063538.jpg"},
        //       "coverImage":{"fileUrl":"https://indietown-dev.s3.amazonaws.com/file_1677648085268.jpg","key":"file_1677648085268.jpg"},
        //       "_id":"63fee04942cce284d9a694f7","bandName":"Miller"
        //     },
        //     {
        //       "profileImage":{"fileUrl":"https://d1hbsedo7kzk7w.cloudfront.net/file_1681208824115.jfif","key":"file_1681208824115.jfif"},
        //       "coverImage":{"fileUrl":"https://d1hbsedo7kzk7w.cloudfront.net/file_1681208849534.jpg","key":"file_1681208849534.jpg"},
        //       "_id":"64353563be4c9e29bd6f8c14","bandName":"denhy"
        //     },
        //     {
        //       "profileImage":{"fileUrl":"https://indietown-dev.s3.amazonaws.com/file_1677648063538.jpg","key":"file_1677648063538.jpg"},
        //       "coverImage":{"fileUrl":"https://indietown-dev.s3.amazonaws.com/file_1677648085268.jpg","key":"file_1677648085268.jpg"},
        //       "_id":"63fee04942cce284d9a694f7","bandName":"Miller"
        //     },
        //     {
        //       "profileImage":{"fileUrl":"https://d1hbsedo7kzk7w.cloudfront.net/file_1681208824115.jfif","key":"file_1681208824115.jfif"},
        //       "coverImage":{"fileUrl":"https://d1hbsedo7kzk7w.cloudfront.net/file_1681208849534.jpg","key":"file_1681208849534.jpg"},
        //       "_id":"64353563be4c9e29bd6f8c14","bandName":"denhy"
        //     }
        // ]
      } else {
        this.toastrService.error(res.message);
      }
    })
  }

  selectedfriendType;
  selectedfriendSilenceStatus;
  openActionsModal(data: any, type) {

    console.log("popup coms here");
    this.modalRef = this.modalService.show(this.friendActionsModal, { class: 'friendstabb' });
    this.selectedFriendData = data;
    this.selectedfriendType = type;
    this.getSilencedUserStatus(data._id);
    // if(this.showSiidbar==false){
    //   this.openModal(this.friendClickModal);
    // }else{
    //   // this.route.navigate(['/user/profile'],{queryParams:{id:data?._id}})
    // }
  }
  getSilencedUserStatus(id) {
    this.service.getSilencedUserStatus(id).subscribe((res: any) => {
      console.log(res);
      this.selectedfriendSilenceStatus = res.data.isSilent;
    })
  }

  unMute(id) {
    this.service.unMuteUserPosts({ userId: id }).subscribe((res: any) => {
      if (res.type == 'success') {
        this.selectedfriendSilenceStatus = false;
        // this.modalRef.hide();
      } else {
        this.toastrService.error(res.message);
      }
    })
  }
  unFriendId;
  unFriendName;
  unFriendModalRef;
  openUnfriendModal(id: any,name) {
    this.modalRef.hide();
    this.unFriendId = id;
    this.unFriendName = name;
    this.unFriendModalRef = this.modalService.show(this.unFriendModal);
  }

  unFriend(){
    this.service.unFriend(this.unFriendId, {}).subscribe((res: any) => {
        // debugger;
        this.unFriendModalRef.hide();
        if (res.type == 'success') {
          if (this.selectedfriendType == 'topFriend') {
            let index = this.topFriendsList.findIndex((friend) => {
              return friend._id == this.unFriendId
            });
            this.topFriendsList.splice(index, 1);
          }
          else {
            let index = this.otherFriendsList.findIndex((friend) => {
              return friend._id == this.unFriendId
            });
            this.otherFriendsList.splice(index, 1);
          }
          // this.getFriendsList();
        } else {
          this.toastrService.error(res.message);
        }
      },
      (error) =>{
        console.log("ome error occurred while unfriend");
        this.unFriendModalRef.hide();
      }
    )
  }

  silencePosts(id: any) {
    this.service.silentUserPosts({ userId: id }).subscribe((res: any) => {
      if (res.type == 'success') {
        this.selectedfriendSilenceStatus = true;
        // this.modalRef.hide();
      } else {
        this.toastrService.error(res.message);
      }
    })
  }
  filter = false;
  filterFriendsList(e: any) {
    console.log("filtering ", e);
    this.filterFriendKey = e;
    console.log(this.userId)
    if (e.length >= 2) {
      this.filter = true
      if (!this.userId) {
        this.service.filterFriendsList(this.filterFriendKey, 'top', '').subscribe((res: any) => {
         console.log(res, "res")
          this.topFriendsList = res.data;
          this.topFriendsCount = res.totalCount;
        })
        this.service.filterFriendsList(this.filterFriendKey, 'others', '').subscribe((res: any) => {
          this.otherFriendsList = res.data;
          this.otherFriendsCount = res.totalCount;
        })
      }
      else {
        this.service.filterFriendsList(this.filterFriendKey, 'friends', this.userId).subscribe((res: any) => {
          this.friendsList = res.data;
          this.friendsCount = res.totalCount;
        })
      }
    }else{
      this.filter = false;
      if(this.userId){
        this.loadMoreFriendPage = 1;
        this.getFriendsList(this.userId);
      }
      else{
        this.loadMoreOtherFriendPage = 1;
        this.getTopFriendsList();
        this.getOtherFriendsList();
      }
      
    }
  }

  navigateToProfile(userId, userName) {
    if(this.myProfileId == userId){
      return;
    }
    this.router.navigateByUrl('user/profile?id=' + userId + '&user=' + userName).then(() => {
      window.location.reload();
    });
  }

  addFriend(id, index) {
    this.service.AddFriend({ "recipientId": id }).subscribe((res: any) => {
      if (res.type == 'success') {
        this.toastrService.success("Friend request sent");
        this.friendsList[index].friendStatus = 'REQUEST_SENT';
      }
      // this.spinner.hide();
    }, (err) => {
      this.toastrService.error(err.error.message);
    })
  }
  addFriendButtonText:string = "Add Friend";

  AddFriend(id:any, index){
    let data = document.getElementById('addFriendText'+id).innerText;
    // alert(data);
    if(data=='Pending'){
      // this.spinner.show();
      // document.getElementById('addFriendText'+id).innerText = 'Add Friend'
      // document.getElementById('addFriendText'+id).style.background = 'black';
      // document.getElementById('addFriendText'+id).style.color = 'white';
      this.cancelRequestId = id;
      this.modalRef = this.modalService.show(this.cancelRequests,{class:'cancelrequestmodall'});
      // this.service.cancelRequest({"action":"CANCEL"},id).subscribe((res:any)=>{
      //   if(res.type=='success'){
      //     this.toastrService.success(res.message); 
      //   }
      // })
      // this.spinner.hide();
    }else if(data == 'Add Friend'){
      this.spinner.show();
      document.getElementById('addFriendText'+id).innerText = 'Pending'
      document.getElementById('addFriendText'+id).style.background='#FAFAFA';
      document.getElementById('addFriendText'+id).style.color='black';
      document.getElementById('addFriendText'+id).style.border='1px solid black';
      document.getElementById('addFriendText'+id).style.borderRadius='8px';
      this.service.AddFriend({"recipientId":id}).subscribe((res:any) => {      
        if(res.type == 'success'){
          // this.toastrService.success(res.message); 
          this.toastrService.success("Friend request sent"); 
        }else{
        }
      },(err) => {
        this.toastrService.error(err.error.message);
      })
      this.spinner.hide();
    }else if(data=='Accept'){ 
      this.spinner.show();
      document.getElementById('addFriendText'+id).innerText = 'Friend'; 
      document.getElementById('addFriendText'+id).classList.add("friend");
      document.getElementById('addFriendText'+id).classList.remove("addFriend");

      document.getElementById('addFriendText'+id).classList.remove('btn:enabled');
      // document.getElementById('bandSuggestionList'+id).style.color='black';
      this.service.AcceptFriendRequest({"recipientId": id,"action": "ACCEPT"}).subscribe((res:any) => {
        if(res.type == 'success'){
          // this.toastr.success(res.message);   
          this.toastrService.success("Friend request accepted");
          // debugger;
          // let index = this.moreData.findIndex((obj)=>{
          //   return obj._id == id
          // });
          // if(index != -1){
          //   this.moreData[index].friendStatus = "FRIEND";
          // }
        }else{
          this.toastrService.error(res.message);   
        }
      },(err) => {
        this.toastrService.error(err.error.message);
      })
      this.spinner.hide();
    }
  
}
  cancelRequest(id, index) {
    this.service.cancelRequest(id, { "action": "CANCEL" }).subscribe((res: any) => {
      if (res.type == 'success') {
        this.toastrService.success("Cancelled friend request");
        this.friendsList[index].friendStatus = 'NOT_FRIEND';
      }
    })
  }
  cancelRequestId;
cancelRequestModalRef;

showCancelRequestLoader: Boolean = false;
cancelFriendRequest(){
  this.showCancelRequestLoader = true;
  let id = this.cancelRequestId;
  
  
  this.service.cancelRequest(id,{"action":"CANCEL"}).subscribe((res:any)=>{
    this.showCancelRequestLoader = false;
    if(res.type=='success'){
      this.toastrService.success("Cancelled friend request");   
      document.getElementById('addFriendText'+id).innerText = 'Add Friend'
      document.getElementById('addFriendText'+id).style.background = 'black';
      document.getElementById('addFriendText'+id).style.color = 'white';
      document.getElementById('addFriendText'+id).style.borderRadius='15px';
    }
    else{
      this.toastrService.info("please try after some time!"); 
    }
    this.modalRef?.hide();
  },err => {
    this.showCancelRequestLoader = false;
    this.toastrService.info("please try after some time!"); 
    this.modalRef?.hide();
  })
}

  message(id) {
    this.router.navigateByUrl('/messages');
  }

  loadMoreFriend: Boolean = true;
  loadMoreFriendPage = 1;


  loadMoreFriends(e){
    console.log("scrolled fired");
    if(this.loadMoreFriend){
      console.log("loading more")
      this.loadMoreFriendPage++;
      // this.getMoreFriendsList();
      this.getFriendsList(this.userId);
    }
  }
  loadMoreOtherFriend = true;
  loadMoreOtherFriendPage = 1;
  loadMoreOtherFriends(e){
    console.log("scrolled fired other friends");
    if(this.loadMoreOtherFriend){
      console.log("loading more")
      this.loadMoreOtherFriendPage++;
      this.getOtherFriendsList();
    }
  }

  getMoreFriendsList(){
    this.service.getBandcampList(this.userId,this.loadMoreFriendPage,20).subscribe((res:any)=>{
      console.log(res);
      if(res.data.length){
        this.friendsList.push(...res.data);
      }
      else{
        this.loadMoreFriend = false;
      }
    })
  }

  navigateToMessages(data){
    // routerLink="/messages"
    this.modalRef?.hide();
    localStorage.setItem("cameFromProfile",JSON.stringify(data));
    this.router.navigateByUrl('/messages');
  }

}
