/**
  * Usage: dateString | localDate:'format'
 **/

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterFriends'
})
export class FriendsFilterPipe implements PipeTransform {

    constructor() { }

    transform(items: any[], callback:any): any {
        if (!items || !callback) {
            return items;
        }
        return items.filter(item => {
            if(item.bandName){
                return item.bandName.toLowerCase().includes(callback.toLowerCase());
            }
            else if(item.userId?.bandName){
                return item.userId.bandName.toLowerCase().includes(callback.toLowerCase());
            }
            
        });
    }
}