<div style="margin-top: 20px;" *ngIf="playList.length">
    <div class="d-flex justify-content-between" style="width: 100%; padding: 10px;     padding-inline: 0px;">
            <div>
                <span><img style="    margin-right: 7px;" src="../../../assets/Indietown_icons/youtube-input-icon.svg" height="35" width="35" alt=""></span>
                <span class="musictitle">YouTube</span>
            </div>
            <div style="padding-top: 2%;" *ngIf="playList.length>3">
                <span class="seealltext" (click)="navigateToYoutube()">See all</span>
            </div>
    </div>

    <div class="mt-2" [ngClass]="{'youtubecardh' : playList?.length>0}">
            <div class="youtube-sm-card" *ngFor="let i of playList | slice:0:3" >
                <!-- (click)="openYoutubeModal(i)" -->
                <div class="row" (click)="navigateToYoutube()">
                        <div class="col-lg-5 d-flex align-items-center justify-content-center">
                            <img [src]="i?.imageUrl" alt="" height="86" width="110" class="card-img-top" style="border-radius: 10px;">
                        </div>
                        <div class="col-lg-7 d-flex align-items-center justify-content-center p-0">
                            <div>
                                <p class="musicsubtitle">{{i?.name}}</p>
                                <p></p>
                            </div>
                        </div>
                </div>  
            </div>
            
    </div>
    <h1 *ngIf="playList?.length<=0"style="text-align: center; display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 17%;">No Data Found</h1>
</div>
