<!-- <div class="header"> -->
    <!-- <div class="left-sec">
        <ng-container *ngIf="welcomeUserMsg"><h1>Dashboard</h1></ng-container>
        <h1 *ngIf="manageCaregiversHeading">Manage Caregivers / Companion</h1>
        <h1 *ngIf="manageClientHeading">Manage Clients</h1>
        <h1 *ngIf="manageServicesHeading">Manage Services</h1>
        <h1 *ngIf="manageTutorialHeading">Manage Tutorial</h1>
        <h1 *ngIf="manageConvenienceHeading">Manage Convenience Fee</h1>
        <h1 *ngIf="manageDiseasesHeading">Manage Diseases</h1>
        <h1 *ngIf="dashboardHeading">Dashboard</h1>
        <h1 *ngIf="changePasswordHeading">Change Password</h1>
    </div> -->


    <!-- <div class="right-sec">
        <div class="userName">{{userName}}</div>
        <div class="btn-group" dropdown placement="bottom right"> -->
            <!-- <button id="button-alignment" dropdownToggle type="button" class="dropdown-toggle"
                aria-controls="dropdown-alignment">
                {{userName}}
            </button> -->
            
            <!-- <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                aria-labelledby="button-alignment">
                <li role="menuitem"><a class="dropdown-item admin-dropdown"
                        routerLink="/edit-profile">Change Password</a></li>
                <li style="cursor: pointer;" role="menuitem"><a class="dropdown-item admin-dropdown"
                        (click)="openModal(template)">Logout</a></li>
            </ul> -->
        <!-- </div>

    </div> -->
<!-- </div> -->

<!-- <form #f="ngForm" id="openModalField">
  <div class="search-form searchPost-form">
    <div class="form-group">
      <span>
        <img src="../../../../../assets/icons//search-icon.svg" alt="" />
      </span>
      <input
        class="form-control"
        id="search"
        type="text"
        placeholder="Search"
        aria-label="Search"
        (click)="openSearchModal()"
        style="padding-left: 60px;caret-color: transparent;"
        autocomplete="off"
      />
    </div>
  </div>
</form> -->


<form #f="ngForm" id="searchLocation" style="display: none; justify-content: center; align-items: center;display: none;">
    <div class="search-form searchPost-form headerseaech" style="align-items: center;
    margin: auto;
    display: flex;
    justify-content: center;
    width: 700px;  right: unset !important;">
        <!-- <input style="background: rgba(233, 233, 235, 0.3); 
        border-radius: 15px !important;
        border: 1px solid #E9E9EB;
        width: 614px;
        color: #495057;" id="pac-input-header" placeholder="Location" (keyup)="checkEmpty($event)" (keypress)="CheckEnterPress($event)" class="controls form-control firstPage" type="text"/> -->
        <!-- <div id="map-header" class="mt-3" style="height: 50px" hidden></div> -->
       
    </div>
</form>

<!-- Logout fonfirm -->
<ng-template #template>
  
    <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true"><img style="filter: brightness(0);
             width: 39px;
    height: 29px;
              margin-top: -2px;" src="../../../assets/Indietown_icons/close.svg" alt=""></span>
        </button>
    </div>
    <div class="modal-body text-center delete-modal logoutpopupp align-items-center">
        <!-- <img src="../../../../../assets/images/create-successfull-img.svg" alt=""> -->
        <p style="margin-top: 10px;" class="logouttxt">Are you sure want to logout?</p>
        <div class="modal-btn d-flex" style="margin-top: 39px;">
            <button class="btn btn-blue logoutbtn" (click)="confirm()" style="background-color: #E9E9EB !important;color: black !important;">Yes</button>
            <button class="btn btn-red nobtn" style="margin-left: 15px;" (click)="cancel()">No</button>
        </div>
    </div>
</ng-template>

<!-- Logout fonfirm -->
<!-- <ng-template #searchDataModal>
    <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" style="display: none;" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body text-center delete-modal align-items-center" id="headersearchpopup">
        <div class="modal-btn d-flex" style="margin-top: 39px;">
            <div style="display: flex; justify-content: center;align-items: center;">
                <form action="#" [formGroup]="searchForm">
                  <div class="text-center">
                    <mat-slider formControlName="slider" thumbLabel
                      [displayWith]="formatLabel" tickInterval="1000" step="1" min="0" max="200" aria-label="units">
                    </mat-slider>
                  </div>
            
                  <div class="d-flex justify-content-center">
                    <div class="form-group">
                      <input class="form-control" formControlName="gener" placeholder="Genre/Title" id="genre" 
                       (keypress)="CheckEnterPress($event)"/>
                    </div>
                    <div class="form-group">
                      <input class="form-control" formControlName="name" placeholder="Name" id="name"
                         style="margin-left: 2%"(keypress)="CheckEnterPress($event)"/>
                        <input type="hidden" id="abcd">
                    </div>
                  </div>
                </form>
              </div>
        </div>
    
    <button class="btn btn-primary" [disabled]="searchForm.invalid" style="width:520px;height:70px;border-radius: 15px;" (click)="searchData()">Search</button>
    <div class="mt-3 cardSliders" style="height: 260px;overflow: auto;">
        <div  style="margin-top: 26px !important;
        padding-left: 8px;
        display: block;
        text-align: left;">
            <label for="" *ngIf="!search" class="label-text">Suggestions</label>
            <label for="" *ngIf="search" class="label-text">Search Result</label>
        </div>
        <div *ngFor="let res of searchList" style="width: 100% !important;height: 80px;cursor: pointer;" class="m-2">
                <mat-card class="d-flex justify-content-between" routerLink="/view-profile" (click)="modalRef.hide()" [queryParams]="{ id: res?._id }">
                    <mat-card-header>
                        <img mat-card-avatar [src]="res?.profileImage?.fileUrl ? res?.profileImage?.fileUrl : '../../../assets/Indietown_icons/upload-profile-pic.png'" alt="">
                      <mat-card-title>{{res?.bandName | titlecase}}</mat-card-title>
                      <mat-card-subtitle>
                        <label for="" *ngIf="res?.communities?.length>0">{{res?.communities[0]?.communityName}} <span  *ngIf="res?.communities?.length>=2">|</span> {{res?.communities[1]?.communityName}}</label>
                        <label for="" *ngIf="res?.generes?.length>0">{{res?.generes[0]?.genereName}} <span *ngIf="res?.generes?.length>=2">|</span> {{res?.generes[1]?.genereName}}</label>
                      </mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-actions>
                      <button class="btn btn-dark" (click)="addFriend(res?._id)" id="bandSuggestionList{{res?._id}}">Add Friend</button>
                    </mat-card-actions>
                </mat-card>
        </div>
    </div>

    <p *ngIf="searchClick" (click)="seeAll()" style="padding-top: 20px;cursor: pointer;">See all results</p>
    <p *ngIf="searchList.length<=0">No Result Found</p>

    </div>
</ng-template> -->


<div class="modal" id="searchModalDialog" aria-hidden="true" aria-labelledby="">
    <div class="modal-dialog">
        <div class="modal-content">
          
          <div class="modal-header" style="padding: 0;">
            <button type="button" class="close pull-right" aria-label="Close" (click)="closeSearchModal()">
                <span aria-hidden="true"><img style="filter: brightness(0);
                  width: 20px;
                  height: 20px;
                  margin-top: -2px;" src="../../../assets/Indietown_icons/close.svg" alt=""></span>
            </button>
        </div>

        <form #f="ngForm" id="searchLocation" style=" justify-content: center; align-items: center;">
    
         <div class="search-form searchPost-form headerseaech" style="align-items: center;
          margin: auto;
          display: flex;
          justify-content: center;
          width: 700px;  right: unset !important;margin-top: 115px; margin-left: -20px;">
          
              <input style="background: rgba(233, 233, 235, 0.3); 
              border-radius: 15px !important;
              border: 1px solid #E9E9EB;
              width: 614px;
              color: #495057;" autocomplete="off" id="pac-input-header" placeholder="Location" (keyup)="checkEmpty($event)" (keypress)="CheckEnterPress($event)" class="controls form-control firstPage" type="text"/>
              <!-- <div id="map-header" class="mt-3" style="height: 50px" hidden></div> -->
              <!-- <datalist id="programmingLanguages"> 
                <option value="Objective C">Objective C</option> 
              </datalist>  -->
              <!-- <datalist id="programmingLanguages" (click)="clickHandler2($event)"> 
                <option value="Objective C">Objective C</option> 
              </datalist> -->
              
          </div>
          <ul id="programmingLanguages" style="list-style: none;
          margin-top: 78px;
          position: fixed;
          font-size: 14px;
          margin-bottom: 52px;
          z-index: 99999;
          background-color: white;"></ul>
        </form>
        <div class="modal-btn d-flex" style="margin-top: 120px; padding-bottom: 5px;">

          
            <div style="display: flex; justify-content: center;align-items: center;">
              

                <form action="#" [formGroup]="searchForm">
                  <div class="text-center">
                    <span class="radiustxt">Radius</span>
                    <mat-slider (valueChange)="getSuggestionsForRadiusChange()" formControlName="slider" thumbLabel [disabled]="disabledSlider"
                      [displayWith]="formatLabel" tickInterval="1000" step="1" min="0" max="200" aria-label="units">
                    </mat-slider>
                    <div class="milestxt">0 Miles</div>
                  </div>
            
                  <div class="d-flex justify-content-center mt-4">
                    <div class="form-group">
                      <input
                              class="form-control pl-20  w-282"
                              formControlName="gener"
                              placeholder="Genre/Community"
                              id="genre"
                              style="border-radius: 15px !important;"
                              (keyup)="getGenerSuggestions($event)"
                              (keypress)="CheckEnterPress($event)"
                              matInput
                              [matAutocomplete]="auto"
                            />
                            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                              <mat-option *ngFor="let suggestion of generSuggestions" [value]="suggestion.genereName?suggestion.genereName:suggestion.communityName" (click)="onChangeGenerSearch(suggestion.genereName?suggestion.genereName:suggestion.communityName)">
                                {{suggestion.genereName?suggestion.genereName:suggestion.communityName}}
                              </mat-option>
                            </mat-autocomplete>
                      <!-- <input class="form-control" formControlName="gener" placeholder="Genre/Title" id="genre" 
                       (keypress)="CheckEnterPress($event)" (keyup)="checkEmpty($event)"/> -->
                    </div>
                    <div class="form-group">
                      <input class="form-control" formControlName="name" placeholder="Name" id="name"
                         style="margin-left: 20px"(keypress)="CheckEnterPress($event)" (keyup)="checkEmptyForName($event)"/>
                        <input type="hidden" id="abcd">
                    </div>
                  </div>
                </form>
              </div>
        </div>
    
    <!-- <button class="btn btn-primary searchBtn" [disabled]="searchForm.value.gener=='' && latitube==false &&  searchForm.value.name.length<=1" style="width:520px;height:60px;border-radius: 15px; margin: 0 auto; margin-top: 20px;" (click)="searchData()">Search</button> -->
    <div  style="margin-top: 26px !important;
    padding-left: 8px;
    display: block;
    text-align: left;">
        <!-- <label for="" *ngIf="!search" class="label-text">Suggestions</label> -->
        <label for="" *ngIf="!search" class="label-text">Suggested Friends</label>
        <label for="" *ngIf="search" class="label-text">Search Results</label>
    </div>
    <div class="cardSliders" style="height: 315px;overflow: auto; overflow-x: hidden;">
       
        <div *ngFor="let res of searchList;let index=index" style="width: 100% !important;height: 100px; margin-inline: 0 !important;" class="m-2 borderapply">
                <mat-card class="d-flex justify-content-between">
                    <mat-card-header>
                      <!-- routerLink="/user/profile" [queryParams]="{ id: res?._id,user: res?.bandName }"-->
                        <img mat-card-avatar style="cursor: pointer;" (click)="navigateToUserProfile(res?._id, res?.bandName)" [src]="res?.profileImage?.fileUrl ? res?.profileImage?.fileUrl : '../../../assets/Indietown_icons/upload-profile-pic.png'" alt="">
                      <mat-card-title style="font-size: 16px; cursor: pointer;">{{res?.bandName | titlecase}}</mat-card-title>
                      <mat-card-subtitle>
                        <!-- <label style="font-size: 12px;" for="" *ngIf="res?.communities?.length>0">{{res?.communities[0]?.communityName}} <span  *ngIf="res?.communities?.length>=2">|</span> {{res?.communities[1]?.communityName}}</label>
                        <label style="font-size: 12px;" for="" *ngIf="res?.generes?.length>0">{{res?.generes[0]?.genereName}} <span *ngIf="res?.generes?.length>=2">|</span> {{res?.generes[1]?.genereName}}</label> -->
                        <mat-card-subtitle>{{res?.communityIds.length>0 ? ((res?.communityIds[0]?.communityName != null ? res?.communityIds[0]?.communityName : '') + (res?.communityIds[1]?.communityName!=null ?  " | " +res?.communityIds[1]?.communityName : '')): ((res?.genereIds[0]?.genereName!=null ? res?.genereIds[0]?.genereName : '') +  (res?.genereIds[1]?.genereName!=null ? " | " +res?.genereIds[1]?.genereName : '') + (res?.genereIds[2]?.genereName ? " | " +res?.genereIds[2]?.genereName :
                          ''))}}</mat-card-subtitle>
                      </mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-actions style="display: flex;
                    justify-content: center;
                    align-items: center;">
                      <button style="border-radius: 8px; height: 31px !important;padding: 0 10px !important;" class="btn " [ngClass]="{'requestSent' : (res?.friendStatus =='REQUEST_SENT') || (res?.friendStatus =='ACCEPT_REQUEST'), 'requestNotSent' : res?.friendStatus =='NOT_FRIEND','friend':res?.friendStatus=='FRIEND'}"
                      (click)="addFriend(res?._id)" id="bandSuggestionList{{res?._id}}">
                         <!-- {{res?.friendStatus =='REQUEST_SENT' ? 'Cancel request' : 'Add Friend'}} -->
                         {{res?.friendStatus =='REQUEST_SENT' ?  'Pending' : res?.friendStatus == 'NOT_FRIEND'?'Add Friend' : res?.friendStatus == 'ACCEPT_REQUEST' ? 'Accept' : 'Friend'}}

                         
                      </button>
                    </mat-card-actions>
                </mat-card>
        </div>
        <div style="display: flex;justify-content: center;padding-top: 20px;">
            <p *ngIf="moreData?.length>=10" (click)="seeAll()" style="padding-top: 20px;cursor: pointer;">See all results</p>
            <!-- <p *ngIf="searchList.length<=0" style="font-size: 24px;">No Result Found</p> -->
            <p *ngIf="searchList.length<=0" style="font-size: 24px;">No Results Found</p>
        </div>
    </div>
    </div>
</div>
  </div>




  <div class="mobileheadernav" style="position: fixed; bottom: 0;">
    <span class="paddinli"><img src="../../../assets/Indietown_icons/home.png" alt="" routerLink="/home" style="transform: translate(64%, 0px); filter: brightness(0) invert(1);
      "><br><span>Home</span></span>
    <span class="paddinli"><img src="../../../assets/Indietown_icons/Chat.png" alt="" routerLink="/messages" style="transform: translate(106%, 0px); filter: brightness(0) invert(1);
      "><br><span>Messages</span></span>
    <span class="paddinli"><img src="../../../assets/Indietown_icons/Notification.png" routerLink="/notifications" style="transform: translate(120%, 0px); filter: brightness(0) invert(1);
      " alt=""><br><span>Notifications</span></span>
    <span class="paddinli"><img style="width:24px; height: 24px; transform: translate(46%, 0px);    border-radius: 50%;
      " [src]="profileDetails?.profileImage?.fileUrl?profileDetails?.profileImage?.fileUrl:'../../../../assets/Indietown_icons/upload-profile-pic.png'" alt="" routerLink="/profile"><br><span>Profile</span></span>
    <!-- <div class="btn-group" dropdown placement="bottom right">
      <div id="button-alignment" class="menubuttonn" dropdownToggle type="button"
          aria-controls="dropdown-alignment">
          <span class="first"></span>
          <span class="second"></span>
          <span class="last"></span>
          <img src="../../../assets/Indietown_icons/header-left-icon.png" alt="" style="cursor: pointer;">
  </div>                    
  <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right headerdropdown" role="menu"
      aria-labelledby="button-alignment" style="background: #f8f9f8;
      box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
      border-radius: 15px;
      ">
      <li role="menuitem"><span class="dropdown-item admin-dropdown"><span><img class="icons" src="../../../assets/Indietown_icons/profile-dropdown.png" alt=""> </span> Profile</span></li>
      <li role="menuitem" routerLink="/settings"><span class="dropdown-item admin-dropdown"><span><img class="icons" style="margin-right: 10px;width: 20px; height: 20px;" src="../../../assets/Indietown_icons/seetings.png" alt=""> </span> Settings</span></li>
      <div class="dropdown-divider"></div>
      <li style="cursor: pointer;" role="menuitem" (click)="openModal(template)" ><span class="dropdown-item admin-dropdown"><span><img style="margin-right: 10px;width: 20px; height: 20px" class="icons" src="../../../assets/Indietown_icons/logout.png" alt=""> </span> Log Out</span></li>
  </ul>

  </div> -->
   </div>
<div class="header-top" id="headertopbar">
  
   
    <div class="header-icons" id="abc">
           


            <span>
              <form #f="ngForm" id="openModalField">
                <div class="search-form searchPost-form" style="right: unset !important;
                    margin-left: -74.5%;">
                  <div class="form-group">
                    <span style="padding-left: 0px !important;">
                      <img style="position: absolute;margin-top: 0px;
                      margin-left: 4px;" src="../../../../../assets/Indietown_icons/search-icon1.svg" alt="" />
                    </span>
                    <input
                      class="form-control"
                      id="search"
                      type="text"
                      readonly
                      placeholder="Search"
                      aria-label="Search"
                      (click)="openSearchModal()"
                      style="padding-left: 60px; width: 614px; cursor: pointer;"
                    />
                  </div>
                </div>
              </form>
                <!-- <div class="btn-group" dropdown placement="bottom right">
                    <div id="button-alignment" class="menubuttonn" dropdownToggle type="button"
                        aria-controls="dropdown-alignment" style="height: 19.95px; z-index: 9999;cursor: pointer;">
                        <span class="first"></span>
                        <span class="second"></span>
                        <span class="last"></span>
                </div>                    
                <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right headerdropdown" role="menu"
                    aria-labelledby="button-alignment" style="background: #f8f9f8;
                    box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
                    border-radius: 15px;
                    ">
                    <li role="menuitem" routerLink="/settings"><span class="dropdown-item admin-dropdown"><span><img class="icons" style="margin-right: 10px;width: 20px; height: 20px;" src="../../../assets/Indietown_icons/seetings.png" alt=""> </span> Settings</span></li>
                    <div class="dropdown-divider"></div>
                    <li style="cursor: pointer;" role="menuitem" (click)="openModal(template)" ><span class="dropdown-item admin-dropdown"><span><img style="margin-right: 10px;width: 20px; height: 20px" class="icons" src="../../../assets/Indietown_icons/logout.png" alt=""> </span> Log Out</span></li>
                </ul>

                </div> -->
            </span>
    </div>
</div>
<!-- <li role="menuitem"><span class="dropdown-item admin-dropdown"><span><img class="icons" src="../../../assets/Indietown_icons/profile-dropdown.png" alt=""> </span> Profile</span></li> -->
<!-- <img src="../../../assets/Indietown_icons/header-left-icon.png" alt="" style="cursor: pointer;"> -->
<ng-template #cancelRequest>
  <div class="modal-header">
    <button type="button" style="border: 1px solid rgba(15, 13, 15, 0.2) !important;
      box-shadow: 0px 14px 34px rgb(0 0 0 / 5%);" class="close pull-right" aria-label="Close"
      (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center delete-modal align-items-center">
    <p style="margin-top: 0px;font-size: 22px;font-weight: 500;line-height: 40px;">Are you sure want to Cancel Friend Request?</p>
    <div class="modal-btn d-flex justify-content-around" style="width: 90%;
    margin: auto;
    margin-top: 39px;">
      <button class="btn btn-default" (click)="clickCancelPostYes()" style="color: black !important; font-weight: bold;">Yes</button>

      <button class="btn btn-red" (click)="modalRef.hide()" style="background-color: black !important;font-weight: bold">No</button>
    </div>
  </div>
</ng-template>