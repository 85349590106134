<!-- <div id="forgot-password">
  <div class="container-fluid">
    <div class="d-flex logoimg">
      <div class="logo">
        <img
          src="../../../../assets/Indietown_icons/login-logo.svg"
          alt="logo"
        />
      </div>
    </div>
  </div>
</div> -->

<form action="" [formGroup]="cityForm">
<div class="centerplacement" style="display: flex;
height: 100%;
align-items: center;
justify-content: center; ">
        <div class="updatecitysec" style="background: #FFFFFF;
        border: 1px solid rgba(15, 13, 15, 0.05);
        box-shadow: 0px 14px 34px rgb(0 0 0 / 5%);
        border-radius: 20px;
        height: auto;
        padding: 33px 47px;
        padding-bottom: 110px;
        display: flex;
    margin-top: 46px;
        flex-direction: column;">
          <div class="text-center welcomeText" style="margin-top: 40px;
          margin-bottom: 80px;">
            <h1>Update City</h1>
          </div>

          <!-- <div class="form-group mb-0">
            <label for="email" style="margin: 15px 0" class="artistname">Artist Name</label>
            <input
            style="margin-bottom: 10px;"
            [ngClass]="{'redbordercolor':invalidName==true,'dynamic':cityForm.value.bandName!=''}"
              class="form-control firstPage"
              formControlName="bandName"
              placeholder="Artist Name"
              id="email"
              type="text"
              style="padding-left: 20px;"
              (keypress)="CheckEnterPress($event)"
            />
            <div class="alert alert-danger" *ngIf="invalidName">
              <span>
                  {{nameError}}
              </span>
            </div>
          </div> -->

          <div class="form-group m-0">
            <label for="pac-input" style="margin: 15px 0" class="where-based"
              >Where Are You Based?</label
            >
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group mr-2" id="located">
                  <input
                    id="pac-input123"
                    formControlName="city"
                    placeholder="City"
                    (keyup)="removeCity($event.target.value)"
                    (keypress)="CheckEnterPress($event)"
                    [ngClass]="{'dynamic':cityForm.value.city!=''}"
                    class="controls form-control firstPage"
                    type="text"
                    style="padding-left: 20px;"
                    placeholder="City"
                  />

                  <div id="map1234" class="mt-3" style="height: 50px; margin-top: 20px;" hidden></div>
                </div>
                <ul id="programmingLanguages2" style="list-style: none;
                margin-top: -14px;
                position: fixed;
                font-size: 14px;
                margin-bottom: 52px;
                z-index: 99999;
                background-color: white;"></ul>
              </div>

              <div class="col-lg-6">
                <div class="form-group">
                  <input
                  style="border:1px solid #0000001a !important"
                    class="form-control firstPage"
                    readonly
                    formControlName="state"
                    placeholder="State"
                    id="state"
                    style="padding-left: 20px;"
                    type="text"
                    (keypress)="CheckEnterPress($event)"
                  />
                  <input type="hidden" id="abcd" />
                </div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <input
              class="form-control firstPage"
              formControlName="country"
              style="border:1px solid #0000001a !important"
              readonly
              placeholder="Country"
              id="country"
              style="padding-left: 20px;"
              type="text"
              (keypress)="CheckEnterPress($event)"
            />
          </div>
          <!-- <div class="field nextBtn" style="transform: translate(0px, 29px);">
            <button class="btn btn-primary" id="nameAndAddressNext" (click)="updateName()" [disabled]="cityForm.invalid || cityForm.value.bandName.trim().length<2"> Update</button>
          </div> -->
          <div class="field nextBtn" style="transform: translate(0px, 29px);">
            <button class="btn btn-primary" id="nameAndAddressNext" (click)="updateName()" [disabled]="cityForm.invalid"> Update</button>
          </div>
        </div>
</div>
</form>

