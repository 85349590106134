import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SpinnerVisibilityService } from "ng-http-loader";
import { ToastrService } from "ngx-toastr";
import { SharedService } from "../services/shared.service";
import * as $ from 'jquery';
@Component({
  selector: "app-youtube-card",
  templateUrl: "./youtube-cards.component.html",
  styleUrls: ["./youtube-cards.component.scss"],
})
export class YoutubeCardsComponent implements OnInit {
  youtubePlayData:any;
  selectedIndex:any;
  // playList:any=[];

  constructor(
    public router: Router,
    private service: SharedService,
    private toastrService: ToastrService,
    private spinner: SpinnerVisibilityService
  ) {}

  // @Input() goYoutube: any;
  // @Input() playList: any=[];
  // @Input() playlistModelOpen: any;

  playList = [];

  @Input() userId;

  @Output() gotoYoutube = new EventEmitter();

  ngOnInit(): void {
    this.getPlayList();
    var youTubeURL = 'http://gdata.youtube.com/feeds/api/videos/EEcldrVuiYU?v=2&alt=json';
    var json = (function () {
        $.ajax({
            'async': false,
            'global': false,
            'url': youTubeURL,
            'dataType': "jsonp", // necessary for IE9
            crossDomain: true,
            'success': function (data) {
                var duration = data.entry.media$group.yt$duration.seconds;
                alert(duration);
            }
        });
    })();
  }

  getPlayList(){
    this.service.getYoutubeList(this.userId,1,20).subscribe((res: any) => {

      if(res.data && res.data.length){
        this.playList = res?.data;
      }
    })
    // this.spinner.show();
    // this.service.getPlayList().subscribe((res:any)=> {
    //     if(res.type == 'success'){
    //       this.playList = res.data;
    //     }else{
    //       this.toastrService.error(res.message);
    //     }
    // })
    // this.spinner.hide();
  }

  navigateToYoutube(){
    this.gotoYoutube.emit();
  }

  openYoutubeModal(data:any){
    this.service.playYoutube(data);
  }

  // gotoYoutube(){
  //   this.goYoutube = 4;
  //   this.service.selectYoutube(4);
  // }

  getImage(id){
    let src = "https://img.youtube.com/vi/" +id +"/0.jpg";
    return src
  }

  getDuration(id:any){
    // let du = (<HTMLInputElement>document.getElementById('first')).duration;
  }
  
}
