import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { error } from 'src/app/_helpers/error';
import { User } from '../../models/user.model';
import { AuthService } from '../../services/auth.service';
import { FormBuilder } from '@angular/forms';
import { compileDeclareClassMetadata } from '@angular/compiler';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  submitted: boolean = false;
  error = error.err;
  loginForm:FormGroup;
  isMobile: boolean = false;
  // ***********************
  hide = true;
  hide1 = true;
  hide2 = true;
  // *********************
// Slider
slides = [
  // {image: '../../../../assets/sliders/indieslider1.jpg', text: 'First'},
  // // {image: '../../../../assets/sliders/indieslider2.jpg',text: 'Second'},
  // {image: '../../../../assets/sliders/indieslider4.png',text: 'Second', id:'indielastimg'},

  // {image: '../../../../assets/sliders/indieslider3.jpg',text: 'Third', id:'indielastimg'},
  // {image: '../../../../assets/sliders/Slider-01.jpg', text: 'First'},
  // {image: '../../../../assets/sliders/Slider-02.jpg',text: 'Second', id:'indielastimg'},
  // {image: '../../../../assets/sliders/Slider-03.jpg',text: 'Third', id:'indielastimg'},

  {image: '../../../../assets/sliders/indieslider1.jpg', text: 'First'},
  {image: '../../../../assets/sliders/Slider-03.jpg',text: 'Second', id:'indielastimg'},
  {image: '../../../../assets/sliders/Slider-01.jpg',text: 'Third', id:'indielastimg'}
];
noWrapSlides = false;
showIndicator = true;
  userType: any = 1;

  check: number = 1;
  changed: boolean = false;
  passChangeMsg:boolean = false;
  emailFocusOut: boolean = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService,
    private fb: FormBuilder
  ) {
    if(screen.width < 640) {
      // true for mobile device
      this.isMobile = true;
    } else {
      // false for not mobile device
      this.isMobile = false;
    }
   }



  receiveEmail:any;

  ngOnInit() {
    this.initForm();
    // let a = localStorage.getItem('receiveEmail');
    // let b = a?.slice(1, a.length-1);
    // this.loginForm.get("email").patchValue(b);   
    // localStorage.removeItem('receiveEmail'); 
if(localStorage.getItem('mail') != null || localStorage.getItem('pass') != null || localStorage.getItem('confmPass')){
  this.signUpForm.get('email').patchValue(localStorage.getItem('mail'));
  this.signUpForm.get('password').patchValue(localStorage.getItem('pass'));
  this.signUpForm.get('confirmPassword').patchValue(localStorage.getItem('confmPass'));
}
    

   
    let term = localStorage.getItem('term');
    if(term =='true'){
      this.signUpForm.get('term').patchValue(true);
    }

    localStorage.clear();
    
   }

  get f() {
    return this.loginForm.controls;
  }

  initForm(){
    this.loginForm = this.fb.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
      ]),
    });
  }

  get p() {
    return this.signUpForm.controls;
  }

  errorMessage:any;

  onAdminSelection(event: any) {
    console.log('admin', event);
    if (event.target.checked == true) {
      this.userType = 1;
      this.check = 1;
    }
  }


  passwordRegex = /(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%&*~`^\(\)_+=\{\[\}\]\|\:;"'\\<,>\/.\?-])[\w !@#$%&*~`^\(\)_+=\{\[\}\]\|\:;"'\\<,>\/.\?-]/;
  emailreg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  // Validators.maxLength(10)
  // , Validators.maxLength(10)
  signUpForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email,Validators.pattern(this.emailreg)]),
    password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    confirmPassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
    term: new FormControl('',Validators.requiredTrue)

    // term: new FormControl('')
  })


  onSupportSelection(event: any) {
    if (event.target.checked == true) {
      this.userType = 5;
      this.check = 2;
    }
  }

  onZoneSelection(event: any) {
    if (event.target.checked == true) {
      this.userType = 6;
      this.check = 3;
    }
  }

  match: boolean = true;
  passwordValid:any;
  SignUp() {
    // routerLink="/auth/welcome"
    if(this.signUpForm.value.password.match(this.passwordRegex)){
      this.passwordValid = true;
    }else{
      this.passwordValid = false;
      this.changed = true;
      // this.errorMessage = '*Please choose a stronger password. It should contain at least 6 Characters, maximum 10 Characters,1 Lowercase letter, 1 Uppercase letter, 1 Special character and 1 Number.'
      // this.errorMessage = 'Password must be between 6-10 characters and include one capital letter, one lowercase letter, one numerical letter and one special character';
      this.errorMessage = 'Password must be 6 characters and include one capital letter, one lowercase letter, one numerical letter, and one special character';
      return
    }

    this.changed = true;
    if(this.signUpForm.invalid) return;
    this.changed = false;

    this.changed = true;
    if (this.signUpForm.invalid) return;
    this.changed = false;
    let data = {
      email: this.signUpForm.value.email,
      password: this.signUpForm.value.password,
    };

    console.log(data);

    if(this.passwordValid==true){
      if (this.signUpForm.value.password == this.signUpForm.value.confirmPassword) {
        this.match = true;
  
  
        
        // // this.router.navigateByUrl('/registration');
        // this.router.navigateByUrl('/enter-code');
        // return;
          this.authService.signUpUser(data).subscribe((res: any) => {
            console.log('changepassword res', res);
            if (res.type == 'success') {
              localStorage.setItem('email', JSON.stringify(this.signUpForm.value.email));
              // this.signUpForm.controls['email'].reset();
              // this.signUpForm.controls['password'].reset();
              // this.signUpForm.controls['confirmPassword'].reset();
              this.passChangeMsg = true;
              console.log(res);
              this.removeLocalStorage();
              localStorage.setItem('currentUser', JSON.stringify(res.data));
              this.toastr.success(res.message);
              this.router.navigateByUrl('/register');
            } 
            if(res.type == 'error') {
              this.toastr.error(res.message);
              this.router.navigateByUrl('/auth/signup');
            }
            
          },(err)=>{
            if(err.error.message=='Request Validation failed'){
              // this.errorMessage = "Please choose stronger password. It should contain atleast 6 Characters , maximum 10 Characters ,1 Lowercase letter, 1 Uppercase letter, 1 Special character and 1 Number.";
              this.toastr.error("Please choose stronger password. It should contain atleast 6 Characters , maximum 10 Characters ,1 Lowercase letter, 1 Uppercase letter, 1 Special character and 1 Number.");
            }else{
              if(err.error?.message){
                this.toastr.error(err.error.message);
              }
            }
            
          });
        }



      }

      if(this.signUpForm.value.password != this.signUpForm.value.confirmPassword){
        this.match = false;
        this.errorMessage = '';
      }
  }

  onClickCheckbox(e:any){
    alert(e);
  }

  removeError(e){
    this.changed = false;
    this.errorMessage = '';
    this.match = true;

    e.target.value = e.target.value.replace(/^\s+/g, '');

    var checkbox = (<HTMLInputElement>document.getElementById('checkbox'));
      if(e.key=='Enter' && this.signUpForm.valid && checkbox.checked == true){
        this.SignUp();
      }
      
      
  }


  createLocalStorage(){
    localStorage.setItem('mail',this.signUpForm.value.email);
    localStorage.setItem('pass',this.signUpForm.value.password);
    localStorage.setItem('confmPass',this.signUpForm.value.confirmPassword);
    localStorage.setItem('term',this.signUpForm.value.term);
  }

  removeLocalStorage(){
    localStorage.removeItem('mail');
    localStorage.removeItem('pass');
    localStorage.removeItem('confmPass');
    localStorage.removeItem('term');
  }

  termCondition(){
    this.createLocalStorage();
    this.router.navigateByUrl('/auth/terms-and-conditions');
  }

  onclickPrivacy(){
    this.createLocalStorage();
    this.router.navigateByUrl('/auth/privacy-policy');
  }

  CheckEnterPress(e:any){
    if (e.keyCode == 13) {               
      e.preventDefault();
      return false;
    } 
  }
}
