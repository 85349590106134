<div class="" style="margin: 0">
  <div>
    <div class="search-friends">
      <div class="form-group" style="    margin-bottom: 25px;">
        <span>
          <img src="../../../../../assets/Indietown_icons/search-icon1.svg" alt="" />
        </span>
        <input class="form-control" id="searchh" type="text" (keyup)="filterFriendsList($event.target.value)"
          placeholder="Search" aria-label="Search" style="background: rgba(233, 233, 235, 0.3);
            border: 1px solid #E9E9EB;
            border-radius: 15px;" />
      </div>
    </div>
    <div *ngIf="filter == true">
      <p>&nbsp;</p>
      <div *ngIf="!userId" style="margin-top:20px;">
        <div class="d-flex flex-wrap" style="margin-top: 3rem;" *ngIf="otherFriendsList?.length">
          <!-- | filter : searchFriendKey -->
          <!-- | filterFriends : filterFriendKey" -->

          <div class="friends-cards" style="position: relative" *ngFor="let friend of otherFriendsList">
            <img src="../../../assets/Indietown_icons/report-icon.png" alt="" class="edit-icon"
              (click)="openActionsModal(friend,'topFriend')" style="width: 34px !important;
            height: 39px !important;
            object-fit: none;
            background: none;
            rotate: 90deg;">
            <img [src]="
                friend?.profileImage?.fileUrl
                  ? friend?.profileImage?.fileUrl
                  : '../../../../assets/Indietown_icons/upload-profile-pic.png'
              " alt="" (click)="navigateToProfile(friend._id,friend.bandName)" height="85" width="84"
              class="card-img-top" />
            <img src="../../../assets/Indietown_icons/top-four.svg" alt="" class="top-4"
              *ngIf="friend?.isTopFriend == true" />


            <!-- *ngIf="showSiidbar == false" -->
            <!-- (click)="sendMessage(friend?._id)" -->
            <div>
              <div>
                <p>&nbsp;&nbsp;</p><br>
                <div style=" " class=" frindsbandname" (click)="navigateToProfile(friend?._id,friend?.bandName)">
                  {{ friend?.bandName | titlecase }}
                </div>
              </div>
              <div>
                <div style="margin-bottom: 5px; " class=" frindsTitlebandname">{{friend?.communityIds.length>0 ?
                  (friend?.communityIds[0]?.communityName +
                  (friend?.communityIds[1]?.communityName!=null ? " | " +friend?.communityIds[1]?.communityName : '')):
                  ((friend?.genereIds[0]?.genereName!=null ? friend?.genereIds[0]?.genereName : '') +
                  (friend?.genereIds[1]?.genereName!=null ? " | " +friend?.genereIds[1]?.genereName :
                  '') + (friend?.genereIds[2]?.genereName ? " | " +friend?.genereIds[2]?.genereName :
                  ''))}}</div>
              </div>
              <button class="btn" (click)="navigateToMessages(friend)">
                Message
              </button>
              <!-- <button
                *ngIf="showSiidbar == true"
                class="btn"
                id="userFriendsList{{ friend?._id }}"
                (click)="addFriendById(friend?._id)"
              >
                Add Friend
              </button> -->
            </div>
          </div>
        </div>

        <!-- <div *ngIf="!otherFriendsList?.length && !topFriendsList?.length" style="margin-top: 35px;
    text-align: center; font-size: 24px;">No Friends Yet !</div> -->
        <div *ngIf="!otherFriendsList?.length && !topFriendsList?.length" style="margin-top: 35px; font-weight: 700;
    text-align: center; font-size: 24px;">No Data Found !</div>
      </div>
    </div>

    <div *ngIf="filter == false">
      <div *ngIf="!userId">
        <!-- *ngIf="!postId && !friendsListStatus" -->
        <h1 style="    font-size: 18px;
        padding-top: 14px;" *ngIf="otherFriendsList?.length">
          <span style="font-weight: 600">Top Friends</span>
          <span *ngIf="topFriendsList?.length"> ({{topFriendsCount}})</span>
        </h1>

        <!-- ({{ friendsList.length > 4 ? 4 : friendsList.length }}) -->

        <div class="d-flex flex-wrap" style="margin-top: 3rem;" *ngIf="topFriendsList?.length">
          <!-- | filter : searchFriendKey -->
          <!-- | filterFriends : filterFriendKey" -->
          <div class="friends-cards" style="position: relative" *ngFor="let friend of topFriendsList">
            <img src="../../../assets/Indietown_icons/report-icon.png" alt="" class="edit-icon"
              (click)="openActionsModal(friend,'topFriend')" style="width: 34px !important;
            height: 39px !important;
            object-fit: none;
            background: none;
            rotate: 90deg;">
            <img [src]="
                friend?.profileImage?.fileUrl
                  ? friend?.profileImage?.fileUrl
                  : '../../../../assets/Indietown_icons/upload-profile-pic.png'
              " alt="" (click)="navigateToProfile(friend._id,friend.bandName)" height="85" width="84"
              class="card-img-top" />
            <img src="../../../assets/Indietown_icons/top-four.svg" alt="" class="top-4" />


            <!-- *ngIf="showSiidbar == false" -->
            <!-- (click)="sendMessage(friend?._id)" -->
            <div>
              <div>
                <p>&nbsp;&nbsp;</p><br>
                <div style=" " class=" frindsbandname" (click)="navigateToProfile(friend?._id,friend?.bandName)">
                  {{ friend?.bandName | titlecase }}
                </div>
              </div>
              <div>
                <div style="margin-bottom: 5px; " class=" frindsTitlebandname">{{friend?.communityIds.length>0 ?
                  (friend?.communityIds[0]?.communityName +
                  (friend?.communityIds[1]?.communityName!=null ? " | " +friend?.communityIds[1]?.communityName : '')):
                  ((friend?.genereIds[0]?.genereName!=null ? friend?.genereIds[0]?.genereName : '') +
                  (friend?.genereIds[1]?.genereName!=null ? " | " +friend?.genereIds[1]?.genereName :
                  '') + (friend?.genereIds[2]?.genereName ? " | " +friend?.genereIds[2]?.genereName :
                  ''))}}</div>
              </div>
              <button class="btn" (click)="navigateToMessages(friend)">
                Message
              </button>
              <!-- <button
                *ngIf="showSiidbar == true"
                class="btn"
                id="userFriendsList{{ friend?._id }}"
                (click)="addFriendById(friend?._id)"
              >
                Add Friend
              </button> -->
            </div>
          </div>
        </div>

        <!-- Here are the suggestions to make your friend as your favourite friend!<br>
        1.Go to their profile.<br>
        2.Click on star mark on top right of the cover picture. -->

        <div *ngIf="otherFriendsList?.length && !topFriendsList?.length" class="d-flex justify-content-center">
          To Make a Friend as a Top Friend Select the star at the top right<br> corner of their profile
        </div>
        <!-- *ngIf="!friendsListStatus" -->
        <div style="margin-top: 2%" *ngIf="otherFriendsList?.length">
          <!-- *ngIf="!postId" -->
          <!-- && topFriendsList?.length -->
          <h1 class="otherfrnds" style="    font-size: 18px;
          padding-top: 14px;" *ngIf="otherFriendsList?.length">
            <span style="font-weight: 600">Others</span>
            <span> ({{ otherFriendsCount }})</span>
          </h1>
          <div class="d-flex flex-wrap" style="margin-top: 3rem" infinite-scroll
          (scrolled)="loadMoreOtherFriends($event)" [infiniteScrollDistance]="6" infinite-scroll-disabled="!loadMoreOtherFriend">
            <!--  -->
            <!-- | filterFriends : filterFriendKey -->
            <div class="friends-cards" style="position: relative" *ngFor="let friend of otherFriendsList">
              <!-- [src]="
                  friend?.profileImage?.fileUrl
                    ? friend?.profileImage?.fileUrl
                    : '../../../../assets/Indietown_icons/upload-profile-pic.png'
                "
                *ngIf="!postId"
                (click)="onClickFriend(friend)" -->
              <!-- *ngIf="!postId" -->
              <img src="../../../assets/Indietown_icons/report-icon.png" alt="" class="edit-icon"
                (click)="openActionsModal(friend,'others')" style="width: 34px !important;
              height: 39px !important;
              object-fit: none;
              background: none;
              rotate: 90deg;">
              <img [src]="
                friend?.profileImage?.fileUrl
                  ? friend?.profileImage?.fileUrl
                  : '../../../../assets/Indietown_icons/upload-profile-pic.png'
              " (click)="navigateToProfile(friend._id,friend.bandName)" alt="" height="85" width="84"
                class="card-img-top" />
              <!-- <img
                [src]="
                  friend?.profileImage?.fileUrl
                    ? friend?.profileImage?.fileUrl
                    : '../../../../assets/Indietown_icons/upload-profile-pic.png'
                "
                *ngIf="postId"
                routerLink="/user/friends-list"
                [queryParams]="{ id: friend?._id, timeline: true }"
                alt=""
                height="77"
                width="77"
                class="card-img-top"
              /> -->



              <div>
                <div>
                  <p>&nbsp;&nbsp;</p><br>
                  <div style=" " class=" frindsbandname" (click)="navigateToProfile(friend?._id,friend?.bandName)">
                    {{ friend?.bandName | titlecase }}
                  </div>
                </div>
                <div>
                  <div style="margin-bottom: 5px; " class=" frindsTitlebandname">{{friend?.communityIds.length>0 ?
                    (friend?.communityIds[0]?.communityName +
                    (friend?.communityIds[1]?.communityName!=null ? " | " +friend?.communityIds[1]?.communityName :
                    '')):
                    ((friend?.genereIds[0]?.genereName!=null ? friend?.genereIds[0]?.genereName : '') +
                    (friend?.genereIds[1]?.genereName!=null ? " | " +friend?.genereIds[1]?.genereName :
                    '') + (friend?.genereIds[2]?.genereName ? " | " +friend?.genereIds[2]?.genereName :
                    ''))}}</div>
                </div>
                <button class="btn" (click)="navigateToMessages(friend)">
                  Message
                </button>
                <!-- <button
                  *ngIf="showSiidbar == true"
                  class="btn"
                  id="userFriendsList{{ friend?._id }}"
                  (click)="addFriendById(friend?._id)"
                >
                  Add Friend
                </button> -->
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="!otherFriendsList?.length && !topFriendsList?.length" style="margin-top: 35px;
        text-align: center; font-size: 24px; font-weight: 700;">No Friends Yet !</div>
      </div>

    </div>

    <div *ngIf="userId">
      <div style="margin-top: 2%" *ngIf="friendsList?.length">
        <!-- *ngIf="!postId" -->
        <!-- margin-top: 4rem; -->
        <div class="d-flex flex-wrap scroll-css" style="height: 740px;overflow: auto;" infinite-scroll [scrollWindow]="false"
          (scrolled)="loadMoreFriends($event)" infinite-scroll-disabled="!loadMoreFriend">
          <!--  -->
          <!-- | filterFriends : filterFriendKey -->
          <div class="friends-cards" style="position: relative;top: 36px;"
            *ngFor="let friend of friendsList;let friendIndex = index">
            <img [src]="
                friend?.profileImage?.fileUrl
                  ? friend?.profileImage?.fileUrl
                  : '../../../../assets/Indietown_icons/upload-profile-pic.png'
              " (click)="navigateToProfile(friend?._id,friend?.bandName)" alt="" height="85" width="84"
              class="card-img-top" />
            <!-- (click)="navigateToProfile(friend?._id,friend?.bandName)" -->
            <div (click)="navigateToProfile(friend?._id,friend?.bandName)">
              <p style="margin-bottom: 5px" class="mt-3 frindsbandname">
                {{ friend?.bandName | titlecase }}
              </p>
            </div>

            <div>
              <div style="margin-bottom: 5px; " class=" frindsTitlebandname">{{friend?.communityIds.length>0 ?
                (friend?.communityIds[0]?.communityName +
                (friend?.communityIds[1]?.communityName!=null ? " | " +friend?.communityIds[1]?.communityName : '')):
                ((friend?.genereIds[0]?.genereName!=null ? friend?.genereIds[0]?.genereName : '') +
                (friend?.genereIds[1]?.genereName!=null ? " | " +friend?.genereIds[1]?.genereName :
                '') + (friend?.genereIds[2]?.genereName ? " | " +friend?.genereIds[2]?.genereName :
                ''))}}</div>
            </div>

            <div>
              <button id="addFriendText{{ friend?._id }}" class="btn" *ngIf="friend.friendStatus == 'NOT_FRIEND' && (friend?._id != myProfileId)"
                (click)="AddFriend(friend?._id,0)">
                Add Friend
              </button>
              <button class="btn" style="background: none;
              color: #000; cursor: auto; font-weight: bold;"
                *ngIf="friend.friendStatus == 'NOT_FRIEND' && (friend?._id == myProfileId)">
                You
              </button>
              <!-- <button class="btn" *ngIf="friend.friendStatus == 'REQUEST_SENT'"
                (click)="cancelRequest(friend?._id,friendIndex)" style="background: transparent;
                border: 1px solid #000;
                color: #000;
                font-weight: 500;">
                Cancel Request
              </button> -->
              <button
                style="border-radius: 8px; box-shadow: none;color: black !important;background-color:transparent !important;"
                class="btn btn-dark addFriend btn-sm" (click)="AddFriend(friend._id, 0)"
                id="addFriendText{{ friend?._id }}" *ngIf="friend.friendStatus == 'REQUEST_SENT'">
                Pending
              </button>
              <button
                style="border-radius: 8px; box-shadow: none;color: black !important;background-color:transparent !important;"
                class="btn btn-dark addFriend btn-sm" (click)="AddFriend(friend._id, 0)"
                id="addFriendText{{ friend?._id }}" *ngIf="friend.friendStatus == 'ACCEPT_REQUEST'">
                Accept
              </button>
              <button class="btn" *ngIf="friend.friendStatus == 'FRIEND'" (click)="navigateToMessages(friend)">
                message
              </button>
              <!-- <button class="btn" *ngIf="friend.friendStatus == 'FRIEND' && (friend?._id == myProfileId)">
                you
              </button> -->
            </div>
          </div>
        </div>
      </div>

      <!-- <div *ngIf="!friendsList?.length" style="margin-top: 35px;
        text-align: center; font-size: 24px;">No Friends Yet !</div> -->
      <div *ngIf="!friendsList?.length && filter" style="margin-top: 35px;
        text-align: center; font-size: 24px;">No Data Found !</div>
      <div *ngIf="!friendsList?.length && !filter" style="margin-top: 35px;
        text-align: center; font-size: 24px;">No Friends Yet !</div>
    </div>

  </div>
</div>


<div class="profilepopup">
  <ng-template #friendActionsModal>
    <div class="modal-header" style="padding: 20px; padding-bottom: 10px;">
      <div style="display: flex;
      align-items: center;
      justify-content: flex-start;">
        <img
          [src]="selectedFriendData?.profileImage?.fileUrl ? selectedFriendData?.profileImage?.fileUrl : '../../../assets/Indietown_icons/upload-profile-pic.png'"
          height="65" width="65" alt="" style="border-radius: 100%"
          (click)="navigateToProfile(selectedFriendData?._id,selectedFriendData?.bandName)" />
        <div style="">
          <div class="friendClickModal-text">
            <p class="friendsbandtitle"
              (click)="navigateToProfile(selectedFriendData?._id,selectedFriendData?.bandName)">
              {{ selectedFriendData?.bandName | titlecase }}
            </p>
            <!-- (click)="navigateToProfile(selectedFriendData?._id,selectedFriendData?.bandName)" -->
            <!-- {{ selectedFriendData?.bandName | titlecase }} -->
            <p class="friendssubtitle">
              {{selectedFriendData?.communityIds.length>0 ?
              (selectedFriendData?.communityIds[0]?.communityName +
              (selectedFriendData?.communityIds[1]?.communityName!=null ? " | "
              +selectedFriendData?.communityIds[1]?.communityName : '')):
              ((selectedFriendData?.genereIds[0]?.genereName!=null ? selectedFriendData?.genereIds[0]?.genereName : '')
              +
              (selectedFriendData?.genereIds[1]?.genereName!=null ? " | " +selectedFriendData?.genereIds[1]?.genereName
              :
              '') + (selectedFriendData?.genereIds[2]?.genereName ? " | " +selectedFriendData?.genereIds[2]?.genereName
              :
              ''))}}
            </p>
          </div>
        </div>
      </div>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" style="background: #FFFFFF;
        border: 1px solid rgba(15, 13, 15, 0.2) !important;
        box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.05);
        opacity: 1;">
        <span aria-hidden="true"><img style="width: 17.5px;
          height: 17.5px;
          margin-top: -4px;" src="../../../assets/Indietown_icons/cross.svg" alt=""></span>
      </button>
    </div>
    <div class="modal-body delete-modal" style="border-top: solid 3px #f5f5f5; border-radius: unset;padding: 20px;">
      <ul class="friendsul" style="list-style: none; margin-left: -35px">
        <li class="friendClickModal-group">
          <span><img src="../../../assets/Indietown_icons/Chat-white.svg" alt="" /></span>
          <span (click)="navigateToMessages(selectedFriendData)">Message {{ selectedFriendData?.bandName | titlecase
            }}</span>
        </li>

        <li class="friendClickModal-group"
          (click)="openUnfriendModal(selectedFriendData?._id,selectedFriendData?.bandName)">
          <span><img src="../../../assets/Indietown_icons/unfriend.svg" alt="" /></span>
          <span>Unfriend {{ selectedFriendData?.bandName | titlecase }}</span>
        </li>

        <li class="friendClickModal-group" (click)="silencePosts(selectedFriendData?._id)"
          *ngIf="!selectedfriendSilenceStatus">
          <span><img src="../../../assets/Indietown_icons/Volume Off.svg" alt="" /></span>
          <span>Silence {{ selectedFriendData?.bandName | titlecase }} Posts</span>
        </li>
        <li class="friendClickModal-group" (click)="unMute(selectedFriendData?._id)"
          *ngIf="selectedfriendSilenceStatus">
          <span><img src="../../../assets/Indietown_icons/Volume Off.svg" alt="" /></span>
          <span>UnMute {{ selectedFriendData?.bandName | titlecase }} Posts</span>
        </li>
      </ul>
    </div>
  </ng-template>
</div>
<ng-template #cancelRequests>
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef?.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center align-items-center">
    <p style="line-height: 26px;font-size: 16px;font-weight: bold;color: black;">Are you sure you want to cancel <br>
      this request?</p>
    <div class="modal-btn d-flex justify-content-around" style="margin-top: 39px;">
      <button class="btn btn-red nobtn" (click)="cancelFriendRequest()"
        style="color: black;margin-right: 10px;font-weight:bold;">Yes</button>
      <button class="btn btn-blue logoutbtn" (click)="modalRef?.hide()"
        style="background-color: black;font-weight:bold;">No</button>
    </div>
    <div *ngIf="showCancelRequestLoader" style="text-align: center;">
      <img width="50" src="../../../assets/Indietown_icons/Loading_icon.gif">
    </div>
  </div>
</ng-template>

<ng-template #unFriendModal>
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="unFriendModalRef?.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center align-items-center">
    <p style="line-height: 26px;font-size: 16px;font-weight: bold;color: black;">Are you sure you want to unfriend <br>
      {{unFriendName | titlecase}}?</p>
    <div class="modal-btn d-flex justify-content-around" style="margin-top: 39px;">
      <button class="btn btn-red nobtn" (click)="unFriend()"
        style="color: black;margin-right: 10px;font-weight:bold;">Yes</button>
      <button class="btn btn-blue logoutbtn" (click)="unFriendModalRef?.hide()"
        style="background-color: black;font-weight:bold;">No</button>
    </div>
    <div *ngIf="showCancelRequestLoader" style="text-align: center;">
      <img width="50" src="../../../assets/Indietown_icons/Loading_icon.gif">
    </div>
  </div>
</ng-template>

<!-- (keyup)="searchFriend($event.target.value)" -->
    <!-- <h1 *ngIf="friendsListStatus">No Friends</h1> -->
